/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-empty */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { Client } from '@amityco/ts-sdk';

// import Config from 'react-native-config';
import { useAppSelector } from '@store';
import axios from 'axios';

export type TAuthContext = {
  error: string;
  isConnecting: boolean;
  logout: () => void;
  client?: Amity.Client | {};
  login: () => Promise<void>;
  isConnected: boolean;
  sessionState: string;
};

export const AuthContext = React.createContext<TAuthContext>({
  client: {},
  isConnecting: false,
  error: '',
  login: async () => {},
  logout: () => {},
  isConnected: false,
  sessionState: '',
});

export const AuthContextProvider = ({
  children,
}: {
  children: React.JSX.Element;
}) => {
  const profile = useAppSelector(state => state.login.userProfile);
  const apiKey = process.env.REACT_APP_CHAT_API_KEY || '';
  const apiRegion = process.env.REACT_APP_CHAT_API_REGION;
  const [error, setError] = useState('');
  const [isConnecting, setLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [sessionState, setSessionState] = useState('');
  const [client] = useState(Client.createClient(apiKey, apiRegion));
  const [sessionHandler] = useState<Amity.SessionHandler>({
    sessionWillRenewAccessToken(renewal) {
      try {
        renewal.renew();
      } catch (error) {}
    },
  });

  useEffect(() => {
    return Client.onSessionStateChange((state: Amity.SessionStates) => {
      setSessionState(state);
    });
  }, []);

  const startSync = () => {
    if (Client.isConnected() && sessionState === 'established') {
      try {
        Client.enableUnreadCount();
      } catch (error) {}
    } else {
    }
  };

  useEffect(() => {
    if (sessionState === 'established') {
      startSync();
      setIsConnected(true);
    } else {
      setIsConnected(false);
    }
  }, [sessionState]);

  const handleConnect = async () => {
    if (profile?.sub) {
      try {
        const res = await Client.login(
          {
            userId: profile?.sub.split(':')[1],
            displayName: profile?.sub.split(':')[1],
          },
          sessionHandler,
        );
      } catch (error) {}
    }
  };

  const login = async () => {
    setError('');
    setLoading(true);
    try {
      handleConnect();
    } catch (e) {
      const errorText =
        (e as Error)?.message ?? 'Error while handling request!';

      setError(errorText);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isConnected && sessionState !== 'established') {
      login();
    }
  }, [profile]);

  const logout = async () => {
    try {
      // Client.stopUnreadSync();
      await Client.logout();
    } catch (e) {}
  };

  return (
    <AuthContext.Provider
      value={{
        error,
        isConnecting,
        login,
        client,
        logout,
        isConnected,
        sessionState,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
