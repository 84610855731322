import { useEffect, useState } from "react";

import { Driver, IDriverInfractions, IDriverPayload, IProfileScore } from "@types";
import { getDriverVehicle, getInfractions, getScore } from "src/services/noTrucker";

export function useSocialProfileNoTrucker () {

  const [data, setData] = useState<IDriverPayload|undefined>();
  const [infractions, setInfractions] = useState<IDriverInfractions|undefined>();
  const [score, setScore] = useState<IProfileScore|undefined>();
  const [result, setResult] = useState<any[]>([]);
    
  useEffect(() => {
    setResult(getOptions());
  }, [data, infractions, score]);

  const refetch = async (driver: Driver|undefined) => {
    setData(undefined);
    setInfractions(undefined);
    if (driver) {
      getScoreInfo(driver);
      getDriverInfo(driver);
    }
  };
  
  const getDriverInfo = async (driver: Driver) => {
    try {
      const data = await getDriverVehicle(
        driver.id,
        {
          refetchOnMountOrArgChange: true,
          refetchOnFocus: true,
        },
      );

      const infractionsData = await getInfractions(
        data?.data?.result?.full_name ?? '',
        data?.data?.result?.driver_license?.substring(3) as string,
      );

      setInfractions(infractionsData?.data?.result);
      setData(data?.data?.result);

    } catch(e) {
      console.log('error while fetching rio fleet driver info')
    }
  }

  const getScoreInfo = async (driver: Driver) => {
    try {
      const scoreData = await getScore(
        driver.userId?.replaceAll('prod-rio-users:', '')
      );
      console.log('score: ', scoreData)
      setScore(scoreData?.data?.result);
    } catch(e) {
      console.log('error while fetching score');
    }
  }

  const getDistanceValue = () => {
    return (
      (score && score.kms > 100 ? score.kms : score?.kms ? score.kms : 0)
        .toString()
        .replace('.', ',') + ' km'
    );
  };
  
  const getTotalTripsValue = () => {
    return score && score.totalTrips > 100
      ? '+' + score.totalTrips
      : score?.totalTrips
      ? score.totalTrips
      : 0;
  };
  
  const getMapInteractionsValue = () => {
    return score && score.mapInteractions > 100
      ? '+' + score.mapInteractions
      : score?.mapInteractions
      ? score.mapInteractions
      : 0;
  };
  
  const getInfractionsValue = () => {
    const totalInfractions = infractions?.total || 0;
  
    if (totalInfractions <= 30) {
      return 'baixa';
    } else if (totalInfractions <= 60) {
      return 'média';
    } else {
      return 'alta';
    }
  };

  const getOptions = () => {
    const result = [
      {
        id: 1,
        description: 'Distância percorrida no app',
        value: getDistanceValue(),
      },
      {
        id: 2,
        description: 'Total de viagens',
        value: getTotalTripsValue(),
      },
      {
        id: 3,
        description: 'Contribuições com o mapa',
        value: getMapInteractionsValue(),
      },
      {
        id: 4,
        description: 'Média de infrações',
        value: getInfractionsValue(),
      },
    ];
    console.log('no trucker: ', result, score)
    return result;
  };
  
  return {
    refetch,
    result,
  };
};
  
export default useSocialProfileNoTrucker;