import {
  Button,
  Dialog,
  ExpanderList,
} from "@rio-cloud/rio-uikit";
import React, { ReactNode, useCallback, useState } from "react";
// import itensSvg from '../../../assets/svg/itens.svg';
// import motorSvg from '../../../assets/svg/motor.svg';
// import conectivitySvg from '../../../assets/svg/conectivity.svg';
// import dimensionsSvg from '../../../assets/svg/dimensions.svg';
// import securitySvg from '../../../assets/svg/security.svg';
// import truckSvg from '../../../assets/svg/truck.svg';


interface Props {
  id: string|undefined;
  open: boolean;
  onClose: () => void;
}

interface VehicleInfo {
  imageUrl: string;
  name: string;
  description: string;
}

const DriverVehicle = ({ id, open, onClose }: Props) => {
  
  const [ userInfo ] = useState<VehicleInfo>({
    imageUrl: "https://static.vecteezy.com/ti/vetor-gratis/p1/2275847-avatar-masculino-perfil-icone-de-homem-caucasiano-sorridente-vetor.jpg",
    name: 'VW Meteor 28.480',
    description: 'Veículo ano 2022. único dono, com implementação de carroceria aberta, 4800mm entre-eixos, rastreamento RIO e seguro',
  });

  
  const AvatarSection = useCallback(
    (title: string, image: string | ReactNode) => (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '42px',
      }}>
        {
          React.isValidElement(image)
            ? image
            : <img
                src={image as string}
                alt="Avatar"
                style={{
                  width: "30px",
                  height: "30px",
                  objectFit: "cover",
                }}
              />
        }
        <span style={{
          fontSize: "16px",
          lineHeight: "24px",
          color: "#000000",
          marginLeft: "16px",
          fontWeight: '600',
        }}>
          {title}
        </span>
      </div>
    ),
    []
  );

  return (
    <Dialog
      show={open}
      title={"Veículo do Motorista"}
      onClose={onClose}
      footer={
        <div className="display-flex gap-10 justify-content-end flex-grow">
          <Button
            bsSize={Button.MD}
            style={{ width: 84 }}
            onClick={onClose}>
            Voltar
          </Button>
        </div>
      }
      body={
        <div>
          <div style={{
            display: "flex", 
            flexDirection: "row", 
            justifyContent: "flex-start", 
            alignItems: "center",
            marginTop: "20px",
            marginLeft: "12px",
          }}>
            <img
              src={userInfo.imageUrl}
              alt="Avatar"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "64px",
                objectFit: "cover",
              }}
            />
            <span style={{
              fontSize: "22px",
              fontWeight: "600",
              color: "#000000",
              marginLeft: "16px",
            }}>
              {userInfo.name}
            </span>
          </div>

          <div style={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '21px',
            marginTop: '24px',
            marginBottom: '24px',
            paddingLeft: '12px',
            paddingRight: '12px',
          }}>
            {userInfo.description}
          </div>

          <ExpanderList
            bordered={true}
            unmountOnExit={false}
            rounded={false}
            items={[
              {
                id: 1,
                header: AvatarSection(
                  'Ítens do Veículo',
                  'itens', // itensSvg,
                ),
                body: <div>Content here...</div>,
              },
              {
                id: 2,
                header: AvatarSection(
                  'Motor / Desempenho / Consumo', 
                  'motor' // motorSvg,
                ),
                body: <div>Content here...</div>, 
              },
              {
                id: 3,
                header: AvatarSection(
                  'Implementos', 
                  'truck' //truckSvg,
                ),
                body: <div>Content here...</div>, 
              },
              {
                id: 4,
                header: AvatarSection(
                  'Dimensões / Capacidades', 
                  'dimensions' //dimensionsSvg,
                ),
                body: <div>Content here...</div>, 
              },
              {
                id: 5,
                header: AvatarSection(
                  'Segurança', 
                  'security' //securitySvg,
                ),
                body: <div>Content here...</div>, 
              },
              {
                id: 6,
                header: AvatarSection(
                  'Tecnologia / Conectividade', 
                  'connectivity' //conectivitySvg,
                ),
                body: <div>Content here...</div>, 
              },
              
            ]}
          />
        </div>
        
      }
      className="exampleDialog"
      showCloseButton
      bsSize="md"
    />
  );
};

export default DriverVehicle;
