import {
  Button,
  Checkbox,
  ExpanderPanel,
  NotFoundState,
  RadioButton,
  TableSearch,
  TreeSummary,
  TreeSummaryRow,
  TypeCounter,
} from "@rio-cloud/rio-uikit";

import Dialog from "@rio-cloud/rio-uikit/Dialog";
import SortArrows from "@rio-cloud/rio-uikit/SortArrows";
import { useEffect, useMemo, useState } from "react";
import Paginator from "src/components/Paginator";
import { GroupedVirtuoso, TableVirtuoso } from 'react-virtuoso'
import { group } from "console";
import { IVehicle } from "src/screens/tripsListRoute";

enum VehicleType {
  TRUCK = "Caminhão",
  BUS = "Ônibus",
  TRAILER = "Carreta",
  VAN = "Van",
}

interface Props {
  open: boolean;
  onClose: () => void;
  vehicles: Array<IVehicle>;
  handleSaveVehicle: (idVehicle: string, name: string) => void;
  handleCancel: () => void;
  onChangeInput: (text: string) => void;
  inputValue: string;
  page: number;
  pages: number;
  handlePage: (id: number) => void;
  fleets?: { [x: string]: string; };
  initialSlice: number;
  endSlice: number;
}

const AddVehicle = ({
  open,
  onClose,
  vehicles,
  handleSaveVehicle,
  handleCancel,
  inputValue,
  onChangeInput,
  handlePage,
  page,
  pages,
  fleets,
  initialSlice = 0,
  endSlice = 10
}: Props) => {
  const [grouped, setGrouped] = useState<[string, IVehicle[]][]>();
  const [groupedAll, setAllGrouped] = useState<[string, IVehicle[]][]>();
  const [selected, setSelected] = useState("");
  const [name, setName] = useState("");
  const [selectedTree, setSelectedTree] = useState("");
  const [filterAll, setFilterAll] = useState(false);

  const [directions, setDirections] = useState({
    column: "type",
    asc: true,
  });

  const [assetsCounts, setAssetsCounts] = useState({
    truck: 0,
    trailer: 0,
    bus: 0,
    van: 0,
    driver: 0,
  });


  const mountVehicleWithBrand = () => {
    const group = Object.entries(
      vehicles?.reduce<Record<string, IVehicle[]>>((acc, vehicle) => {
        const embeddedData = vehicle.embedded?.map((tag) => fleets?.[tag]) || [];
        
        if (embeddedData.length === 0) {
          // Se não encontrar nenhum dado para o veículo, adiciona ao grupo "outros"
          if (!acc["outros"]) {
            acc["outros"] = [];
          }
          acc["outros"].push(vehicle);
        } else {
          embeddedData.forEach((data) => {
            if (data && typeof data === "string") {
              if (!acc[data]) {
                acc[data] = [];
              }
              acc[data].push(vehicle);
            }
          });
        }
    
        return acc;
      }, {})
    );
    
  
    setGrouped(group.sort((a, b) => a[0].localeCompare(b[0])));
    setAllGrouped(group.sort((a, b) => a[0].localeCompare(b[0])));
  };

  const header = [
    {
      name: "type",
      label: "tipo",
    },
    {
      name: "vehicle",
      label: "Veículo",
    },
    {
      name: "distance",
      label: "Km Prox. manutenção",
    },
    {
      name: "odometer",
      label: "Odômetro",
    },
    {
      name: "hourmeter",
      label: "Horímetro",
    },
    {
      name: "location",
      label: "localização",
    },
  ];

  const handleSelectTypeTree = (type: string) => {
    setSelected("");
    setName("");
    setSelectedTree(type);
    setFilterAll(false);
    const filteredArr = groupedAll
      ?.filter(([brand, vehiclesf]) => vehiclesf.some((vehicle) => vehicle.type === type))
      .map(([brand, vehicles]) => [brand, vehicles.filter((vehicle) => vehicle.type === type)]);
    handlePage(1);
    if (filteredArr) setGrouped(filteredArr as [string, IVehicle[]][]);
  };

  const handleFilterAll = () => {
    const active = !filterAll;
    setFilterAll(active);

    if (active) {
      setSelectedTree("");
      setGrouped(groupedAll);
    }
  };

  useEffect(() => {
    if (vehicles) {
      mountVehicleWithBrand();
      setAssetsCounts(
        vehicles.reduce(
          (acc, res) => {
            if (typeof res.type === "string" && res.type in acc) {
              acc[res.type as keyof typeof acc]++;
            }
            return acc;
          },
          {
            truck: 0,
            trailer: 0,
            bus: 0,
            van: 0,
            driver: 0,
          }
        )
      );
    }
  }, [vehicles]);

  const groupedMemo = useMemo(() => {
    return grouped;
  }, [grouped]);

  return (
    <Dialog
      show={open}
      title={"Vincular veículo"}
      footer={
        <div className="margin-top-20 display-flex gap-10 justify-content-end flex-grow">
          <Button
            bsSize={Button.MD}
            style={{ width: 84 }}
            onClick={() => {
              setSelected("");
              setName("");
              handleCancel();
            }}
          >
            Voltar
          </Button>
          <Button
            disabled={!selected}
            bsStyle={Button.PRIMARY}
            bsSize={Button.MD}
            style={{ width: 84 }}
            onClick={() => handleSaveVehicle(selected, name)}
          >
            Salvar
          </Button>
        </div>
      }
      onClose={onClose}
      body={
        <>
          <div className="display-flex gap-20 align-items-center margin-top-16 margin-bottom-20">
            <div className="margin-left-15 border border-color-primary border-width-3 border-style-solid width-50 height-50 display-flex justify-content-center align-items-center">
              <span
                className={`rioglyph rioglyph-truck text-size-h1 text-color-primary`}
              />
            </div>
            <div className="width-100pct display-flex align-items-center gap-15 table-toolbar-group-right">
              <div className="width-100pct table-toolbar-column">
                <TableSearch
                  value={inputValue}
                  onChange={(text) => onChangeInput(text)}
                  placeholder="Buscar veículo"
                />
              </div>
              <div className="table-toolbar-column table-toolbar-column table-toolbar-column-spacer">
                <Checkbox onChange={handleFilterAll} checked={filterAll} />
              </div>
              <div className="table-toolbar-column">
                <TreeSummary>
                  <TreeSummaryRow>
                    <TypeCounter
                      key={"truck"}
                      type={"truck"}
                      icon={`truck-baseline`}
                      value={<span>{assetsCounts.truck}</span>}
                      isActive={filterAll || selectedTree === "truck"}
                      hasFilter={true}
                      onClick={handleSelectTypeTree}
                      enableActivity
                    />
                    <TypeCounter
                      key={"bus"}
                      type={"bus"}
                      icon={`bus-baseline`}
                      value={<span>{assetsCounts.bus}</span>}
                      isActive={filterAll || selectedTree === "bus"}
                      hasFilter={true}
                      onClick={handleSelectTypeTree}
                      enableActivity
                    />
                    <TypeCounter
                      key={"van"}
                      type={"van"}
                      icon={`van-baseline`}
                      value={<span>{assetsCounts.van}</span>}
                      isActive={filterAll || selectedTree === "van"}
                      hasFilter={true}
                      onClick={handleSelectTypeTree}
                      enableActivity
                    />
                    <TypeCounter
                      key={"trailer"}
                      type={"trailer"}
                      icon={`trailer-baseline`}
                      value={<span>{assetsCounts.trailer}</span>}
                      isActive={filterAll || selectedTree === "trailer"}
                      hasFilter={true}
                      onClick={handleSelectTypeTree}
                      enableActivity
                    />
                  </TreeSummaryRow>
                </TreeSummary>
              </div>
            </div>
          </div>
          <div style={{ background: "#D0D8DE", padding: 16 }}>
            <div className="margin-top-15 margin-bottom-15">
              <span className="text-size-14 text-medium line-height-20">Todos os veículos</span>
            </div>
            {groupedMemo && groupedMemo.length > 0 ? (
              groupedMemo.slice(initialSlice, endSlice).map((fleet, idx) => {
                return (
                  <ExpanderPanel
                    open={false}
                    title={
                      <table className="table table-layout-fixed width-100pct bg-lightest rounded-small">
                        <thead>
                          <tr>
                            <td colSpan={7}>
                              <span className="text-light text-size-14 text-capitalize text-color-dark">
                                {fleet[0]?.toLocaleLowerCase()}
                              </span>
                            </td>
                            <td>
                              <div
                                className="bg-white width-45 height-22 display-flex align-items-center justify-content-center rounded"
                                style={{ border: "1px solid #D0D5DD" }}
                              >
                                <span className="text-light text-size-12 text-capitalize text-color-dark">
                                  {fleet[1].length}
                                </span>
                              </div>
                            </td>
                          </tr>
                        </thead>
                      </table>
                    }
                    headerClassName="padding-0 position-relative"
                    titleClassName="width-100pct bg-lightest border-radius-2 height-60 display-flex border-radius-none"
                    bodyClassName="padding-0"
                    className="shadow-default margin-bottom-4 border-radius-none"
                    iconClassName="position-absolute top-0 right-15 height-100pct display-flex align-items-center text-color-dark"
                  >
                    <div>
                      <TableVirtuoso
                        key={idx}
                        style={{ minHeight: 100, height: 200, maxHeight: 400 }}
                        className="table table-head-filled table-layout-fixed margin-top-4 table-bordered bg-white"
                        components={{
                          Table: () => {
                            return (
                              <table className="table table-head-filled table-layout-fixed margin-top-4 table-bordered">
                                <thead>
                                  <tr>
                                    <th
                                      key={"select"}
                                      data-field={"select"}
                                      className="width-60"
                                    ></th>
                                    {header.map((h) => {
                                      return (
                                        <th
                                          className="user-select-none sort-column"
                                          key={h.name}
                                          data-field={h.name}
                                          onClick={() =>
                                            h.name !== directions.column
                                              ? setDirections({
                                                column: h.name,
                                                asc: true,
                                              })
                                              : setDirections({
                                                column: h.name,
                                                asc: !directions.asc,
                                              })
                                          }
                                        >
                                          <span>
                                            {directions.column === h.name ? (
                                              <SortArrows
                                                direction={
                                                  !!directions.asc ? "asc" : "desc"
                                                }
                                                key={h.name}
                                              />
                                            ) : (
                                              <SortArrows key={h.name} />
                                            )}
                                            <span className="text-capitalize">
                                              {h.label}
                                            </span>
                                          </span>
                                        </th>
                                      );
                                    })}
                                  </tr>
                                </thead>

                                <tbody>
                                  {fleet[1].map((detail) => {
                                    return (
                                      <tr className="height-60" key={detail.id}>
                                        <td key={"select"} data-field={"select"}>
                                          <RadioButton
                                            name="radios"
                                            id={detail.name}
                                            checked={detail.id === selected}
                                            onChange={() => {
                                              setSelected(detail.id);
                                              setName(detail.name);
                                            }}
                                          />
                                        </td>
                                        <td key={"type"} data-field={"type"}>
                                          <div>
                                            <span className="text-size-12 text-normal text-color-darker text-light">
                                              {
                                                VehicleType[
                                                detail.type?.toLocaleUpperCase() as keyof typeof VehicleType
                                                ]
                                              }
                                            </span>
                                          </div>
                                        </td>
                                        <td key={"vehicle"} data-field={"vehicle"}>
                                          <div>
                                            <span className="text-size-12 text-normal text-color-darker text-light">
                                              {detail.name}
                                            </span>
                                          </div>
                                        </td>
                                        <td key={"distance"} data-field={"distance"} className="text-align-center">
                                          <span>-</span>
                                        </td>
                                        <td key={"odometer"} data-field={"odometer"}>
                                          <span>-</span>
                                        </td>
                                        <td key={"hourmeter"} data-field={"hourmeter"}>
                                          <span>-</span>
                                        </td>
                                        <td key={"location"} data-field={"location"}>
                                          <span>-</span>
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            )
                          }
                        }}
                      />
                    </div>
                  </ExpanderPanel>
                );
              })
            ) : (
              <NotFoundState
                headline="Nenhum veículo disponível"
                message="Volte e tente novamente."
                fullWidth
                outerClassName="border-none"
              />
            )}
            <Paginator 
              handlePage={handlePage} 
              page={page} 
              pages={grouped?.length ? Math.round(grouped?.length / 10) < 1 ? 1:Math.round(grouped?.length / 10) : 1} 
            />
          </div>
        </>
      }
      className=""
      bodyClassName="padding-16"
      showCloseButton
      bsSize="lg"
    />
  );
};

export default AddVehicle;
