import CardCount from "src/components/CardCount";
import PageContainer from "../../components/routes/PageContainer";
import { useContext, useEffect, useState } from "react";
import { useDebounce } from "src/hooks/useDebounce";
import { Notification } from "@rio-cloud/rio-uikit";
import { Dialog, TableTrips } from "@components";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  fetchCanceledTrip,
  fetchTripsList,
  sendStatusSchulingTrip,
} from "src/services/trips";
import ConfirmationDialog from "@rio-cloud/rio-uikit/ConfirmationDialog";
import { getFollowers, getFollowings } from "src/services/social";
import { Client } from "@amityco/ts-sdk";
import { useAppSelector } from "@store";

const TripsScreen = () => {
  const profile = useAppSelector((state) => state.login.userProfile);
  const [routes, setRoutes] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [usersAmity, setUsersAmity] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState("desc");
  const [fieldSort, setFieldSort] = useState("name");
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [pages, setPages] = useState(1);
  const [show, setShow] = useState(false);
  const [idDelete, setIdDelete] = useState<undefined | string>("");
  const [dashboard, setDashboard] = useState({
    allTrips: 0,
    scheduled: 0,
    new: 0,
    pending: 0,
    refused: 0,
    canceled: 0,
  });

  const [filterByType, setFilterByType] = useState<string>('ALL');
  const debouncedSearch = useDebounce(searchValue, 400);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const navigate = useNavigate();
  const [search] = useSearchParams();

  const handleUpdateRoute = (id: string) => {
    const currentParams = new URLSearchParams(search);
    currentParams.set("id", id);
    const url = "/routing/routes/update?" + currentParams.toString();
    navigate(url);
    // window.location.href = url;
  };

  const handleCanceled = async () => {
    try {
      const date = new Date().toISOString();
      await fetchCanceledTrip(
        { deletedAt: date, updatedAt: date },
        idDelete as string
      );
      initialData();
      setShow(false);
    } catch (error) {
      Notification.error("Não foi possível buscar as rotas.");
    }
  };

  const handleSelectTrip = () => {
    navigate("/routing/trips/routes");
  };

  const initialData = async (signal?: AbortSignal, search = debouncedSearch) => {
    try {
      const res = await fetchTripsList(page, 10, search, sort, fieldSort, filterByType, { signal });
      if (res.data.result) {
        setRoutes(res.data?.result?.trips);
        setDashboard({
          allTrips: res.data.result.allTrips,
          pending: res.data.result.pending,
          scheduled: res.data.result.schedules,
          new: res.data.result.suggestion,
          refused: res.data.result.refused,
          canceled: res.data.result.canceled,
        });

        setPages(res.data.result?.pages > 0 ? res.data.result?.pages : 1);
      }
    } catch (error) {
      if ((error as any).name !== "CanceledError") 
        Notification.error("Não foi possível buscar as rotas.");
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    initialData(signal);
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, debouncedSearch, sort, filterByType]);

  const handleDownload = () => {
    console.log("donwload");
  };

  const fetchFollowerAndFollowing = async () => {
    try {
      const resFollowers = await getFollowers(profile?.userId);

      setFollowers(resFollowers.data.result.follows);
      setUsersAmity(resFollowers.data.result.users);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSchedulingActionDate = async (
    status: "ACCEPT" | "REFUSE",
    tripId: string
  ) => {
    try {
      await sendStatusSchulingTrip({
        status,
        tripId,
      });
      const statusEnum = {
        ACCEPT: "aceito",
        REFUSE: "recusado",
      };
      Notification.success(`Agendamento ${statusEnum[status]} com sucesso`);
      initialData();
    } catch (error) {
      const statusEnum = {
        ACCEPT: "Não foi possível aceitar no momento.",
        REFUSE: "Não foi possível recusar no momento.",
      };

      Notification.success(statusEnum[status]);
    }
  };

  const handleSelectFilterByType = (type: string) => {
    setPage(1);
    setFilterByType(prev => {
      if (prev === type) {
        return type === 'ALL' ? 'ALL' : 'ALL';
      }
      return type;
    });
  };

  useEffect(() => {
    fetchFollowerAndFollowing();
  }, [Client.getActiveClient().token?.accessToken]);

  return (
    <PageContainer>
      <div style={{display: "flex", gap: 20}}>
        <CardCount
          isActive={filterByType === 'ALL'}
          onClick={() => handleSelectFilterByType('ALL')}
          borderColor="#00C2DF"
          label="TODAS AS VIAGENS"
          value={dashboard.allTrips}
        />

        <CardCount
          isActive={filterByType === 'ACCEPTED'}
          onClick={() => handleSelectFilterByType('ACCEPTED')}
          borderColor="#5CB85C"
          label="VIAGENS AGENDADAS"
          value={dashboard.scheduled}
        />

        <CardCount
          isActive={filterByType === 'PROPOSED'}
          onClick={() => handleSelectFilterByType('PROPOSED')}
          borderColor="#FF8E3C"
          label="NOVO DIA E HORA SUGERIDOS"
          value={dashboard.new}
        />

        <CardCount
          isActive={filterByType === 'PENDING'}
          onClick={() => handleSelectFilterByType('PENDING')}
          borderColor="#D0D8DE"
          label="ACEITE PENDENTE"
          value={dashboard.pending}
        />

        <CardCount
          isActive={filterByType === 'REFUSED'}
          onClick={() => handleSelectFilterByType('REFUSED')}
          borderColor="#D90000"
          label="VIAGENS RECUSADAS"
          value={dashboard.refused}
        />

        <CardCount
          isActive={filterByType === 'CANCELED'}
          onClick={() => handleSelectFilterByType('CANCELED')}
          borderColor="#D90000"
          label="VIAGENS CANCELADAS"
          value={dashboard.canceled}
        />
      </div>

      <TableTrips
        usersAmity={usersAmity}
        followers={followers}
        pages={pages}
        handleDownload={handleDownload}
        handleSort={(dir, field) => {
          setSort(dir);
          setFieldSort(field);
        }}
        routes={routes}
        viewType="TABLE"
        page={page}
        handleCreateRoute={handleOpenModal}
        handleUpdateRoute={handleUpdateRoute}
        handleSearchValueChange={(text) => setSearchValue(text)}
        searchValue={searchValue}
        handleScheduleAccept={(id) => handleSchedulingActionDate("ACCEPT", id)}
        handleScheduleRefused={(id) => handleSchedulingActionDate("REFUSE", id)}
        handleCancel={(id) => {
          setIdDelete(id);
          setShow(true);
        }}
        handlePage={(page) => {
          setPage(page);
        }}
      />
      <ConfirmationDialog
        show={show}
        title="Confime o Cancelamento"
        content="Tem certeza que deseja cancelar a viagem?"
        onClickConfirm={handleCanceled}
        onClickCancel={() => setShow(false)}
        cancelButtonText="Voltar"
        confirmButtonText={
          <>
            <span>Confirmar</span>
          </>
        }
        useOverflow
      />
      <Dialog.AddTrip
        open={openModal}
        onClose={handleCloseModal}
        onClickSelectRoute={handleSelectTrip}
      />
    </PageContainer>
  );
};

export default TripsScreen;
