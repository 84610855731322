import { DestinyRoute, IOnGoingTrip, OriginRoute, SocialUser } from "@types";
import Chip from "../Chip";
import ChipStatus from "../ChipStatus";
import { Button, ContentLoader } from "@rio-cloud/rio-uikit";
import { formatDateBr, formatDateUTCBr, formatValueKmMask, kmToMeters } from "@utils";
import { decode } from "src/utils/flexiblePolyline";

interface Props {
    detail?: IOnGoingTrip | null;
    origin?: OriginRoute;
    destiny?: DestinyRoute;
    stops?: Array<{ note: string; label: string }>;
    followers?: Array<SocialUser>;
    usersAmity?: Amity.User[];
    statusTripNow?: string;
    statusRouteNow?: string;
    progressNow?: string;
    handleShare?: (id: string) => void;
    type?: 'PUBLIC' | 'DEFAULT',
    currencyAddress?: string;
    handleCopy?: () => void;
}

const Label = ({ children }: { children: React.ReactNode }) => {
    return <span className="text-size-14 text-normal line-height-18 text-color-darkest">{children}</span>;
};

const Value = ({ children }: { children: React.ReactNode }) => {
    return <span className="text-size-16 text-normal line-height-20 text-color-darkest">{children}</span>;
};

const Input = ({ label, value, iconLeft }: { label: string; value: string; iconLeft: string }) => {
    return (
        <div className="display-flex align-items-center margin-top-10 margin-bottom-10">
            <div className="width-100pct">
                <label htmlFor="input5">{label}</label>
                <div className="input-group gap-15">
                    <span className="input-group-addon">
                        <span className={iconLeft} />
                    </span>
                    <input id="input5" className="form-control" type="text" placeholder="" disabled value={value} />
                    <Button bsStyle={Button.PRIMARY} iconOnly iconName="rioglyph rioglyph-checkboxes" disabled />
                </div>
            </div>
        </div>
    );
};

const SidebarDetail = ({
    detail,
    destiny,
    origin,
    stops,
    followers,
    usersAmity,
    statusTripNow,
    statusRouteNow,
    progressNow,
    handleShare,
    handleCopy,
    type = 'DEFAULT',
    currencyAddress,
}: Props) => {
    const renderChip = (driverId: string) => {
        const follower = followers?.filter((res) => res.from === driverId);

        return follower?.length ? (
            <Chip text="Conectado" icon="rioglyph rioglyph-user-group" background="bg-status-driving" />
        ) : (
            <Chip text="Minha equipe" icon="rioglyph rioglyph-role-management" background="bg-status-working" />
        );
    };

    const renderLocation = (item?: string) => {
        if (!item) {
            return '';
        }
        const d = decode(item);
        const lastIndex = d.polyline[d.polyline.length - 1];

        return `${lastIndex[0]}, ${lastIndex[1]}`
    }

    return (
      <div>
        {type !== "PUBLIC" ? (
          <div className="display-flex flex-direction-row align-items-center padding-x-15">
            <div
              className="width-50 aspect-ratio-1 bg-highlight padding-5 display-flex align-items-center justify-content-center rounded-circle bg-size-100pct bg-repeat-no bg-light"
              style={{
                backgroundImage: `url(${
                  usersAmity?.find((res) => detail?.driverId === res.userId)?.metadata?.imageProfile?.fileUrl ||
                  "https://cdn.rio.cloud/svg/common/ico_rio_colored.svg"
                })`,
              }}
            />
            <div className="padding-x-15">
              <span className="text-bold text-size-18 text-capitalize line-height-20">{detail?.driverName}</span>
              <div className="margin-top-5">{renderChip(detail?.driverId as string)}</div>
            </div>
          </div>
        ) : null}

        {type === "PUBLIC" ? (
          <div className="display-flex align-items-center padding-y-20" style={{ marginLeft: 6, marginBottom: 4 }}>
            <span className="rioglyph rioglyph-truck text-size-h1"></span>
            <span className="text-bold text-size-18">{detail?.assetName}</span>
          </div>
        ) : null}

        {type === "PUBLIC" ? (
          <>
            <div className="padding-y-16 padding-x-15">
              <span>Ultima Posição identificada</span>
              <br />
              <span className="text-normal text-size-16">{currencyAddress}</span>
            </div>

            <div className="display-flex padding-y-15 padding-x-15">
              <div className="display-flex align-items-center gap-4">
                <span className="rioglyph rioglyph-map-marker text-size-18 text-color-primary"></span>
                <span className="text-normal text-size-16">
                  {detail?.latLongActually?.lat}, {detail?.latLongActually?.lng}
                </span>
              </div>
            </div>

            <div className="display-flex padding-y-15 padding-x-15">
              <div className="width-50pct">
                <span>Horário de saida</span>
                <br />
                <span className="text-normal text-size-16">{formatDateUTCBr(detail?.departure)}</span>
              </div>
              <div className="width-50pct">
                <span>Previsão de chegada</span>
                <br />
                <span className="text-normal text-size-16">{formatDateUTCBr(detail?.arrival)}</span>
              </div>
            </div>
          </>
        ) : null}

        <div className="margin-top-15 flex-1-1 bg-white border rounded divider-y-1 divider-color-light divider-style-solid">
          <div className="display-flex align-items-center justify-content-between padding-y-10 padding-x-15 border-bottom-width-1">
            <div>
              <Label>Agendamento</Label>
            </div>
            <div style={{ width: 130 }}>
              <Value>{formatDateBr(detail?.dateStarted)}</Value>
            </div>
          </div>
          <div className="display-flex align-items-center justify-content-between padding-y-10 padding-x-15 border-bottom-width-1">
            <div>
              <Label>Status da rota</Label>
            </div>
            <div style={{ width: 130 }}>
              {statusRouteNow ? (
                <ChipStatus status={statusRouteNow} />
              ) : (
                <ChipStatus
                  status={
                    detail?.tripStatusNow === "NOTSTARTED" && !detail?.polyline?.length
                      ? "NOTSTARTED"
                      : detail?.routeStatusNow
                  }
                />
              )}
            </div>
          </div>
          <div className="display-flex align-items-center justify-content-between padding-y-10 padding-x-15 border-bottom-width-1">
            <div>
              <Label>Status da chegada</Label>
            </div>
            <div style={{ width: 130 }}>
              <ChipStatus
                status={
                  detail?.tripStatusNow === "NOTSTARTED" && detail?.polyline && detail?.polyline?.length > 0
                    ? "PAUSED"
                    : detail?.tripStatusNow === "NOTSTARTED" && !detail?.polyline?.length
                    ? "NOTSTARTED"
                    : detail?.tripStatusNow
                }
              />
            </div>
          </div>
          <div className="display-flex align-items-center justify-content-between padding-y-10 padding-x-15 border-bottom-width-1">
            <div>
              <Label>Progresso</Label>
            </div>
            <div className="display-flex align-items-center gap-15">
              <div className="width-200">
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-primary"
                    style={{
                      width: progressNow ? progressNow : detail?.actualProgress ? detail?.actualProgress : "0%",
                    }}
                  />
                </div>
              </div>
              <Value>{progressNow ? progressNow : detail?.actualProgress}</Value>
            </div>
          </div>
          <div className="display-flex align-items-center justify-content-between padding-y-10 padding-x-15 border-bottom-width-1">
            <div className="display-flex align-items-center gap-4">
              <span className="rioglyph rioglyph-time-alt text-color-gray"></span>
              <Label>ETA</Label>
            </div>
            <div>
              <Value>{detail?.eta}</Value>
            </div>
          </div>

          <div className="display-flex align-items-center justify-content-between padding-y-10 padding-x-15 border-bottom-width-1">
            <div className="display-flex align-items-center gap-4">
              <span className="rioglyph rioglyph-time text-color-gray"></span>
              <Label>Duração</Label>
            </div>
            <div>
              <Value>{detail?.eta}</Value>
            </div>
          </div>
          <div className="display-flex align-items-center justify-content-between padding-y-10 padding-x-15 border-bottom-width-1">
            <div className="display-flex align-items-center gap-4">
              <span className="rioglyph rioglyph-road text-color-gray" />
              <Label>{type === "DEFAULT" ? "Distância" : "Distância restante atual"}</Label>
            </div>
            <div>
              <Value>
                {type === "DEFAULT"
                  ? detail?.distance?.toLowerCase()?.replace(/\./g, ",")
                  : Number(detail?.distance) / 1000 > 0
                  ? (Number(detail?.distance) / 1000)?.toFixed(2)?.toLowerCase()?.replace(/\./g, ",") + " km"
                  : "0,0 km"}
              </Value>
            </div>
          </div>

          {type !== "PUBLIC" ? (
            <div className="display-flex align-items-center justify-content-between padding-y-10 padding-x-15 border-bottom-width-1">
              <div className="display-flex align-items-center gap-4">
                <span className="rioglyph rioglyph-truck text-color-gray"></span>
                <Label>Veículo</Label>
              </div>
              <div>
                <Value>{detail?.assetName}</Value>
              </div>
            </div>
          ) : null}
        </div>

        {type !== "PUBLIC" &&
        detail?.polyline &&
        detail?.polyline.length > 0 &&
        detail.tripStatusNow !== "NOTSTARTED" ? (
          <div className="display-flex align-items-center justify-content-between padding-y-20 padding-x-15 border-bottom-width-1">
            <div className="display-flex align-items-center gap-4">
              <span className="rioglyph rioglyph-map-marker text-size-18 text-color-primary"></span>
              <span className="text-normal text-size-16">
                {renderLocation(detail?.polyline[detail?.polyline?.length - 1])}
              </span>
            </div>
            <div>
              <button type="button" className="btn btn-default" onClick={() => handleShare?.(detail?.tripId as string)}>
                Compartilhar viagem
              </button>
            </div>
          </div>
        ) : null}

        {type !== "PUBLIC" ? (
          <div className="padding-y-10 padding-x-15">
            <Input label="Origem" value={origin?.address?.label || ""} iconLeft="rioglyph rioglyph-start" />
            {stops && stops.length ? (
              <div className="padding-left-20">
                {stops.map((s) => {
                  return <Input label={s.note} value={s.label} iconLeft="rioglyph rioglyph-pushpin" />;
                })}
              </div>
            ) : null}
            <Input label="Destino" value={destiny?.address?.label || ""} iconLeft="rioglyph-finish" />
          </div>
        ) : null}

        {type === "PUBLIC" ? (
          <div className="display-flex width-100pct padding-y-15 padding-x-15">
            <button type="button" className="btn btn-default width-100pct" onClick={handleCopy}>
              Copiar link de rastreio
            </button>
          </div>
        ) : null}
      </div>
    );
};

export default SidebarDetail;


