import {ExpanderPanel} from "@rio-cloud/rio-uikit";
import React, {useEffect, useRef, useState} from "react";
import Button from "@rio-cloud/rio-uikit/Button";
import Dialog from "@rio-cloud/rio-uikit/Dialog";
import "./styles.css";
import {Coordinates} from "../Map";
import ConfirmationDialog from "@rio-cloud/rio-uikit/ConfirmationDialog";
import CustomInputSelect from "../CustomInputSelect/CustomInputSelect";
import Notification from "@rio-cloud/rio-uikit/Notification";
import {CreateAreaPayload} from "../../types/areas";
import {defineAreas, defineAvoidAreas} from "../../store/routes/RoutesSlice";
import {useDispatch} from "react-redux";
import {useAppSelector} from "@store";

type ModalData = {
    id: string;
    type: "interest" | "avoid";
    coordinates: Coordinates[];
    radius?: number | null;
    formatted?: string;
};

interface Props {
    handleCancel: (data: ModalData) => void;
    handleSave: () => void;
    modalData: ModalData;
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
}

const permanencyTypeOptions = [
    {label: "Horas", value: "HOUR"},
    {label: "Minutos", value: "MINUTE"},
    {label: "Dias", value: "DAY"},
];

const createKmInputHandlers = (
    setter: React.Dispatch<React.SetStateAction<string>>,
    minKmInputValue: string,
    setIsMaxVelocity: React.Dispatch<React.SetStateAction<boolean>>,
    nextInputRef?: React.RefObject<HTMLInputElement>
) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = e.target.value.replace(/[^0-9]/g, "");
        setter(rawValue);

        if (minKmInputValue !== "" && rawValue !== "") {
            const minKm = parseInt(minKmInputValue, 10);
            const maxKm = parseInt(rawValue, 10);
            if (maxKm >= minKm) {
                setIsMaxVelocity(false);
            } else {
                setIsMaxVelocity(true);
            }
        } else {
            setIsMaxVelocity(false);
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const inputValue = e.target.value.trim();
        if (inputValue === "") {
            setter("");
            return;
        }

        let numericValue = parseInt(e.target.value, 10);
        if (isNaN(numericValue)) {
            numericValue = 0;
        }

        const formattedValue = `${numericValue} km/h`;
        setter(formattedValue);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Tab" && nextInputRef && nextInputRef.current) {
            e.preventDefault();
            nextInputRef.current.focus();
        }
    };

    return {handleChange, handleBlur, handleKeyDown};
};

const createTimeInputTextHandlers = (
    setter: React.Dispatch<React.SetStateAction<string>>,
    type: string,
    compareValue: string,
    setIsTimeError: React.Dispatch<React.SetStateAction<boolean>>,
    nextInputRef?: React.RefObject<HTMLInputElement>,
    isForMax: boolean = false
) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = e.target.value.replace(/[^0-9]/g, "");
        setter(rawValue);

        if (compareValue !== "" && rawValue !== "") {
            const compareNumber = parseInt(compareValue, 10);
            const currentNumber = parseInt(rawValue, 10);

            if (isForMax) {
                setIsTimeError(currentNumber < compareNumber);
            } else {
                setIsTimeError(currentNumber >= compareNumber);
            }
        } else {
            setIsTimeError(false);
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const inputValue = e.target.value.trim();
        if (inputValue === "") {
            setter("");
            return;
        }

        let numericValue = parseInt(e.target.value, 10);
        if (isNaN(numericValue)) {
            numericValue = 0;
        }

        const formattedValue = `${numericValue} ${type === "HOUR" ? "horas" : type === "MINUTE" ? "minutos" : "dias"}`;
        setter(formattedValue);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Tab" && nextInputRef && nextInputRef.current) {
            e.preventDefault();
            nextInputRef.current.focus();
        }
    };

    return {handleChange, handleBlur, handleKeyDown};
};

const createTextInputHandlers = (
    setter: React.Dispatch<React.SetStateAction<string>>,
    nextInputRef?: React.RefObject<HTMLInputElement>
) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setter(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {

        if (e.key === "Tab" && nextInputRef && nextInputRef.current) {
            e.preventDefault();
            nextInputRef.current.focus();
        }
    };

    return {handleChange, handleKeyDown};
};

const ModalMapInterestArea = ({
                                  handleCancel,
                                  handleSave,
                                  modalData,
                                  openModal,
                                  setOpenModal,
                              }: Props) => {
    const dispatch = useDispatch();
    const {areas} = useAppSelector((state) => state.routes);
    const {areasToAvoid} = useAppSelector((state) => state.routes);
    const [confirmCloseModal, setConfirmCloseModal] = useState(false);
    const [selectedMinPermanencyTimeOption, setSelectedMinPermanencyTimeOption] = useState("HOUR");
    const [selectedMaxPermanencyTimeOption, setSelectedMaxPermanencyTimeOption] = useState("HOUR");
    const [minKmInputValue, setMinKmInputValue] = useState("");
    const [maxKmInputValue, setMaxKmInputValue] = useState("");
    const [minTimeInputValue, setMinTimeInputValue] = useState("");
    const [maxTimeInputValue, setMaxTimeInputValue] = useState("");
    const [areaName, setAreaName] = useState("");

    const [isAreaNameEmpty, setIsAreaNameEmpty] = useState(false);
    const [isMaxVelocity, setIsMaxVelocity] = useState(false);
    const [isMaxTime, setIsMaxTime] = useState(false);

    const areaNameRef = useRef<HTMLInputElement>(null);
    const kmInputRef = useRef<HTMLInputElement>(null);
    const timeInputRef = useRef<HTMLInputElement>(null);

    const minKmHandlers = createKmInputHandlers(setMinKmInputValue, "", () => {
    });
    const maxKmHandlers = createKmInputHandlers(setMaxKmInputValue, minKmInputValue, setIsMaxVelocity, kmInputRef);
    const areaNameHandlers = createTextInputHandlers(setAreaName);
    const minTimeInputHandlers = createTimeInputTextHandlers(
        setMinTimeInputValue,
        selectedMinPermanencyTimeOption,
        maxTimeInputValue,
        setIsMaxTime,
        timeInputRef,
        false
    );
    const maxTimeInputHandlers = createTimeInputTextHandlers(
        setMaxTimeInputValue,
        selectedMaxPermanencyTimeOption,
        minTimeInputValue,
        setIsMaxTime,
        timeInputRef,
        true
    );

    const handleSelectedMinPermanencyChange = (newValue: string | number) => {
        const selectedMinPermanency = permanencyTypeOptions.find((option) => option.value === newValue);
        setSelectedMinPermanencyTimeOption(selectedMinPermanency?.value || "");
        const inputValue = minTimeInputValue.replace(/[^0-9]/g, "");
        const formattedValue = `${inputValue} ${selectedMinPermanency?.value === "HOUR" ? "horas" : selectedMinPermanency?.value === "MINUTE" ? "minutos" : "dias"}`;
        setMinTimeInputValue(formattedValue);
    };

    const handleSelectedMaxPermanencyChange = (newValue: string | number) => {
        const selectedMaxPermanency = permanencyTypeOptions.find((option) => option.value === newValue);
        setSelectedMaxPermanencyTimeOption(selectedMaxPermanency?.value || "");
        const inputValue = maxTimeInputValue.replace(/[^0-9]/g, "");
        const formattedValue = `${inputValue} ${selectedMaxPermanency?.value === "HOUR" ? "horas" : selectedMaxPermanency?.value === "MINUTE" ? "minutos" : "dias"}`;
        setMaxTimeInputValue(formattedValue);
    };

    const handleCancelModal = () => {
        setConfirmCloseModal(true);
    };

    const handleOnCloseValidation = () => {
        setConfirmCloseModal(true);
        return false;
    }

    const handleCloseModal = () => {
        handleCancel(modalData);
        setConfirmCloseModal(false);
        setTimeout(() => {
            setOpenModal(false);
        }, 500);
        cleanFields();
    };

    const handleSubmitModal = () => {
        try {
            if (!areaName) {
                setIsAreaNameEmpty(true);
                Notification.info("O campo Nome é obrigatório.");
                if (areaNameRef.current) {
                    areaNameRef.current.focus();
                }
                return;
            }

            if (modalData.type === "interest") {
                const trimmedMinTime = minTimeInputValue.trim();
                const trimmedMaxTime = maxTimeInputValue.trim();

                if (trimmedMinTime !== "") {
                    const minTimeNumber = parseInt(trimmedMinTime, 10);
                    if (isNaN(minTimeNumber) || minTimeNumber <= 0) {
                        Notification.info("O campo Tempo de Permanência Mínimo deve ser vazio ou um valor maior que zero.");
                        return;
                    }
                }
                if (trimmedMaxTime !== "") {
                    const maxTimeNumber = parseInt(trimmedMaxTime, 10);
                    if (isNaN(maxTimeNumber) || maxTimeNumber <= 0) {
                        Notification.info("O campo Tempo de Permanência Máximo deve ser vazio ou um valor maior que zero.");
                        return;
                    }
                }

                if (trimmedMinTime !== "" && trimmedMaxTime !== "") {
                    const minTime = parseInt(trimmedMinTime, 10);
                    const maxTime = parseInt(trimmedMaxTime, 10);
                    if (maxTime < minTime) {
                        setIsMaxTime(true);
                        Notification.info("O valor máximo de tempo de permanência deve ser maior que o valor mínimo.");
                        if (timeInputRef.current) {
                            timeInputRef.current.focus();
                        }
                        return;
                    }
                }

                if (minKmInputValue !== "" && maxKmInputValue !== "") {
                    const minKm = parseInt(minKmInputValue, 10);
                    const maxKm = parseInt(maxKmInputValue, 10);
                    if (isNaN(minKm) || isNaN(maxKm)) {
                        Notification.info("Valores de velocidade inválidos.");
                        return;
                    }
                    if (maxKm < minKm) {
                        setIsMaxVelocity(true);
                        Notification.info("O valor máximo de velocidade deve ser maior que o valor mínimo.");
                        if (kmInputRef.current) {
                            kmInputRef.current.focus();
                        }
                        return;
                    }
                }
            }

            const newArea: CreateAreaPayload = {
                id: modalData.id,
                name: areaName,
                routeId: "",
                points: modalData.coordinates,
                areaTypeId: modalData.radius === undefined ? 1 : 2,
                interestType: modalData.type === "interest" ? "INTEREST" : "AVOID",
                circleRadius: modalData.radius || undefined,
                velocityMin: minKmInputValue ? parseInt(minKmInputValue, 10) : null,
                velocityMax: maxKmInputValue ? parseInt(maxKmInputValue, 10) : null,
                stayTimeMin: minTimeInputValue ? parseInt(minTimeInputValue, 10) : null,
                stayTimeMax: maxTimeInputValue ? parseInt(maxTimeInputValue, 10) : null,
                stayTimeTypeMin: minTimeInputValue ? selectedMinPermanencyTimeOption : null,
                stayTimeTypeMax: maxTimeInputValue ? selectedMaxPermanencyTimeOption : null,
            };

            updateAvoidAreas();
            dispatch(defineAreas([...areas, newArea]));

            const mensagem = modalData.type === "interest" ? "Área de interesse" : "Área a ser evitada";
            Notification.info(`${mensagem} adicionado com sucesso!`);
            handleSave();
            setOpenModal(false);
            cleanFields();
        } catch (error) {
            Notification.error("Não foi possível criar a área.");
            console.error(error);
        }
    };

    const updateAvoidAreas = () => {
        if (modalData.type === "avoid") {
            const updatedFormatted = areasToAvoid.formatted
                ? `${areasToAvoid.formatted}|${modalData.formatted}`
                : modalData.formatted;
            dispatch(defineAvoidAreas({ formatted: updatedFormatted }));
        }
    }

    const cleanFields = () => {
        setAreaName("");
        setMinKmInputValue("");
        setMaxKmInputValue("");
        handleSelectedMinPermanencyChange(permanencyTypeOptions[0].value);
        handleSelectedMaxPermanencyChange(permanencyTypeOptions[0].value);
    }

    const dynamicContentDialog = () => {
        return (
            <div className="display-flex">
                <div
                    className="border border-color-primary border-width-3 text-center dynamic-content-dialog-header">
                <span
                    className="rioglyph rioglyph-layer text-color-primary dynamic-content-dialog-header-icon"
                />
                </div>
                <div className="display-grid gap-10" style={{flex: 1}}>
                    <div>
                        <label className="text-color-dark">Nome</label>
                        <input
                            type="text"
                            className={`input-base ${isAreaNameEmpty ? "input-error" : "input"}`}
                            autoFocus={true}
                            value={areaName}
                            onChange={(e) => {
                                setAreaName(e.target.value);
                                setIsAreaNameEmpty(false);
                            }}
                            onKeyDown={areaNameHandlers.handleKeyDown}
                            ref={areaNameRef}
                        />
                    </div>
                    {modalData.type !== "avoid" && (
                        <>
                            <ExpanderPanel
                                title="Velocidade"
                                bsStyle="blank"
                                titleClassName="text-bold margin-y-10"
                                bodyClassName="padding-0"
                            >
                                <div className="form-group padding-left-0">
                                    <div className="velocity-container">
                                        <div className="velocity-item">
                                            <label htmlFor="areaName" className="dynamic-content-dialog-label">Mínima
                                                (opcional) </label>
                                            <input
                                                type="text"
                                                className="input"
                                                value={minKmInputValue}
                                                onChange={minKmHandlers.handleChange}
                                                onBlur={minKmHandlers.handleBlur}
                                                onKeyDown={minKmHandlers.handleKeyDown}
                                            />
                                        </div>
                                        <div className="velocity-item">
                                            <label htmlFor="areaName" className="dynamic-content-dialog-label">Máxima
                                                (opcional) </label>
                                            <input
                                                type="text"
                                                className={`input-base ${isMaxVelocity ? "input-error" : "input"}`}
                                                value={maxKmInputValue}
                                                onChange={maxKmHandlers.handleChange}
                                                onBlur={maxKmHandlers.handleBlur}
                                                onKeyDown={maxKmHandlers.handleKeyDown}
                                                ref={kmInputRef}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </ExpanderPanel>
                            <ExpanderPanel
                                title="Tempo de permanência"
                                bsStyle="blank"
                                titleClassName="text-bold margin-y-10"
                                bodyClassName="padding-0"
                            >
                                <div className="form-group padding-left-0">
                                    <div className="permanency-container">
                                        <div className="permanency-minimum-container">
                                            <div className="permanency-item">
                                                <label htmlFor="areaName" className="dynamic-content-dialog-label">Mínimo
                                                    (opcional) </label>
                                                <input
                                                    type="text"
                                                    className="input"
                                                    value={minTimeInputValue}
                                                    onChange={minTimeInputHandlers.handleChange}
                                                    onBlur={minTimeInputHandlers.handleBlur}
                                                    onKeyDown={minTimeInputHandlers.handleKeyDown}
                                                />
                                            </div>
                                            <div className="permanency-item">
                                                <label htmlFor="areaName"
                                                       className="dynamic-content-dialog-label-align"></label>
                                                <CustomInputSelect
                                                    options={permanencyTypeOptions}
                                                    value={selectedMinPermanencyTimeOption !== undefined ? selectedMinPermanencyTimeOption : ""}
                                                    onChange={handleSelectedMinPermanencyChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="permanency-maximum-container">
                                            <div className="permanency-item">
                                                <label htmlFor="areaName" className="dynamic-content-dialog-label">Máximo
                                                    (opcional)</label>
                                                <input
                                                    type="text"
                                                    className={`input-base ${isMaxTime ? "input-error" : "input"}`}
                                                    value={maxTimeInputValue}
                                                    onChange={maxTimeInputHandlers.handleChange}
                                                    onBlur={maxTimeInputHandlers.handleBlur}
                                                    onKeyDown={maxTimeInputHandlers.handleKeyDown}
                                                    ref={timeInputRef}
                                                />
                                            </div>
                                            <div className="permanency-item">
                                                <label htmlFor="areaName"
                                                       className="dynamic-content-dialog-label-align"></label>
                                                <CustomInputSelect
                                                    options={permanencyTypeOptions}
                                                    value={selectedMaxPermanencyTimeOption !== undefined ? selectedMaxPermanencyTimeOption : ""}
                                                    onChange={handleSelectedMaxPermanencyChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ExpanderPanel>
                        </>
                    )}
                </div>
            </div>
        );
    }

    const dynamicFooterDialog = () => {
        return (
            <div className="display-flex gap-10 justify-content-end">
                <Button
                    bsStyle="primary"
                    className="btn btn-primary btn-outline btn-lg width-100"
                    onClick={() => handleCancelModal()}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={false}
                    bsStyle="primary"
                    className="btn-lg width-100"
                    onClick={() => {
                        handleSubmitModal();
                    }}
                >
                    Salvar
                </Button>
            </div>
        );
    }

    const getDialogTitle = () => {
        return `Criar área de ${modalData.type === "interest" ? "interesse" : "área a ser evitada"}`;
    };

    const getConfirmationDialogTitle = () => {
        return modalData.type === "interest" ? "Deseja excluir a área de interesse?" : "Deseja excluir a área a ser evitada?";
    };

    const getConfirmationDialogContent = () => {
        return modalData.type === "interest" ? "Tem certeza que deseja excluir a área de interesse?" : "Tem certeza que deseja excluir a área a ser evitada?";
    };

    return (
        <>
            <Dialog
                show={openModal}
                title={getDialogTitle()}
                onCloseValidation={handleOnCloseValidation}
                showCloseButton
                bsSize="md"
                body={dynamicContentDialog()}
                footer={dynamicFooterDialog()}
            />
            <ConfirmationDialog
                show={confirmCloseModal}
                title={getConfirmationDialogTitle()}
                content={getConfirmationDialogContent()}
                onClickConfirm={handleCloseModal}
                onClickCancel={() => {
                    setConfirmCloseModal(false);
                    setOpenModal(true);
                }}
                cancelButtonText="Cancelar"
                confirmButtonText={<span>Excluir</span>}
                useOverflow
            />
        </>
    );
}

export default ModalMapInterestArea;