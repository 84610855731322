import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { main } from "./utils/configuration";
import { handleLoginRedirect } from "./utils/configuration/login/redirect";
import { config } from "./config";
import RouteView from "./screens/RouteView";

const renderApplication = () => {
  createRoot(document.getElementById("root") as HTMLElement).render(<App />);
};

const renderPublic = () => {
  createRoot(document.getElementById("root") as HTMLElement).render(<RouteView />);
}

if (window.location.href.startsWith('https://latam-routing.rio.cloud/route/view' as string)) {
  renderPublic();
} else if (window.location.href.startsWith(config.login.redirectUri as string)) {
  handleLoginRedirect();
} else {
  main(renderApplication);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
