import { DatePicker } from "@rio-cloud/rio-uikit";

interface Props {
  label?: string;
  value: string | Date | moment.Moment | undefined;
  timeFormat?: string | boolean;
  dateFormat: string | boolean;
  onChange?:
    | ((value: moment.Moment | string, isValid: boolean) => void)
    | undefined;
  inputProps?: React.HTMLProps<HTMLInputElement> | undefined;
  errorMessage?: string;
}

const InputDate = ({
  label,
  value,
  timeFormat,
  dateFormat,
  onChange,
  inputProps,
  errorMessage,
}: Props) => {
  return (
    <div className={errorMessage ? "form-group has-feedback has-error padding-left-0 margin-bottom-20 margin-top-20 padding-0" : "margin-bottom-20 margin-top-20"}>
      {label ? (
        <div className="margin-bottom-4">
          <span className="text-size-14 text-normal text-color-dark line-height-16">
            {label}
          </span>
        </div>
      ) : null}

      <DatePicker
        className="padding-left-0 border-color-black margin-bottom-4"
        inputProps={inputProps}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        locale="pt-BR"
        value={value}
        onChange={onChange}
      />
      {errorMessage ? (
        <>
          <span className="form-control-feedback rioglyph rioglyph-error-sign" />
          <span className="help-block">
            <span>{errorMessage}</span>
          </span>
        </>
      ) : null}
    </div>
  );
};

export default InputDate;
