import styled from "styled-components";

export const Container = styled.div`
    padding-top: 24px;
    padding-bottom: 24px;
    background-color: #FFFFFF;
    width: 100%;
    height: 98px;
    border-radius: 4px;
`;

export const Content = styled.div`
    border-left: 8px solid green;
    height: 50px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
    justify-content: space-between;
`;

export const ContentLabel = styled.div`
`;

export const ContentOptions = styled.div`
    display: flex;
    align-items: center;
`;

export const GlobalIcon = styled.div`
    width: 64px;
    height: 64px;
    font-size: 64px;
    color: #D0D8DE;
    margin-right: 10px;
`;

export const OptionalIcon = styled.div`
    font-size: 50px;
    color: #D0D8DE;
    margin-right: 10px;
`;

export const Divider = styled.div`
    width: 1px;
    height: 33px;
    background: #A7AFBB;
    margin-right: 10px;
`;

export const Label = styled.span`
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 400;
    line-height: 25.14px;
    text-align: left;
    color: #697A8B;
    margin-left:5px;
`;

export const Label1 = styled.span`
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 400;
    line-height: 25.14px;
    text-align: left;
    color: #30B4C0;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 16px;
`;

export const Value = styled.span`
    font-family: Source Sans Pro;
    font-size: 40px;
    font-weight: 600;
    line-height: 50.28px;
    text-align: left;
    color: #30B4C0;
`;

