import { combineReducers } from "@reduxjs/toolkit";
import { ExampleSlice } from "./example/ExampleSlice";
import { SidebarSlice } from "./sidebar/SidebarSlice";
import { RoutesSlice } from "./routes/RoutesSlice";
import { TripsSlice } from "./trips/TripsSlice";
import { langSlice } from "./lang/langSlice";
import { userSlice } from "./user/userSlice";
import { configSlice } from "./config/configSlice";
import { appSlice } from "./app/appSlice";
import { LoginSlice } from "./login/LoginSlice";
import { TokenSlice } from "./token/TokenSlice";

export const reducers = combineReducers({
  example: ExampleSlice.reducer,
  sidebar: SidebarSlice.reducer,
  routes: RoutesSlice.reducer,
  trips: TripsSlice.reducer,
  lang: langSlice.reducer,
  config: configSlice.reducer,
  app: appSlice.reducer,
  user: userSlice.reducer,
  login: LoginSlice.reducer,
  tokenHandling: TokenSlice.reducer,
});
