import { Checkbox, ExpanderList } from "@rio-cloud/rio-uikit";
import { useState } from "react";
import Chip from "../Chip";
import { Driver, SocialUser } from "@types";

interface Props {
  data: Array<Driver>;
  selected: string;
  onClickButton: (
    type: "CALENDAR" | "PROFILE" | "VEHICLE",
    id?: string,
    data?: Driver
  ) => void;
  onChecked: (
    checked: boolean,
    id: string,
    name: string,
    userId?: string,
    avatar?: string,
  ) => void;
}

const ExpanderListDriver = ({
  data,
  onClickButton,
  onChecked,
  selected,
}: Props) => {
  const renderHeader = ({ name, userId, id, type, avatar }: Driver) => {
    return (
      <div className="display-flex align-items-center gap-20">
        <fieldset className="margin-bottom-10">
          <Checkbox
            checked={selected === id} // temp remover
            onChange={(event) =>
              onChecked(event.target.checked, id, name as string, userId, avatar)
            }
          />
        </fieldset>
        <div className="display-flex align-items-center gap-10">
          <div
            className="width-30 height-30 rounded-circle aspect-ratio-1 bg-size-100pct bg-repeat-no bg-light"
            style={{
              backgroundImage: `url(${
                avatar
                  ? avatar
                  : "https://cdn.rio.cloud/svg/common/ico_rio_colored.svg"
              })`,
            }}
          />

          <div>
            <span className="text-size-16 text-normal line-height-20 text-capitalize">
              {name}
            </span>
            <br />
            {type === "CONNECTED" ? (
              <Chip
                text="Conectado"
                icon="rioglyph rioglyph-user-group"
                background="bg-status-driving"
              />
            ) : (
              <Chip
                text="Minha equipe"
                icon="rioglyph rioglyph-role-management"
                background="bg-status-working"
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderBody = (d: Driver) => {
    return (
      <div className="display-flex align-items-center gap-20">
        <button
          type="button"
          className="btn btn-default"
          style={{ width: 160 }}
          onClick={() => onClickButton("PROFILE", d.userId, d)}
        >
          <span className="rioglyph rioglyph-user" aria-hidden="true"></span>
          <span>Ver perfil</span>
        </button>
        {/* <button
          type="button"
          className="btn btn-default"
          style={{ width: 160 }}
          onClick={() => onClickButton("VEHICLE", d.userId, d)}
        >
          <span className="rioglyph rioglyph-truck" aria-hidden="true"></span>
          <span>Ver veículo</span>
        </button>
        <button
          type="button"
          className="btn btn-default"
          style={{ width: 160 }}
          onClick={() => onClickButton("CALENDAR", d.userId, d)}
        >
          <span
            className="rioglyph rioglyph-calendar"
            aria-hidden="true"
          ></span>
          <span>Ver calendário</span>
        </button> */}
      </div>
    );
  };

  return (
    <ExpanderList
      unmountOnExit
      items={data.map((driver, index) => {
        return {
          id: index,
          header: renderHeader(driver),
          body: renderBody(driver),
          bodyClassName: "margin-bottom-20",
        };
      })}
      bordered={true}
      rounded={false}
    />
  );
};

export default ExpanderListDriver;
