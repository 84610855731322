import * as St from './styles'
import {OptionalIcon} from "./styles";

interface Props {
    borderColor: string;
    label: string;
    label1?: string;
    value1: string | number;
    label2?: string | number;
    value2?: string | number;
    icon?: string;
    icon1?: string;

}

const CardCountAreas = ({borderColor, label, label1, value1, label2, value2}: Props) => {
    return (
        <St.Container className='rounded bg-white shadow-smooth'>
            <St.Content style={{borderLeftColor: borderColor}}>
                <St.ContentLabel>
                    <St.Label>{label}</St.Label>
                </St.ContentLabel>
                <St.ContentOptions>
                    <St.GlobalIcon>
                        <div className="rioglyph rioglyph-globe"></div>
                    </St.GlobalIcon>
                    <St.Value>{value1}</St.Value>
                    <St.Label1>{label1}</St.Label1>
                    <St.Divider/>
                    <St.OptionalIcon>
                        <div className="rioglyph rioglyph rioglyph-settings"></div>
                    </St.OptionalIcon>
                    <St.Value>{value2}</St.Value>
                    <St.Label1>{label2}</St.Label1>
                </St.ContentOptions>
            </St.Content>
        </St.Container>
    )
}

export default CardCountAreas;