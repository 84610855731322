import { INotification } from "src/providers/NotificationProvider";
import NotificationItem from "../NotificationItem";

interface Props {
    data: Array<INotification>;
    title: string;
}

const NotificationList = ({ data, title }: Props) => {
    return (
        <div className="panel panel-default margin-bottom-0">
            <div className="panel-heading padding-x-25 padding-y-15" style={{ borderBottomWidth: 2 }}>
                <div className="display-flex column-gap-10 justify-content-between divider">
                    <span className="text-bold text-size-16 line-height-24 ">{title}</span>
                </div>
            </div>

            {data && data.length > 0 ? (
                <div className="panel-body padding-0">
                    <ul className="list-group divider-y-2">
                        {data.map((notification, index) => {
                            return (
                                <li className="list-group-item padding-0 rounded-bottom-left rounded-bottom-right" key={index}>
                                    <NotificationItem {...notification} />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            ) : (
                <div className="display-flex justify-content-center max-width-100pct">
                    <div className="margin-0 width-100pct max-width-600 panel-default panel padding-15 border-none">
                        <div className="display-flex flex-column align-items-center text-center padding-25">
                            <div className="text-size-h2 margin-bottom-20">
                                <span className="rioglyph text-color-light rioglyph rioglyph-notification text-size-300pct"></span>
                            </div>
                            <div className="text-size-h3 text-size-h2-sm line-height-125rel text-color-dark text-medium width-500 max-width-100pct">
                                Não há novas notificações
                            </div>
                            <div className="text-color-dark margin-top-15 width-500 max-width-100pct">
                                Se algo novo surgir, avisaremos você aqui.
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NotificationList;
