import {
  Button,
  Dialog,
  ExpanderList,
} from "@rio-cloud/rio-uikit";
import React, { ReactNode, useCallback, useEffect } from "react";
import { Driver } from "@types";
import { useSocialProfileUser } from "./usePerfilSocial";
import { useSocialProfileNoTrucker } from './useProfileNoTrucker';


interface Props {
  driver: Driver|undefined;
  open: boolean;
  onClose: () => void;
}

type DataArr = {
  id: any;
  label: string;
  secondary?: string;
  image?: string;
};

type CardProps = {
  label: string,
  secondary?: string,
  icon?: string|ReactNode,
}

type CommentProps = {
  label: string,
  secondary?: string,
  image?: string,
}

type SectionProps = {
  data: Array<DataArr>, 
  iconBody: string, 
  customAction?: ReactNode,
}

export const CNH_CATEGORY_DATA = [
  { 
    id: 'A', 
    label: 'Categoria A', 
    value: 'Categoria A',
    description: 'Habilita o condutor a dirigir veículos de duas ou três rodas (motocicletas, motonetas e ciclomotores).'
  },
  { 
    id: 'B', 
    label: 'Categoria B', 
    value: 'Categoria B',
    description: 'Permite conduzir veículos de até 3.500 kg (PBT) e até 8 passageiros (excluindo o motorista).'
  },
  { 
    id: 'C', 
    label: 'Categoria C', 
    value: 'Categoria C',
    description: 'Habilita a dirigir veículos de carga com peso bruto total superior a 3.500 kg.'
  },
  { 
    id: 'D', 
    label: 'Categoria D', 
    value: 'Categoria D',
    description: 'Permite conduzir veículos de transporte coletivo com mais de 8 passageiros (excluindo o motorista).'
  },
  { 
    id: 'E', 
    label: 'Categoria E', 
    value: 'Categoria E',
    description: 'Autoriza a condução de combinações de veículos (reboques/semirreboques) acima de 6.000 kg ou mais de 8 lugares.'
  },
  { 
    id: 'ACC', 
    label: 'Categoria ACC', 
    value: 'Categoria ACC',
    description: 'Autorização para condução de ciclomotor (até 50 cm³ e velocidade máxima de 50 km/h).',
  },
  { 
    id: 'M', 
    label: 'Categoria M', 
    value: 'Categoria M',
    description: ''
  },
];

const PerfilSocial = ({ driver, open, onClose }: Props) => {
  
  const { userProfile, data, refetch } = useSocialProfileUser();
  const { result: noTruckerResult, refetch : refetchNoTrucker } = useSocialProfileNoTrucker(); 
  const cnhInfo = CNH_CATEGORY_DATA.find(it => it.value == userProfile?.metadata?.cnhCategory);
  
  useEffect(() => {
    refetch(driver?.userId?.replaceAll('prod-rio-users:', ''));
    refetchNoTrucker(driver);
  }, [driver]);
  
  const AvatarSection = useCallback(
    (title: string, image: string | ReactNode) => (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '42px',
      }}>
        {
          React.isValidElement(image)
            ? image
            : (<span 
              className={image + ' width-30 height-30 rioglyph text-color-gray'}
              style={{
                fontSize: '30px'
              }}
            />)
        }
        <span style={{
          fontSize: "16px",
          lineHeight: "24px",
          color: "#000000",
          marginLeft: "16px",
          fontWeight: '600',
        }}>
          {title}
        </span>
      </div>
    ),
    []
  );

  const CardItem = useCallback(
    ({label, secondary, icon = 'rioglyph-road' }: CardProps) => (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', marginBottom: '12px' }}>
        {(typeof icon == 'string') ? (<span 
          className={icon + ' rioglyph text-color-white text-size-16'}
          style={{ borderRadius: '20px', padding: '3px', backgroundColor: '#333333' }}
        />) : icon}
        <div style={{ marginLeft: '16px'}}>
          <div style={{ fontWeight: '600' }}>{label}</div>
          {secondary ? <div style={{ fontSize: '12px', color: '#888888', marginTop: '8px' }}>{secondary}</div> : null }
        </div>
      </div>
    ), []
  );

  const CommentItem = useCallback(
    ({label, secondary, image }: CommentProps) => (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', marginBottom: '8px' }}>
          <span 
            className="width-20 height-20"
            style={{
              fontSize: "24px",
              lineHeight: "12px",
              padding: '3px',
              color: '#333333'
            }}
          >“</span>
          <div style={{ marginLeft: '16px', fontWeight: '600' }}>{label}</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '16px' }}>
          {image && image.length ? (
            <span 
              className="width-20 height-20 rounded-circle aspect-ratio-1 bg-size-100pct bg-repeat-no bg-light"
              style={{
                backgroundImage: `url(${image})`,
                padding: '3px',
                backgroundColor: '#333333'
              }}
            />
          ) : (
            <span 
              className="width-20 height-20 rioglyph rioglyph-user text-color-white text-size-16"
              style={{ 
                borderRadius: '20px', 
                padding: '3px', 
                backgroundColor: '#333333' 
              }}
            />
          )}
          {secondary ? <div style={{ fontSize: '12px', color: '#888888', marginLeft: '16px' }}>{secondary}</div> : null }
        </div>
      </div>
    ), []
  );

  const NoTruckerInfo = useCallback(
    () => (
      <div style={{ margin: '0 16px' }}>
        {noTruckerResult.map(it => (
          <div
            key={it?.id} 
            style={{
              width: '100%',
              display: 'flex', 
              flexDirection: 'row', 
              alignItems: 'center', 
              justifyContent: 'space-between', 
              borderBottom: '1px solid #dedede',
              padding: '16px 0'
            }}>
            <span style={{
              color: '#A7AFBB',
              fontSize: '16px',
            }}>
              {it?.description}
            </span>
            <span style={{
              color: '#000000',
              fontWeight: '500',
              fontSize: '18px',
            }}>
              {it?.value}
            </span>
          </div>
        ))}
      </div>
    ), [noTruckerResult]
  );

  const SectionContent = useCallback(
    ({data, iconBody, customAction}: SectionProps) => (
      <>
        {data.length <= 0 ? (
          <div style={{ padding: '16px', color: '#bbbbbb', textAlign: 'left'}}>Sem resultados</div>
        ) : (
          <div style={{ padding: '16px' }}>
            {data.map((item, id) => (
              <div key={id.toString()}>
                {item.image 
                  ? <CommentItem image={item.image} label={item.label} secondary={item.secondary} />
                  : <CardItem icon={iconBody} label={item.label} secondary={item.secondary} />
                }
              </div>
            ))}
          </div>
        )}
        {customAction ? customAction : null}
      </>
    ), []);

  return (
    <Dialog
      show={open}
      title={"Perfil do Motorista"}
      onClose={onClose}
      footer={
        <div className="display-flex gap-10 justify-content-end flex-grow">
          <Button
            bsSize={Button.MD}
            style={{ width: 84 }}
            onClick={onClose}>
            Voltar
          </Button>
        </div>
      }
      body={
        <div>
          <div style={{
            display: "flex", 
            flexDirection: "row", 
            justifyContent: "flex-start", 
            alignItems: "center",
            marginTop: "20px",
            marginLeft: "12px",
          }}>
            <div
              className="width-30 height-30 rounded-circle aspect-ratio-1 bg-size-100pct bg-repeat-no bg-light"
              style={{
                backgroundImage: `url(${
                  userProfile?.metadata?.imageProfile?.fileUrl ?? driver?.photo
                    ? (userProfile?.metadata?.imageProfile?.fileUrl ?? driver?.photo)
                    : "https://cdn.rio.cloud/svg/common/ico_rio_colored.svg"
                })`,
              }}
            />
            <span style={{
              fontSize: "22px",
              fontWeight: "600",
              color: "#000000",
              marginLeft: "16px",
            }}>
              {userProfile?.metadata ? (
                userProfile?.metadata?.displayName  || [userProfile?.metadata?.givenName, userProfile?.metadata?.familyName].filter(s => s).join(' ')
              ) : driver?.name}
            </span>
          </div>
          
          <div style={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '21px',
            marginTop: '24px',
            marginBottom: '24px',
            paddingLeft: '12px',
            paddingRight: '12px',
          }}>
            {userProfile?.metadata?.about }
          </div>

          { userProfile ? (
          <ExpanderList
            bordered={true}
            unmountOnExit={false}
            rounded={false}
            items={[
              {
                id: 1,
                header: AvatarSection(
                  'CNH',
                  <div style={{
                    height: '30px',
                    width: '30px',
                    borderRadius: '40px',
                    backgroundColor: '#A7AFBB',
                    fontSize: '40px',
                    lineHeight: '30px',
                    fontWeight: '600',
                    paddingLeft: '3px',
                    color: "#FFFFFF"
                  }}>
                    {cnhInfo?.id}
                  </div>,
                ),
                body: (<div style={{ padding: '0 16px' }}>
                  <div style={{
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '21px',
                    marginBottom: '12px',
                  }}>
                    {userProfile?.metadata?.cnhCategory}
                  </div>
                  <div style={{
                    fontSize: '14px',
                    lineHeight: '21px',
                  }}>
                    {cnhInfo?.description}
                  </div>
                </div>),
              },
              {
                id: 2,
                header: AvatarSection(
                  'Histórico', 
                  'rioglyph-drive-history',
                ),
                body: (<SectionContent
                  iconBody="rioglyph rioglyph-user"
                  data={data?.experiences.map(c => ({
                    id: c.id,
                    label: c?.currentPosition
                      ? `${c.position} ${c?.company}`
                      : `Trabalhou como ${c?.position} na empresa ${c?.company}.`,
                    secondary: c?.currentPosition
                      ? `Desde ${c.startDate?.slice(0,4)} até hoje.`
                      : `Período ${c.startDate?.slice(0, 4)} a ${c.endDate?.slice(0,4)}.`,
                  })) ?? []}
                />), 
              },
              {
                id: 3,
                header: AvatarSection(
                  'Cursos e Qualificações', 
                  'rioglyph-tachograph',
                ),
                body: (<SectionContent
                  iconBody="rioglyph rioglyph-user"
                  data={data?.courses.map(c => ({
                    id: c.id,
                    label: c?.courseName,
                    secondary: c?.institution.toUpperCase(),
                  })) ?? []}
                />),
              },
              {
                id: 4,
                header: AvatarSection(
                  'Competências', 
                  'rioglyph-stars',
                ),
                body: (<SectionContent
                  iconBody="rioglyph rioglyph-user"
                  data={data?.skills.map(c => ({
                    id: c.id,
                    label: c?.skillName,
                  })) ?? []}
                />),
              },
              {
                id: 5,
                header: AvatarSection(
                  'Reconhecimento e avaliações', 
                  'rioglyph-parcel',
                ),
                body: (<SectionContent
                  iconBody="rioglyph rioglyph-user"
                  data={data?.evaluations.slice(0,3).map(c => ({
                    id: c.id,
                    label: c?.text,
                    secondary: c?.evaluatorName,
                    image: c?.evaluatorImage ?? 'none', // TODO: OBTAIN USER IMAGE
                  })) ?? []}
                />),
              },
              {
                id: 6,
                header: AvatarSection(
                  'NoTrucker Assist', 
                  'rioglyph-speed',
                ),
                body: <NoTruckerInfo/>, 
              },
              
            ]}
          />
          ) : (        
            <div style={{
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '21px',
              marginTop: '24px',
              textAlign: 'center',
              marginBottom: '24px',
              paddingLeft: '12px',
              paddingRight: '12px',
            }}>
              Usuário não possui perfil social cadastrado.
            </div>
          )}
        </div>
        
      }
      className="exampleDialog"
      showCloseButton
      bsSize="md"
    />
  );
};

export default PerfilSocial;
