import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AvoidAreas, IRoute, IRoutesState, IVehicleInfo, Route, Waypoint} from "@types";
import {IStop} from "../../components/RegisterRoute";
import {CreateRouteCostPayload} from "../../types/costSimulator";
import {CreateAreaPayload} from "../../types/areas";
import {string} from "zod";

const initialState: IRoutesState = {
    origin: null,
    destination: null,
    routes: [],
    stops: [],
    routeName: "",
    routeCost: {} as CreateRouteCostPayload,
    accountId: "",
    routeRangeToleranceLimit: 0,
    avoidToll: false,
    areas: [],
    vehicle: {} as IVehicleInfo,
    areasToAvoid: {} as AvoidAreas,
    durationWithStops: "0h00min",
    waypoint: {} as Waypoint,
};

const defaultVehicle = {
    grossWeight: '',
    height: '',
    lenght: '',
    width: '',
    type: '',
    maxAxleLoad: '',
    numberOfAxles: '',
    trailerAxles: '',
    shippedHazardousGoods: '',
    pollutantClass: '',
};

export const RoutesSlice = createSlice({
    name: "Routes",
    initialState,
    reducers: {
        defineOrigin: (state, action: PayloadAction<IRoute | null>) => {
            state.origin = action.payload;
        },
        defineDestination: (state, action: PayloadAction<IRoute | null>) => {
            state.destination = action.payload;
        },
        defineRoutes: (state, action: PayloadAction<Route[]>) => {
            state.routes = action.payload;
        },
        defineStops: (state, action: PayloadAction<IStop[]>) => {
            state.stops = action.payload;
        },
        defineRouteName: (state, action: PayloadAction<string>) => {
            state.routeName = action.payload;
        },
        defineAccountId: (state, action: PayloadAction<string>) => {
            state.accountId = action.payload;
        },
        defineRouteDeviation: (state, action: PayloadAction<number>) => {
            state.routeRangeToleranceLimit = action.payload;
        },
        defineRouteCost: (state, action: PayloadAction<CreateRouteCostPayload>) => {
            state.routeCost = action.payload;
        },
        defineAvoidToll: (state, action: PayloadAction<boolean>) => {
            state.avoidToll = action.payload;
        },
        defineAreas: (state, action: PayloadAction<CreateAreaPayload[]>) => {
            state.areas = action.payload;
        },
        defineVehicleInfo: (state, action: PayloadAction<IVehicleInfo>) => {
            state.vehicle = action.payload;
        },
        defineVehicleInfoDefault: (state) => {
            state.vehicle = defaultVehicle;
        },
        defineAvoidAreas: (state, action: PayloadAction<AvoidAreas>) => {
            state.areasToAvoid = action.payload;
        },
        defineDurationWithStops:(state,action:PayloadAction<string>)=>{
            state.durationWithStops = action.payload;
        },
        defineWaypoint: (state, action: PayloadAction<Waypoint>) => {
            state.waypoint = action.payload;
        }
    },
});

export const {
    defineOrigin,
    defineDestination,
    defineRoutes,
    defineStops,
    defineRouteName,
    defineAccountId,
    defineRouteDeviation,
    defineRouteCost,
    defineAvoidToll,
    defineAreas,
    defineVehicleInfo,
    defineVehicleInfoDefault,
    defineAvoidAreas,
    defineDurationWithStops,
    defineWaypoint,
} = RoutesSlice.actions;

export default RoutesSlice.reducer;

