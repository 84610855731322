import { useContext, useEffect, useState } from "react";
import { ApplicationHeader, ApplicationLayout, NotFoundState, NotificationsContainer, Sidebar, Spinner } from "@rio-cloud/rio-uikit";
import Notification from "@rio-cloud/rio-uikit/Notification";
import { SidebarDetail } from "@components";
import { useAppSelector, store } from "@store";
import RioMap, {
    Coordinates,
    getBoundingBox,
    getRandomValueToForceRerender,
    RoutePoint,
    SandboxMarker
} from "src/components/Map";
import { fetchAddressWithLatLong, fetchTripPublic } from "src/services/routes";
import { AddressSearch, Route } from "src/types/routes";
import { IOnGoingTrip } from "@types";
import { decode } from "src/utils/flexiblePolyline";
import { mountPolyline } from "@utils";
import { Provider } from "react-redux";

const positionDefault = {
    lat: -23.5475,
    lng: -46.63611,
}

const RouteView = () => {
    const [count, setCount] = useState(1);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState<IOnGoingTrip | null>();
    const [position, setPosition] = useState<Coordinates | undefined>(positionDefault);
    const [segments, setSegments] = useState<RoutePoint[]>([]);
    const [markers, setMarkers] = useState<SandboxMarker[]>([]);
    const [markerNow, setMarkerNow] = useState<SandboxMarker>()
    const [currencySegments, setCurrencySegments] = useState<RoutePoint[]>([]);
    const [boundingBox, setBoundingBox] = useState<
        { top: number; left: number; right: number; bottom: number } | undefined
    >();
    const [currencyAddress, setCurrencyAddress] = useState<AddressSearch>();
    const [error, setError] = useState(false);
    const uuid = new URLSearchParams(document.location.search).get('link');

    const fetchRouteDetails = async () => {
        try {
            const response = await fetchTripPublic(uuid as string);
            setSelected({
                actualProgress: response.progressTrip.toString(),
                assetId: response.vehicle.assetId,
                assetName: response.vehicle.assetName,
                dateStarted: response.scheduleTripDate,
                destinyName: response.destinyLocation.address,
                distance: response.remainingDistance.toString(),
                driverId: '',
                driverName: '',
                eta: response.dinamicETA,
                polyline: response.currentPolyline,
                routeId: response.polylinePlanned.routes[0].id,
                routeStatusNow: response.routeStatus,
                tripId: '',
                tripStatusNow: response.tripStatus,
                latLongActually: response.latLongActually,
                arrival: response.expectateArriveAt,
                departure: response.startDateTime,
            });
            setError(false);
            const currency = mountPolyline(response.currentPolyline)
            handleRoutes(response.polylinePlanned.routes as any, response.stops, currency.markerDestiny);
            setCurrencySegments(currency.segments);
            setMarkerNow(currency.markerDestiny);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    };

    const updatedRoute = async () => {
        try {
            const response = await fetchTripPublic(uuid as string);
            setSelected({
                actualProgress: response.progressTrip.toString(),
                assetId: response.vehicle.assetId,
                assetName: response.vehicle.assetName,
                dateStarted: response.scheduleTripDate,
                destinyName: response.destinyLocation.address,
                distance: response.remainingDistance.toString(),
                driverId: '',
                driverName: '',
                eta: response.dinamicETA,
                polyline: response.currentPolyline,
                routeId: response.polylinePlanned.routes[0].id,
                routeStatusNow: response.routeStatus,
                tripId: '',
                tripStatusNow: response.tripStatus,
                latLongActually: response.latLongActually,
                arrival: response.expectateArriveAt,
                departure: response.startDateTime,
            });
            const currency = mountPolyline(response.currentPolyline)
            setCurrencySegments(currency.segments);
            setMarkerNow(currency.markerDestiny);
        } catch (error) {
            console.log(error)
        }
    };

    const handleRoutes = (routes: Route[], via: any[], markerNow?: SandboxMarker) => {
        if (routes.length) {
            let ArrayOfShape: any[] = [];
            const route = routes?.[0];
            const data = route?.sections || [];

            const getPolylineArray = data.map((item) => {
                return {
                    shape: decode(item.polyline),
                };
            });

            const markers = data.reduce((acum: SandboxMarker[], curr, index, arr) => {
                if (index === 0) {
                    const markerOrigin: SandboxMarker = {
                        id: getRandomValueToForceRerender().toString(),
                        markerProps: {
                            iconNames: ["start"],
                            name: "",
                            markerColor: "bg-map-marker-route",
                            active: false,
                            fixed: false,
                        },
                        position: {
                            lat: curr.departure.place.location.lat,
                            lng: curr.departure.place.location.lng,
                        },
                    };
                    acum.push(markerOrigin);
                }
                if (index === arr.length - 1) {
                    const markerDestination: SandboxMarker = {
                        id: getRandomValueToForceRerender().toString(),
                        markerProps: {
                            iconNames: ["finish"],
                            name: "",
                            markerColor: "bg-map-marker-route",
                            active: false,
                            fixed: false,
                        },
                        position: {
                            lat: curr.arrival.place.location.lat,
                            lng: curr.arrival.place.location.lng,
                        },
                    };
                    acum.push(markerDestination);
                } else {
                    const markerStopover: SandboxMarker = {
                        id: getRandomValueToForceRerender().toString(),
                        markerProps: {
                            iconNames: ["rioglyph rioglyph-pushpin"],
                            name: via?.[index]?.type,
                            markerColor: "bg-map-marker-route",
                            active: false,
                            fixed: false,
                        },
                        position: {
                            lat: curr.arrival.place.location.lat,
                            lng: curr.arrival.place.location.lng,
                        },
                    };
                    acum.push(markerStopover);
                }

                return acum;
            }, []);

            getPolylineArray.forEach((item) => {
                ArrayOfShape.push(item.shape.polyline);
            });

            const concatArrays = ArrayOfShape.flat();

            const getPoints = concatArrays.map((value) => {
                return {
                    lat: value[0].toString(),
                    lng: value[1].toString(),
                };
            });

            setSegments(getPoints as RoutePoint[]);


            setMarkers(markers);


            if (getPoints?.length > 0) {
                setPosition(undefined);
                const values = getBoundingBox(getPoints);
                const paddedValues = {
                    top: values.top + 0.4,
                    bottom: values.bottom - 0.1,
                    left: values.left - 0.4,
                    right: values.right + 0.4,
                };

                if (count === 1) {
                    setBoundingBox(paddedValues);
                }
            } else if (markers?.length > 0) {
                setPosition(markers[0].position);
            }

        } else {
            setMarkers([]);
            setSegments([]);
        }

        setCount(prev => prev + 1);

    };

    const handleSearchAddress = async () => {
        const response = await fetchAddressWithLatLong(selected?.latLongActually?.lat, selected?.latLongActually?.lng)
        setCurrencyAddress(response[0])
    }

    const handleCopy = () => {
        const url = window.location.href; // Obtém a URL atual
        navigator.clipboard.writeText(url)
            .then(() => Notification.info("URL copiada para a área de transferência!"))
            .catch(err => console.error("Erro ao copiar: ", err));
    }

    useEffect(() => {
        fetchRouteDetails();

        const interval = setInterval(() => {
            updatedRoute();
        }, 60000);

        return () => {
            clearInterval(interval);
        };
    }, [uuid])

    useEffect(() => {
        if (selected?.latLongActually) {
            handleSearchAddress()
        }
    }, [selected?.latLongActually]);

    return (
        <ApplicationLayout>
            <ApplicationLayout.Header className="">
                <div className="width-100pct height-100pct bg-white bg-white display-flex align-items-center" style={{ borderBottom: '1px solid #ddd' }}>
                    <svg className="fill-primary" width="50" height="50" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 50V0h50v50H0zm45.3-23.95c0-4.55-3.7-8.25-8.25-8.25s-8.25 3.7-8.25 8.25 3.7 8.25 8.25 8.25 8.25-3.7 8.25-8.25zm-23.6 7.8h5.05V18.2H21.7v15.65zm-8-15.65H5.25v15.65h4.9V27.6l4.25 6.25h5.35l-4.1-6.25c.45-.15.85-.4 1.15-.7 1.1-.9 1.8-2.3 1.8-3.8 0-2.7-2.2-4.9-4.9-4.9z" fill-rule="evenodd">
                        </path>
                    </svg>
                    <span className="text-size-h5 text-medium margin-left-20">RIO ROUTING</span>
                </div>
            </ApplicationLayout.Header>
            {!loading ? (
                <>
                    <NotificationsContainer />
                    {!error ? (
                        <div className="width-100pct height-100vh bg-gray">
                            <Provider store={store}>
                                <ApplicationLayout.Sidebar className="right">
                                    <Sidebar
                                        title="Veículo"
                                        position={Sidebar.RIGHT}
                                        disableClose
                                        width={500}
                                    >
                                        <div className="padding-left-20 padding-right-20 padding-bottom-20">
                                            <SidebarDetail
                                                handleCopy={handleCopy}
                                                currencyAddress={currencyAddress?.title}
                                                detail={selected}
                                                type='PUBLIC'
                                            />
                                        </div>
                                    </Sidebar>
                                </ApplicationLayout.Sidebar>


                                <div style={{ width: '100%', height: '100%' }}>
                                    <ApplicationLayout.Body innerClassName="padding-0">
                                        <RioMap
                                            position={position}
                                            boundingBox={boundingBox}
                                            apiKey={process.env.REACT_APP_RIO_MAP_KEY as string}
                                            segments={
                                                segments.length ? [{ points: segments, alternative: false }] : []
                                            }
                                            currencySegments={
                                                currencySegments.length ? [{ points: currencySegments, alternative: false }] : []
                                            }
                                            markers={markerNow?.position?.lat ? [...markers, markerNow] : markers}
                                        />
                                    </ApplicationLayout.Body>
                                </div>
                            </Provider>
                        </div>
                    ) : <div className="width-100pct height-100vh display-flex align-items-center justify-content-center">
                        <NotFoundState
                            headline="Página indisponível"
                            message="Volte e tente novamente."
                            fullWidth
                            outerClassName="border-none"
                        />
                    </div>}
                </>
            ) : <div className="width-100pct height-100vh bg-white display-flex align-items-center justify-content-center">
                <Spinner isDoubleSized text='Carregando...' />
            </div>

            }
        </ApplicationLayout>
    );
};

export default RouteView;
