import { useEffect, useState } from "react";

interface Props {
  pages: number;
  page: number;
  handlePage: (id: number) => void;
  pagesPerBlock?: number;
}

const Paginator = ({ pages, page, handlePage }: Props) => {
  const pagesPerBlock = 15;
  const [all, setAll] = useState<number[]>();
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(pagesPerBlock);

  const onChangePage = (pageNum: number) => {
    if (pageNum === startPage || pageNum === endPage) {
      const calc = { start: pageNum - 1, end: endPage + pagesPerBlock };
      setStartPage(calc.start)
      setEndPage(calc.end)
    }
    handlePage(pageNum)
  }

  const handleBack = () => {
    const calc = {
      start: startPage - pagesPerBlock,
      end: endPage - pagesPerBlock,
    };

    if (calc.start > 0) {
      setStartPage(calc.start)
      setEndPage(calc.end);
    } else {
      setStartPage(0)
      setEndPage(pagesPerBlock);
    }
  }

  const handleMore = () => {
    const calc = { start: startPage + pagesPerBlock, end: endPage + pagesPerBlock }
    if (all?.length && calc.start < all?.length) {
      setStartPage(calc.start);
      setEndPage(calc.end);
    }
  }

  useEffect(() => {
    const pagesInBlock = Array.from({ length: pages }, (_, i) => 1 + i);
    setAll(pagesInBlock);
  }, [pages])

  return (
    <div
      style={{
        marginTop: 40,
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <ul
        className="pagination"
        style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
      >
        {/* Botão de navegação para o bloco anterior */}
        <li
          onClick={handleBack}
        >
          <span className="rioglyph rioglyph-chevron-left"></span>
        </li>

        {/* Botões de páginas dentro do bloco atual */}
        {all?.slice(startPage, endPage).map((pageNum) => (
          <li
            key={pageNum}
            onClick={() => {
              onChangePage(pageNum)
            }}
            style={{ background: pageNum === page ? "#A7AFBB" : "" }}
          >
            <span style={{ color: pageNum === page ? "white" : "" }}>
              {pageNum}
            </span>
          </li>
        ))}

        {/* Botão de navegação para o próximo bloco */}
        <li
          onClick={handleMore}
        >
          <span className="rioglyph rioglyph-chevron-right"></span>
        </li>
      </ul>
    </div>
  );
};

export default Paginator;
