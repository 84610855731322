import { store } from "@store";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use((config) => {
  const access_token = store.getState().tokenHandling.accessToken;
  config.headers.Authorization = `Bearer ${access_token}`;
  return config;
});

export default api;
