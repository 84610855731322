import { formatarMeters, formatKg, formatMetersMaskInNumber } from "@utils";
import CustomInputSelect from "../CustomInputSelect/CustomInputSelect";

const Dimensions = ({
    height,
    width,
    length,
    vehicleType,
    selectedVehicleOption,
    selectedTypeOption,
    type,
    trailerAxles,
    numberOfAxles,
    grossWeight,
    shippedHazardousGoods,
    handleVehicleTrailerAxles,
    handleVehicleOptionType,
    handleVehicleOptionChange,
    handleChangeLenght,
    handleChangeWidth,
    handleChangeHeight,
    handleVehicleNumberOfAxles,
    handleGrossWeight,
    maxAxleLoad,
    handleMaxAxleLoad,
    handleShippedHazardousGoodsType,
    handleVehiclePollutantClass,
    vehiclePollutantClass,
    error,
}: {
    height: string;
    width: string;
    length: string;
    selectedTypeOption: string;
    vehicleType: {
        value: number;
        label: string;
    }[]
    selectedVehicleOption: string | number;
    trailerAxles: string;
    numberOfAxles: string;
    grossWeight: string;
    maxAxleLoad: string;
    shippedHazardousGoods: string | number;
    handleChangeLenght: (v: string) => void;
    handleChangeWidth: (v: string) => void;
    handleChangeHeight: (v: string) => void;
    handleVehicleOptionChange: (value: string | number) => void;
    handleVehicleOptionType: (value: string | number) => void;
    handleVehicleTrailerAxles: (value: string) => void;
    handleVehicleNumberOfAxles: (value: string) => void;
    handleShippedHazardousGoodsType: (value: string | number) => void;
    handleGrossWeight: (value: string) => void;
    handleMaxAxleLoad: (value: string) => void;
    type: string;
    handleVehiclePollutantClass: (v:  string | number) => void;
    vehiclePollutantClass: string | number;
    error?: {type: string}
}) => {

    return (
        <div className="padding-bottom-20">
            <div>
                {/* <div className="width-50pct display-flex gap-15 padding-top-10 padding-bottom-10 padding-left-10 padding-right-10">
                    <CustomInputSelect
                        options={vehicleType}
                        value={selectedVehicleOption}
                        onChange={handleVehicleOptionChange}
                        disabled={disabledType}
                    />
                </div> */}
                <div className="display-flex gap-15 padding-top-10 padding-bottom-10 padding-left-10 padding-right-10">
                    <div className="width-50pct">
                        <label htmlFor="inputWithMask1">Tipo de veículo</label>
                        <CustomInputSelect
                            className="display-block margin-bottom-5"
                            options={[
                                {
                                    label: "Caminhão",
                                    value: "TRUCK",
                                },
                                {
                                    label: "Ônibus",
                                    value: "BUS",
                                },
                                {
                                    label: "Carro",
                                    value: "CAR",
                                },
                            ]}
                            value={selectedTypeOption}
                            onChange={handleVehicleOptionType}
                        />
                    </div>
                    <div className="width-50pct">
                        {type === "TRUCK" ? (
                            <>
                                <label htmlFor="inputWithMask1">Tipo de carga</label>
                                <CustomInputSelect
                                    className="display-block margin-bottom-5"
                                    options={[
                                        {
                                            label: "Explosivo",
                                            value: "explosive",
                                        },
                                        {
                                            label: "Gás",
                                            value: "gas",
                                        },
                                        {
                                            label: "Inflamável",
                                            value: "flammable",
                                        },
                                        {
                                            label: "Combustível",
                                            value: "combustible",
                                        },
                                        {
                                            label: "Orgânico",
                                            value: "organic",
                                        },
                                        {
                                            label: "Venenoso",
                                            value: "poison",
                                        },
                                        {
                                            label: "Corrosívo",
                                            value: "corrosive",
                                        },
                                        {
                                            label: "Tóxico inalável",
                                            value: "poisonousInhalation",
                                        },
                                        {
                                            label: "Produto químico",
                                            value: "harmfulToWater",
                                        },
                                        {
                                            label: "Outros",
                                            value: "other",
                                        },
                                    ]}
                                    value={shippedHazardousGoods}
                                    onChange={handleShippedHazardousGoodsType}
                                />
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="display-flex gap-15 padding-top-10 padding-bottom-10 padding-left-10 padding-right-10">
                <div className="width-50pct">
                    <label htmlFor="inputWithMask1">Eixos do veículo</label>
                    <div className="input-group">
                        <input
                            type="text"
                            className="simulador-input"
                            value={numberOfAxles}
                            onChange={(e) =>
                                e.target.value.length < 2 ? handleVehicleNumberOfAxles(e.target.value) : null
                            }
                        />
                        <span className="input-group-addon rioglyph rioglyph-axle text-size-20">t</span>
                    </div>
                </div>
                <div className="width-50pct">
                    <label htmlFor="inputWithMask1">Eixos do reboque</label>
                    <div className="input-group">
                        <input
                            type="text"
                            className="simulador-input"
                            value={trailerAxles}
                            onChange={(e) =>
                                e.target.value.length < 3 ? handleVehicleTrailerAxles(e.target.value) : 0
                            }
                        />
                        <span className="input-group-addon rioglyph rioglyph-axle text-size-20">t</span>
                    </div>
                </div>
            </div>
            <div className="display-flex gap-15 padding-top-10 padding-bottom-10 padding-left-10 padding-right-10">
                <div className="width-33pct">
                    <label htmlFor="inputWithMask1">Comp. total</label>
                    <div className="input-group">
                        <input
                            type="text"
                            className="simulador-input"
                            value={length}
                            onChange={(e) => handleChangeLenght(formatarMeters(e.target.value))}
                        />
                        <span className="input-group-addon">m</span>
                    </div>
                </div>
                <div className="width-33pct">
                    <label htmlFor="inputWithMask1">Altura</label>
                    <div className="input-group">
                        <input
                            type="text"
                            className="simulador-input"
                            value={height}
                            onChange={(e) => handleChangeHeight(formatarMeters(e.target.value))}
                        />
                        <span className="input-group-addon">m</span>
                    </div>
                </div>
                <div className="width-33pct">
                    <label htmlFor="inputWithMask1">Largura</label>
                    <div className="input-group">
                        <input
                            type="text"
                            className="simulador-input"
                            value={width}
                            onChange={(e) => handleChangeWidth(formatarMeters(e.target.value))}
                        />
                        <span className="input-group-addon">m</span>
                    </div>
                </div>
            </div>
            <div className="display-flex gap-15 padding-top-10 padding-bottom-10 padding-left-10 padding-right-10">
                <div className="width-50pct">
                    <label htmlFor="inputWithMask1">PBTC</label>
                    <div className="input-group">
                        <input
                            type="text"
                            className="simulador-input"
                            value={grossWeight}
                            onChange={(e) => handleGrossWeight(formatKg(e.target.value))}
                        />
                        <span className="input-group-addon rioglyph rioglyph-weight text-size-20"></span>
                    </div>
                </div>
                <div className="width-50pct">
                    <label htmlFor="inputWithMask1">Peso por eixo</label>
                    <div className="input-group">
                        <input
                            disabled
                            type="text"
                            className="simulador-input"
                            value={maxAxleLoad?.slice(0, 4)?.replace(/\./g, ",")}
                            onChange={(e) => handleMaxAxleLoad(formatKg(e.target.value))}
                        />
                        <span className="input-group-addon rioglyph rioglyph-weight text-size-20"></span>
                    </div>
                </div>
            </div>


            <div className="display-flex gap-15 padding-top-10 padding-bottom-10 padding-left-10 padding-right-10">
                <div className="width-50pct">
                    <label htmlFor="inputWithMask1">Classe de emissão de poluentes</label>
                    <CustomInputSelect
                        className="display-block margin-bottom-5"
                        options={[
                            {
                                label: "Nenhum",
                                value: 'NONE',
                            },
                            {
                                label: "EURO I",
                                value: 'EURO I',
                            },
                            {
                                label: "EURO II",
                                value: 'EURO II',
                            },
                            {
                                label: "EURO III",
                                value: 'EURO III',
                            },
                            {
                                label: "EURO IV",
                                value: 'EURO IV',
                            },
                            {
                                label: "EURO V",
                                value: 'EURO V',
                            },
                            {
                                label: "EURO VI",
                                value: 'EURO VI',
                            },
                            {
                                label: "EURO EEV",
                                value: 'EURO EEV',
                            },
                            {
                                label: 'Veículo elétrico',
                                value: 'Electric Vehicles'
                            }
                        ]}
                        value={vehiclePollutantClass}
                        onChange={handleVehiclePollutantClass}
                    />
                </div>
            </div>
        </div>
    );
};


export default Dimensions;