import * as St from "./styles";
import Map from "@rio-cloud/rio-uikit/Map";
import Route from "@rio-cloud/rio-uikit/Route";
import SingleMapMarker, {SingleMapMarkerProps} from "@rio-cloud/rio-uikit/SingleMapMarker";
import MapSettings from "@rio-cloud/rio-uikit/MapSettings";
import MapTypeSettings from "@rio-cloud/rio-uikit/MapTypeSettings";
import {MapApi, Position} from "@rio-cloud/rio-uikit/mapTypes";
import Marker from "@rio-cloud/rio-uikit/Marker";
import React, {useEffect, useRef, useState} from "react";
import ContextMenu from '@rio-cloud/rio-uikit/ContextMenu';
import ContextMenuItem from '@rio-cloud/rio-uikit/ContextMenuItem';
import EventUtils, {createEnhancedListener, EventListenerMap} from '@rio-cloud/rio-uikit/EventUtils';
import Circle from '@rio-cloud/rio-uikit/Circle';
import Polygon, {colorMapMarker} from '@rio-cloud/rio-uikit/Polygon';
import {PolygonPoints} from "@rio-cloud/rio-uikit/components/map/components/features/basics/Polygon";
import ModalMapInterestArea from "../ModalMapInterestArea";
import {useAppSelector} from "@store";
import {v4 as uuidv4} from 'uuid';
import {CreateAreaPayload} from "../../types/areas";
import Notification from "@rio-cloud/rio-uikit/Notification";
import {useDispatch} from "react-redux";
import {defineAvoidAreas, defineWaypoint} from "../../store/routes/RoutesSlice";

const STROKE_COLOR = colorMapMarker['color-map-marker-geofence'].stroke;
const FILL_COLOR = colorMapMarker['color-map-marker-geofence'].fill;
const INTEREST_STROKE_COLOR = colorMapMarker['color-map-marker-success'].stroke;
const INTEREST_FILL_COLOR = colorMapMarker['color-map-marker-success'].fill;
const AVOID_STROKE_COLOR = colorMapMarker['color-map-marker-danger'].stroke;
const AVOID_FILL_COLOR = colorMapMarker['color-map-marker-danger'].fill;

export type Coordinates = {
    lat: number;
    lng: number;
};

interface Props {
    apiKey: string;
    position?: Coordinates;
    boundingBox?: { top: number; left: number; right: number; bottom: number };
    segments: RouteSegment[];
    currencySegments?: RouteSegment[];
    markers: SandboxMarker[];
}

export const getRandomValueToForceRerender = () => Math.random();

export type GeofanceData = {
    geofenceId?: string;
    type?: "circle" | "polygon";
    coordinates: Coordinates[];
    radius?: number;
    geofenceCategory: "INTEREST_AREA" | "AVOID_AREA";
    formatted?: string;
}

export type MarkerData = {
    markerType?: "custommarker";
    enableContextMenu?: boolean;
    contextMenuMarkerName?: string;
    lat?: number;
    lng?: number;
};

export type RoutePoint = Position & {};

export type RouteSegment = {
    points: RoutePoint[];
    data?: object;
    showArrows?: boolean;
    reduced?: boolean;
    alternative?: boolean;
};

export type SandboxMarker = {
    id: string;
    data?: MarkerData;
    type?: "addressType" | "customerPoiType" | "workshopPoiType";
    markerProps: SingleMapMarkerProps;
    position: Position;
};

type MarkerPosition = {
    position: Position;
    type: "interest" | "avoid";
    title: string;
}

type PointPosition = {
    points: PolygonPoints;
    type: "interest" | "avoid";
    eventListenerMap: EventListenerMap;
};

type CirclePosition = {
    position: Position;
    type: "interest" | "avoid";
    radius: number | undefined;
    eventListenerMap: EventListenerMap;
}

export const getBoundingBox = (positions: Position[]) => {
    const lats = positions.map((position) => position.lat);
    const lngs = positions.map((position) => position.lng);
    const bbox = {
        top: Math.max(...lats),
        left: Math.min(...lngs),
        right: Math.max(...lngs),
        bottom: Math.min(...lats),
    };
    return bbox;
};

const Markers = ({markers}: { markers: SandboxMarker[] }) => {
    const mapMarkers = markers.map((marker) => {
        const customData = {id: marker.id, data: marker.data};
        return (
            <Marker
                key={marker.id}
                customData={customData}
                position={marker.position}
                icon={<SingleMapMarker {...marker.markerProps} />}
            />
        );
    });
    return <>{mapMarkers}</>;
};

const Segments = ({segments}: { segments: RouteSegment[] }) => {
    const mapSegments = segments.map((segment) => {
        return (
            <Route
                key={getRandomValueToForceRerender()}
                positions={segment.points}
                isRouteAlternative={segment.alternative}
                hasArrows={segment.showArrows}
            />
        );
    });
    return <>{mapSegments}</>;
};

const CurrencySegments = ({segments}: { segments: RouteSegment[] }) => {
    const mapSegments = segments.map((segment) => {
        return (
            <Route
                key={getRandomValueToForceRerender()}
                positions={segment.points}
                isRouteAlternative={segment.alternative}
                hasArrows={true}
                arrowStyle={{
                    color:'red'
                }}
                style={{
                    color: '#000',
                }}
            />
        );
    });
    return <>{mapSegments}</>;
};

const getStyleByType = (type: "interest" | "avoid") => {
    if (type === "interest") {
        return {strokeColor: INTEREST_STROKE_COLOR, fillColor: INTEREST_FILL_COLOR};
    } else if (type === "avoid") {
        return {strokeColor: AVOID_STROKE_COLOR, fillColor: AVOID_FILL_COLOR};
    }
    return {strokeColor: STROKE_COLOR, fillColor: FILL_COLOR};
};

const getMarkerColorByType = (type: "interest" | "avoid") => {
    if (type === "interest") {
        return 'bg-map-marker-success';
    } else if (type === "avoid") {
        return 'bg-map-marker-danger';
    }
    return 'bg-map-marker-route';
};

const getIconByType = (type: "interest" | "avoid") => {
    if (type === "interest") {
        return 'rioglyph-heart';
    } else if (type === "avoid") {
        return 'rioglyph-error-sign';
    }
    return 'rioglyph-drivercard-in';
};

type ContextMenuData = {
    target?: string;
    event?: any;
};

type ModalData = {
    id: string;
    type: "interest" | "avoid";
    coordinates: Coordinates[];
    radius?: number | null;
    formatted?: string;
};

const RioMap = (
    {
        apiKey,
        position,
        boundingBox,
        segments,
        markers,
        currencySegments,
    }: Props) => {
    const {areas} = useAppSelector((state) => state.routes);
    const {areasToAvoid} = useAppSelector((state) => state.routes);
    const dispatch = useDispatch();
    const [contextMenuData, setContextMenuData] = useState<ContextMenuData>({});
    const mapApiRef = useRef<MapApi>();
    const [openModal, setOpenModal] = useState(false);
    const [coordLabel, setCoordLabel] = useState<string>("");
    const [showSpinner, setShowSpinner] = useState(true);
    const [modalData, setModalData] = useState<ModalData>({} as ModalData);
    const [modalDatas, setModalDatas] = useState<ModalData[]>([]);

    const drawingMode = useRef<"interest" | "avoid" | "">("");
    const closeDrawingMode = useRef<"interest" | "avoid" | "">("");
    const originalCoord = useRef<Coordinates | null>(null);
    const interestAreas = useRef<Coordinates[]>([]);

    const avoidAreas = useRef<Coordinates[]>([]);
    const preAreasToAvoid = useRef<string>("");
    const [markersPosition, setMarkersPosition] = useState<MarkerPosition[]>([]);

    const [polygonPositions, setPolygonPositions] = useState<PointPosition[]>([]);
    const [circlePositions, setCirclePositions] = useState<CirclePosition[]>([]);

    const getFormattedCoordinate = (value: number, direction: string): string => {
        const sign = direction === "S" || direction === "W" ? "-" : "+";
        return `${sign}${Math.abs(value).toFixed(4)}${direction}`;
    };

    const formatCoordinates = (coords: Coordinates): string => {
        const latDir = coords.lat > 0 ? "N" : "S";
        const lngDir = coords.lng > 0 ? "E" : "W";
        originalCoord.current = {lat: coords.lat, lng: coords.lng};
        return `${getFormattedCoordinate(coords.lat, latDir)} ${getFormattedCoordinate(coords.lng, lngDir)}`;
    };

    const handleOpenContextMenu = (contextMenuCoordinates: Position, waypointId?: string) => {
        setShowSpinner(true);
        setCoordLabel(formatCoordinates(contextMenuCoordinates));

        setContextMenuData({
          target: waypointId || "",
        });
        setTimeout(() => setShowSpinner(false), 500);
    };

    const mapEventListenerMap = {
        [EventUtils.CONTEXTMENU]: (event: any) => {
            if (mapApiRef.current && EventUtils.isRightClick(event)) {
                setContextMenuData({target: "map", event});
                const coords = mapApiRef.current.map.screenToGeo(event.viewportX, event.viewportY);
                if (coords) {
                    handleOpenContextMenu({lat: coords.lat, lng: coords.lng});
                }
            }
        },
    };

    const handleMenuClick = (option: string) => {
        setContextMenuData({});
        const currentCoord = originalCoord.current;
        if (!currentCoord) return;

        switch (option) {
            case "interest": {
                drawingMode.current = "interest";
                setMarkersPosition(prevState => [...prevState, {
                    position: {lat: currentCoord.lat, lng: currentCoord.lng},
                    type: "interest",
                    title: "Área de interesse",
                }]);
                interestAreas.current.push({lat: currentCoord.lat, lng: currentCoord.lng});
                return;
            }
            case "avoid": {
                drawingMode.current = "avoid";
                setMarkersPosition(prevState => [...prevState, {
                    position: {lat: currentCoord.lat, lng: currentCoord.lng},
                    type: "avoid",
                    title: "Área a ser evitada",
                }]);
                avoidAreas.current.push({lat: currentCoord.lat, lng: currentCoord.lng});
                return;
            }
            case "close-interest": {
                closeInterestDrawing();
                return;
            }
            case "close-avoid": {
                closeAvoidDrawing();
                return;
            }
            case "add-waypoint":{
              if (!currentCoord) return;

              handleAddWaypoint({
                lat: currentCoord.lat,
                lng: currentCoord.lng,
              });
              return;
            }
        }
    };

    const closeInterestDrawing = () => {
        closeDrawingMode.current = "interest";
        closeDrawing();
    }

    const closeAvoidDrawing = () => {
        closeDrawingMode.current = "avoid";
        closeDrawing();
    }

    function closeDrawing() {
        const currentCoord = originalCoord.current;
        if (!currentCoord) {
            console.error("originalCoord não está definido");
            return;
        }

        let currentShapePoints = closeDrawingMode.current === "interest" ?
            interestAreas.current :
            avoidAreas.current;

        if (currentShapePoints.length === 1) {
            currentShapePoints.push({lat: currentCoord.lat, lng: currentCoord.lng});
        }
        const shapeType = currentShapePoints.length === 2 ? "circle" : "polygon";

        if (currentShapePoints.length < 2) {
            Notification.error("É preciso ter 2 coordenadas para fechar uma área");
            return;
        }

        showGeofence(createObjectGeofence(shapeType, currentShapePoints, closeDrawingMode.current));
    }

    const handleAddWaypoint = (position: Coordinates) => {
      if (!position) return;

      const newWaypoint = {
        id: uuidv4(),
        ...position
      };

      dispatch(defineWaypoint(newWaypoint));
    };

    const showGeofence = (geofenceData: GeofanceData) => {
        createGeofenceInMap(geofenceData);
        const modalData: ModalData = {
            id: uuidv4(),
            type: geofenceData.geofenceCategory === "INTEREST_AREA" ? "interest" : "avoid",
            coordinates: geofenceData.coordinates,
            radius: geofenceData.type === "circle" ? geofenceData.radius : undefined,
            formatted: geofenceData.formatted,
        };
        setModalData(modalData);
        setModalDatas(prevState => [...prevState, modalData]);
        setOpenModal(true);
    }

    function createGeofenceInMap(geofenceData: GeofanceData) {
        if (geofenceData.type === "circle") {
            setCirclePositions(prevState => [...prevState, {
                position: geofenceData.coordinates[0],
                radius: geofenceData.radius,
                type: geofenceData.geofenceCategory === "INTEREST_AREA" ? "interest" : "avoid",
                eventListenerMap: mapEventListenerMap,
            }]);
        } else {
            setPolygonPositions(prevState => [...prevState, {
                points: geofenceData.coordinates,
                type: geofenceData.geofenceCategory === "INTEREST_AREA" ? "interest" : "avoid",
                eventListenerMap: mapEventListenerMap,
            }]);
        }
    }

    const createObjectGeofence = (
        type: "circle" | "polygon",
        points: Coordinates[],
        typeArea: "interest" | "avoid" | ""
    ): GeofanceData => {
        const geofenceId = "geofence-" + new Date().getTime();
        if (type === "circle") {
            const radius = calculateDistanceInMeters(points[0], points[1]);
            const formatted = "corridor:" + points
                    .map(coord => `${coord.lat},${coord.lng}`)
                    .join(";") +
                ";r=" + Math.round(radius);

            return {
                geofenceId,
                type: "circle",
                coordinates: points,
                radius: radius,
                geofenceCategory: typeArea === "interest" ? "INTEREST_AREA" : "AVOID_AREA",
                formatted,
            };
        } else {
            const formatted = "polygon:" + points
                .map(coord => `${coord.lat},${coord.lng}`)
                .join(";");

            return {
                geofenceId,
                type: "polygon",
                coordinates: points,
                geofenceCategory: typeArea === "interest" ? "INTEREST_AREA" : "AVOID_AREA",
                formatted,
            };
        }
    };

    const calculateDistanceInMeters = (pontoA: Coordinates, pontoB: Coordinates) => {
        const R = 6371000;
        const dLat = ((pontoB.lat - pontoA.lat) * Math.PI) / 180;
        const dLng = ((pontoB.lng - pontoA.lng) * Math.PI) / 180;
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(pontoA.lat * Math.PI / 180) *
            Math.cos(pontoB.lat * Math.PI / 180) *
            Math.sin(dLng / 2) *
            Math.sin(dLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    };

    useEffect(() => {
        const removedItem = modalDatas.find(data =>
            !areas.some(area => area.id === data.id)
        );
        if (removedItem) {
            handleCancel(removedItem);
            const formattedAreas = modalDatas
                .filter(item => item.id !== removedItem.id)
                .map(data => data.formatted).join("|");
            dispatch(defineAvoidAreas({ formatted: formattedAreas ? formattedAreas : undefined }));
            setModalDatas(prevState => prevState.filter(item => item.id !== removedItem.id));
        }

        const addedItems = areas.filter(area =>
            !modalDatas.some(data => data.id === area.id)
        );
        addedItems.forEach((area: CreateAreaPayload) => {
            const geofenceData: GeofanceData = {
                geofenceId: area.id,
                type: area.areaType === 2 ? "circle" : "polygon",
                coordinates: area.points,
                radius: area.areaType === 2 ? area.circleRadius : undefined,
                geofenceCategory: area.interestType === "INTEREST" ? "INTEREST_AREA" : "AVOID_AREA",
            };

            let formatted: string;
            if (geofenceData.type === "circle") {
                formatted = "corridor:" + geofenceData.coordinates
                        .map(coord => `${coord.lat},${coord.lng}`)
                        .join(";") +
                    ";r=" + Math.round(geofenceData.radius!);
            } else {
                formatted = "polygon:" + geofenceData.coordinates
                    .map(coord => `${coord.lat},${coord.lng}`)
                    .join(";");
            }

            if (preAreasToAvoid.current) {
                preAreasToAvoid.current += "|" + formatted;
            } else {
                preAreasToAvoid.current = formatted;
            }
            dispatch(defineAvoidAreas({ formatted: preAreasToAvoid.current }));

            createGeofenceInMap(geofenceData);
            setModalDatas(prevState => [...prevState, {
                id: area.id ?? uuidv4(),
                type: area.interestType === "INTEREST" ? "interest" : "avoid",
                coordinates: area.points,
                radius: area.areaType === 2 ? area.circleRadius : null,
            }]);
        });

    }, [areas]);

    const handleCancel = (data: ModalData) => {
        if (data.radius) {
            setCirclePositions(prevState => prevState.filter(
                circle => circle.position.lat !== data.coordinates[0].lat || circle.position.lng !== data.coordinates[0].lng
            ));
        } else {
            setPolygonPositions(prevState => prevState.filter(
                polygon => !polygon.points.some(
                    point => data.coordinates.some(
                        coord => coord.lat === point.lat && coord.lng === point.lng
                    )
                )
            ));
        }
        clearMarkersPosition();

        if (data.type === "interest") {
            interestAreas.current = [];
        } else if (data.type === "avoid") {
            avoidAreas.current = [];

            let canceledAreaString = "";
            if (data.radius == null) {
                canceledAreaString = "polygon:" + data.coordinates
                    .map(coord => `${coord.lat},${coord.lng}`)
                    .join(";");
            } else {
                canceledAreaString = "corridor:" + data.coordinates
                    .map(coord => `${coord.lat},${coord.lng}`)
                    .join(";") + ";r=" + Math.round(data.radius);
            }

            const currentFormatted = areasToAvoid.formatted || "";

            const updatedArray = currentFormatted
                .split("|")
                .map(item => item.trim())
                .filter(item => item !== "" && item !== canceledAreaString.trim());

            const newFormatted = updatedArray.length > 0 ? updatedArray.join("|") : undefined;

            dispatch(defineAvoidAreas({formatted: newFormatted}));
        }
    };

    const handleSave = () => {
        clearMarkersPosition();
        if (closeDrawingMode.current === "interest") {
            interestAreas.current = [];
        }
        if (closeDrawingMode.current === "avoid") {
            avoidAreas.current = [];
        }
        drawingMode.current = "";
        originalCoord.current = null;
        setModalData({} as ModalData);
    };

    function clearMarkersPosition() {
        setMarkersPosition(prevState => prevState.filter(
            marker => marker.type !== closeDrawingMode.current
        ));
    }

    const contextMenuItems = [
        <ContextMenuItem
            className='text-color-darker cursor-default'
            labelClassName="text-medium"
            label={coordLabel}
            icon="rioglyph-map-marker"
            hasSpinner={showSpinner}
        />,
        <ContextMenuItem
            icon="rioglyph-exclamation-sign"
            label="Adicionar área de interesse"
            callback={() => handleMenuClick("interest")}
        />,
        <ContextMenuItem
            icon="rioglyph-ok-sign"
            label="Fechar área interesse"
            callback={() => handleMenuClick("close-interest")}
        />,
        <ContextMenuItem
            icon="rioglyph-dangerousgoods"
            label="Adicionar área evitada"
            callback={() => handleMenuClick("avoid")}
        />,
        <ContextMenuItem
            icon="rioglyph-ok-sign"
            label="Fechar área evitada"
            callback={() => handleMenuClick("close-avoid")}
        />,
        <ContextMenuItem
            icon="rioglyph rioglyph-pushpin"
            label="Adicionar ponto de passagem"
            callback={() => handleMenuClick("add-waypoint")}
        />,
    ];

    return (
        <St.Container className="height-100pct" style={{position: "relative"}}>
            <Map
                credentials={{apikey: apiKey}}
                language="de"
                center={position}
                boundingBox={boundingBox}
                zoom={10}
                hideMapSettings
                mapSettings={
                    <MapSettings
                        options={[
                            <MapTypeSettings
                                key="mapTypeSettings"
                                tooltip="Change map type"
                                dropdownHeaderText="Map views"
                                defaultTypeLabel="Default view"
                                truckTypeLabel="Truck view"
                                terrainTypeLabel="Terrain view"
                                satelliteTypeLabel="Satellite view"
                                nightTypeLabel="Night view"
                            />,
                        ]}
                    />
                }
                eventListenerMap={mapEventListenerMap}
            >
                {(api: MapApi) => {
                    mapApiRef.current = api;
                    return (
                        <>
                            {segments && <Segments segments={segments}/>}
                            {currencySegments && <CurrencySegments segments={currencySegments}/>}
                            {markers && <Markers markers={markers}/>}
                            {/*{waypoints && <WaypointMarkers />}*/}
                            {markersPosition.map((markerPosition, index) => (
                                <Marker
                                    key={index}
                                    position={markerPosition.position}
                                    icon={
                                        <SingleMapMarker
                                            name={
                                                <div
                                                    className='display-flex align-items-center gap-10 padding-3 margin-x-3'>
                                                <span
                                                    className={`text-anchorSize-h4 ${getIconByType(markerPosition.type)}`}/>
                                                    <div className='line-height-16'>
                                                        <div className='text-medium'>{markerPosition.title}</div>
                                                        <div className='text-anchorSize-12'>
                                                            {getFormattedCoordinate(markerPosition.position.lat, markerPosition.position.lat > 0 ? "N" : "S")} {getFormattedCoordinate(markerPosition.position.lng, markerPosition.position.lng > 0 ? "E" : "W")}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            markerColor={getMarkerColorByType(markerPosition.type)}
                                            markerOnHover
                                            anchorSize='lg'
                                        />
                                    }
                                    eventListenerMap={mapEventListenerMap}
                                />
                            ))}
                            {polygonPositions.map((pointPosition, index) => (
                                <Polygon
                                    key={index}
                                    points={pointPosition.points}
                                    style={getStyleByType(pointPosition.type)}
                                    eventListenerMap={mapEventListenerMap}
                                />
                            ))}
                            {circlePositions.map((circlePosition, index) => (
                                <Circle
                                    key={index}
                                    position={circlePosition.position}
                                    radius={circlePosition.radius}
                                    style={getStyleByType(circlePosition.type)}
                                    precision={30}
                                    eventListenerMap={mapEventListenerMap}
                                />
                            ))}
                            <ContextMenu
                                key={coordLabel}
                                onOpen={handleOpenContextMenu}
                                menuItems={contextMenuItems}
                                contextMenuEvent={contextMenuData.event}
                            />
                        </>
                    );
                }}
            </Map>
            <ModalMapInterestArea
                handleSave={handleSave}
                handleCancel={handleCancel}
                modalData={modalData}
                openModal={openModal}
                setOpenModal={setOpenModal}
            />
        </St.Container>
    );
};

export default RioMap;