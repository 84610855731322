import {useState} from "react";
import ConfirmationDialog from "@rio-cloud/rio-uikit/ConfirmationDialog";
import PageContainer from "../../components/routes/PageContainer";
import TableAreas from "../../components/TableAreas";
import {fetchCanceledTrip} from "../../services/trips";
import {Notification} from "@rio-cloud/rio-uikit";
import CardCountAreas from "../../components/CardCountAreas";
import {Areas} from "../../types/areas";

const AreasScreen = () => {
    const [openModal, setOpenModal] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [page, setPage] = useState(1);
    const [show, setShow] = useState(false);
    const [idDelete, setIdDelete] = useState<undefined | string>("");
    const [dashboard, setDashboard] = useState({
        globalInterests: 0,
        opcionalInterests: 0,
        globalAvoids: 0,
        optinalAvoids: 0,
    });

    const initialData = async () => {
        Notification.error("Não foi possível buscar áreas.");
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleOpenArea = (id: string) => {
        const url = `/areas/${id}`;
    }

    const handleCanceled = async () => {
        try {
            const date = new Date().toISOString();
            await fetchCanceledTrip(
                {deletedAt: date, updatedAt: date},
                idDelete as string
            );
            initialData();
            setShow(false);
        } catch (error) {
            Notification.error("Não foi possível buscar as rotas.");
        }
    };

    const demoAreas: Areas[] = [
        {
            id: "1",
            name: "Área Exemplo 1",
            use: "global",
            maxVelocity: 120,
            minVelocity: 60,
            minimumPermanency: 30,
            maximumPermanency: 90,
            type: "INTEREST",
        },
        {
            id: "2",
            name: "Área Exemplo 2",
            use: "local",
            maxVelocity: 100,
            minVelocity: 50,
            minimumPermanency: 20,
            maximumPermanency: 80,
            type:"AVOID",
        },
    ];


    return (
        <PageContainer>
            <div style={{display: "flex", gap: 20}}>
                <CardCountAreas
                    borderColor="#5CB85C"
                    label="ÁREAS DE INTERESSE"
                    label1={"globais"}
                    value1={"4"}
                    label2={"opcionais"}
                    value2={"21"}
                />

                <CardCountAreas
                    borderColor="#ff0000"
                    label="ÁREAS A SEREM EVITADAS"
                    label1="globais"
                    value1={"7"}
                    label2={"opcionais"}
                    value2={"14"}
                />
            </div>

            <TableAreas
                pages={page}
                handleDownload={() => {
                }}
                handleSort={(dir, field) => {

                }}
                areas={demoAreas}
                viewType="TABLE"
                page={page}
                handleCreateArea={handleOpenModal}
                handleOpenArea={handleOpenArea}
                handleSearchValueChange={(text) => setSearchValue(text)}
                searchValue={searchValue}
                handleCancel={(id) => {
                    setIdDelete(id);
                    setShow(true);
                }}
                handlePage={(page) => {
                    setPage(page);
                }}
            />
            <ConfirmationDialog
                show={show}
                title="Confime a Exclusão."
                content="Tem certeza que deseja excluir o item."
                onClickConfirm={handleCanceled}
                onClickCancel={() => setShow(false)}
                cancelButtonText="Cancelar"
                confirmButtonText={
                    <>
                        <span>Excluir</span>
                    </>
                }
                useOverflow
            />
            {/*<Dialog.AddTrip
                open={openModal}
                onClose={handleCloseModal}
                onClickSelectRoute={handleSelectTrip}
            />*/}
        </PageContainer>
    )
}

export default AreasScreen;