import {
  Route,
  Routes,
  Navigate,
  RouterProvider,
  createHashRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "@store";
import screens from "@screens";
import { Page } from "@components";
import { ThemeProvider } from "styled-components";
import { theme } from "@theme";
import "./styles/styles.css";
import { NotificationsContainer } from "@rio-cloud/rio-uikit";
import { PersistGate } from "redux-persist/integration/react";
import AuthContextProvider from "./providers/AuthProvider";
import { WebSocketProvider } from "./providers/WebsocketProvider";
import { NotificationsProvider } from "./providers/NotificationProvider";

const routesFromElements = createRoutesFromElements(
  <Route element={<Page />}>
    <Route path="/" element={<screens.TripsScreen />} />
    <Route path="/routing/map/trips" element={<screens.OnGoingTripsScreen />} />
    <Route path="/routing/trips" element={<screens.TripsScreen />} />
    <Route path="/routing/zone" element={<screens.AreasScreen />} />
    <Route path="/routing/routes" element={<screens.RoutesScreen />} />
    <Route
      path="/routing/routes/create"
      element={<screens.RoutesCreateScreen />}
    />
    <Route
      path="/routing/routes/update"
      element={<screens.RoutesUpdateScreen />}
    />
    <Route path="/routing/perfomance" element={<screens.PerformanceScreen />} />
    <Route
      path="/routing/trips/routes"
      element={<screens.TripsListRouteScreen />}
    />
    <Route
      path="/routing/trips/driver"
      element={<screens.TripsDriverScreen />}
    />
    <Route path="/routing/trips/schedule" element={<screens.TripsSchedule />} />
    <Route path="/routing/notifications" element={<screens.NotificationsScreen />} />
  </Route>
);

export const router = createHashRouter(routesFromElements);

const App = () => {
  return (
    <Provider store={store}>
      <AuthContextProvider>
        <PersistGate loading={null} persistor={persistor}>
          <NotificationsProvider>
            <WebSocketProvider>
              <ThemeProvider theme={theme}>
                <NotificationsContainer />
                <RouterProvider router={router} />
              </ThemeProvider>
            </WebSocketProvider>
          </NotificationsProvider>
        </PersistGate>
      </AuthContextProvider>
    </Provider>
  );
};

export default App;
