import api from ".";
import {
    CalculateFuelCostResponse,
    CreateRouteCostPayload,
    getByAccountAndCostTypeAccountDefaultCostResponse,
    getCalculateTollResponse,
    getCostTypeByAccountResponse,
    getCurrencyTypesResponse,
    getTruckTypesResponse
} from "../types/costSimulator";

export const getCostTypeByAccount = async (accountId?: string): Promise<getCostTypeByAccountResponse[]> => {
    const response = await api.get(`/v1/cost-types/account/${accountId}`, {});
    return response.data;
}

export const getByAccountAndCostTypeAccountDefaultCost = async (accountId?: string, costType?: number): Promise<getByAccountAndCostTypeAccountDefaultCostResponse> => {
    const response = await api.get(`v1/account-default-costs/account/${accountId}/cost-type/${costType}`, {});
    return response.data;
}

export const getTruckTypes = async (): Promise<getTruckTypesResponse[]> => {
    const response = await api.get(`/v1/truck-types/listAll`, {});
    return response.data;
}

export const getCalculateToll = async (
    lats?: string[],
    longs?: string[],
    type?: string,
    reduceTolls?: boolean,
    width?: string | number,
    height?: string | number,
    lenght?: string | number,
    numberOfAxles?: string,
    trailerAxles?: string,
    grossWeight?: string | number, 
    maxAxleLoad?: string | number,
    shippedHazardousGoods?: string | number,
): Promise<getCalculateTollResponse> => {
    const latsQuery = lats ? `lats=${lats.join(",")}` : "";
    const longsQuery = longs ? `longs=${longs.join(",")}` : "";
    const typeQuery = type ? `vehicleType=${type}` : "TRUCK";
    const widthQuery = `width=${width || 0}`;
    const heightQuery = `height=${height || 0}`;
    const lenghtQuery = `totalLength=${lenght || 0}`;
    const reduceTollsQuery = `reduceTolls=${reduceTolls || false}`;
    const numberOfAxlesQuery = `numberOfAxles=${numberOfAxles || 0}`;
    const trailerAxlesQuery = `trailerAxles=${trailerAxles || 0}`;
    const grossWeightQuery = `grossWeight=${grossWeight || 0}`;
    const maxAxleLoadQuery = `maxAxleLoad=${maxAxleLoad || 0}`;
    const shippedHazardousGoodsQuery = `shippedHazardousGoods=${shippedHazardousGoods || 'NONE'}`;

    const query = [
            latsQuery, 
            longsQuery, 
            typeQuery, 
            reduceTollsQuery, 
            widthQuery, 
            heightQuery, 
            lenghtQuery, 
            numberOfAxlesQuery, 
            trailerAxlesQuery, 
            grossWeightQuery,
            maxAxleLoadQuery,
            shippedHazardousGoodsQuery
        ]
        .filter(Boolean)
        .join("&");

    const response = await api.get(`/v1/calculate/calculateToll?${query}`, {});
    return response.data;
};

export const getCalculateFuelCost = async (
    costType: number,
    consumptionPerKm: number,
    priceByCostType: number,
    distanceInMeters: number
): Promise<CalculateFuelCostResponse> => {
    const query = [
        `costType=${costType}`,
        `consumptionPerKm=${consumptionPerKm}`,
        `priceByCostType=${priceByCostType}`,
        `distanceInMeters=${distanceInMeters}`,
    ].join("&");

    const response = await api.get(`/v1/calculate/calculateFuelCost?${query}`, {});
    return response.data as CalculateFuelCostResponse;
};

export const createRouteCost = async (request: CreateRouteCostPayload): Promise<CreateRouteCostPayload> => {
    const response = await api.post(`/v1/route-costs/`, request);
    return response.data;
};

export const updateRouteCost = async (request: CreateRouteCostPayload): Promise<CreateRouteCostPayload> => {
    const { id, ...requestWithoutId } = request;
    const response = await api.put(`/v1/route-costs/${request.id}`, requestWithoutId);
    return response.data;
}

export const getByIdRouteCost = async (routeId?: string): Promise<CreateRouteCostPayload> => {
    const response = await api.get(`/v1/route-costs/${routeId}`, {});
    return response.data
};

export const getCurrencyTypes = async (): Promise<getCurrencyTypesResponse[]> => {
    const response = await api.get(`v1/currency-types/`, {});
    return response.data;
}

