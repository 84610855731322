import { NotificationList } from "@components";
import { useContext } from "react";
import PageContainer from "src/components/routes/PageContainer";
import { NotificationsContext } from "src/providers/NotificationProvider";

const NotificationsScreen = () => {
    const context = useContext(NotificationsContext);

    return (
        <PageContainer>
            <div className="display-flex align-items-center gap-20">
                <div className="border border-color-primary border-width-3 border-style-solid width-50 height-50 display-flex justify-content-center align-items-center">
                    <span className={`rioglyph rioglyph-notification text-size-h1 text-color-primary`} />
                </div>
                <p className="text-size-h2 text-bold">Notificações</p>
            </div>
            <NotificationList data={context.today} title="Hoje" />
            <NotificationList data={context.notifications} title="Ultimos 7 dias" />
        </PageContainer>
    );
};

export default NotificationsScreen;
