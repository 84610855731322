import SortDirection, {SortDirectionType} from "@rio-cloud/rio-uikit/SortDirection";
import TableViewToggles, {TableViewTogglesViewType} from "@rio-cloud/rio-uikit/TableViewToggles";
import {useState} from "react";
import classNames from "classnames";
import SortArrows from "@rio-cloud/rio-uikit/SortArrows";
import omit from "lodash/fp/omit";
import Chip from "../Chip";
import TableToolbar from "@rio-cloud/rio-uikit/TableToolbar";
import Button from "@rio-cloud/rio-uikit/Button";
import TableSearch from "@rio-cloud/rio-uikit/TableSearch";
import TableSettingsDialog from "@rio-cloud/rio-uikit/TableSettingsDialog";
import TableCardsSorting from "@rio-cloud/rio-uikit/TableCardsSorting";
import isEmpty from "lodash/fp/isEmpty";
import NotFoundState from "@rio-cloud/rio-uikit/NotFoundState";
import ChipStatus from "../ChipStatus";
import ButtonDropdown from "@rio-cloud/rio-uikit/ButtonDropdown";
import {Areas} from "../../types/areas";

const defaultColumnOrder = [
    "name",
    "use",
    "maxVelocity",
    "minimumVelocity",
    "minimumPermanency",
    "maximumPermanency",
    "type",
];

type ColumnLabel = {
    [key: string]: string;
};
const columnLabels: ColumnLabel = {
    name: "Nome",
    use: "Uso",
    maxVelocity: "Velocidade Máxima",
    minimumVelocity: "Velocidade Mínima",
    minimumPermanency: "Permanência Mínima",
    maximumPermanency: "Permanência Máxima",
    type: "Tipo",
};

type ColumnDetails = {
    [key: string]: number;
};

type ColumnDetailsMap = {
    [key: string]: ColumnDetails;
};

const demoColumnsDetails: ColumnDetailsMap = {
    name: {
        width: 260,
        defaultWidth: 260,
        maxWidth: 260,
    },
    // origin: {
    //   width: 200,
    //   defaultWidth: 200,
    //   maxWidth: 200,
    // },
    // destination: {
    //   width: 200,
    //   defaultWidth: 200,
    //   maxWidth: 200,
    // },
    // vehicle: {
    //   width: 260,
    //   defaultWidth: 260,
    //   maxWidth: 260,
    // },
};

const getSortDir = (
    sortDir: SortDirectionType,
    sortBy: string,
    previousSortBy: string
) => {
    if (sortBy === previousSortBy) {
        return sortDir === SortDirection.ASCENDING
            ? SortDirection.DESCENDING
            : SortDirection.ASCENDING;
    }
    return SortDirection.ASCENDING;
};

export type Props = {
    viewType: TableViewTogglesViewType;
    pages: number;
    areas: Areas[];
    searchValue: string;
    page: number;
    handleSearchValueChange: (text: string) => void;
    handleDownload: () => void;
    handleCreateArea: () => void;
    handleOpenArea: (id: string) => void;
    handlePage: (page: number) => void;
    handleSort: (dir: string, newSort: string) => void;
    handleFilter?: () => void;
    handleCancel: (id?: string) => void;
};

const TableAreas = (props: Props) => {
    const {viewType: externalViewType} = props;
    const [sortBy, setSortBy] = useState("name");
    const [sortDir, setSortDir] = useState<SortDirectionType>(
        SortDirection.ASCENDING
    );
    const [showTableSettingsDialog, setShowTableSettingsDialog] = useState(false);
    const [columnOrder, setColumnOrder] = useState<string[]>(defaultColumnOrder);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
    const [columnsDetails, setColumnsDetails] = useState(demoColumnsDetails);
    const [viewType, setViewType] = useState(
        externalViewType || TableViewToggles.VIEW_TYPE_TABLE
    );

    const handleToggleTableSettingsDialog = () =>
        setShowTableSettingsDialog(!showTableSettingsDialog);
    const handleViewTypeChange = (newViewType: TableViewTogglesViewType) =>
        setViewType(newViewType);

    const handleColumnChange = (
        newColumnOrder: string[],
        newHiddenColumns: string[],
        newColumnsDetails = columnsDetails
    ) => {
        setColumnOrder(newColumnOrder);
        setHiddenColumns(newHiddenColumns);
        setColumnsDetails(newColumnsDetails);
    };

    const handleColumnDetailsChange = (
        column: string,
        newColumnDetails: ColumnDetails
    ) => {
        const updatedColumnsDetails = {...columnsDetails};
        updatedColumnsDetails[column] = newColumnDetails;
        setColumnsDetails(updatedColumnsDetails);
    };

    const handleSortChange = (event: React.MouseEvent<HTMLElement>) => {
        const newSortBy = event.currentTarget.getAttribute("data-sortby");
        if (newSortBy) {
            handleCardSortChange(newSortBy, getSortDir(sortDir, newSortBy, sortBy));
            props.handleSort(sortDir, newSortBy);
        }
    };

    const handleCardSortChange = (
        newSortBy: string,
        newSortDir: SortDirectionType
    ) => {
        setSortBy(newSortBy);
        setSortDir(newSortDir);
    };

    const icons = {
        id: "",
        name: "rioglyph rioglyph rioglyph-layer margin-right-10 text-color-gray text-size-20",
        use:
            "rioglyph rioglyph-global margin-right-10 text-color-gray text-size-16",
        maxVelocity:
            "rioglyph rioglyph rioglyph-speed margin-right-10 text-color-gray text-size-16",
        minimumVelocity:
            "rioglyph rioglyph rioglyph-speed margin-right-10 text-color-gray text-size-16",
        minimumPermanency:
            "rioglyph rioglyph rioglyph-time margin-right-10 text-color-gray text-size-16",
        maximumPermanency:
            "rioglyph rioglyph rioglyph-time margin-right-10 text-color-gray text-size-16",
        type: "",
    };

    const renderTableHead = (
        column: string,
        label: string,
        sortByColumn: string,
        sortDirection: SortDirectionType
    ) => {
        const tableHeadClassNames = classNames("user-select-none", "sort-column");

        return (
            <th
                key={column}
                className={tableHeadClassNames}
                onClick={handleSortChange}
                data-field={column}
                data-sortby={column}
                title={label}
            >
        <span>
          {sortByColumn === column ? (
              <SortArrows direction={sortDirection}/>
          ) : (
              <SortArrows/>
          )}
            <span>{label}</span>
        </span>
            </th>
        );
    };

    const renderTableCaption = (column: string, columnDetails: ColumnDetails) => {
        const style = columnDetails?.width
            ? {
                minWidth: columnDetails.width,
                width: columnDetails.width,
            }
            : {};

        return <col key={column} style={style}/>;
    };

    const columns = columnOrder.filter((name) => !hiddenColumns.includes(name));

    const withoutHidden = omit(hiddenColumns);
    const filteredRows = props.areas.map((vehicle) => ({
        ...withoutHidden(vehicle),
    })) as Areas[];

    const searchResult = filteredRows;

    const rows = searchResult;

    const tableClassNames = classNames(
        "table",
        "table-layout-fixed",
        "table-column-overflow-hidden",
        "table-bordered",
        "table-sticky",
        "table-head-filled",
        viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD &&
        "table-cards table-single-card",
        viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS &&
        "table-cards table-multi-cards"
    );

    const isViewTypeTable = viewType === TableViewToggles.VIEW_TYPE_TABLE;

    const cardSortingSelectOptions = columns.map((column) => {
        return {
            id: column,
            label: columnLabels[column],
            selected: column === sortBy,
            disabled: false,
        };
    });

    const renderItems = (id?: string) => {
        const items = [
            // {
            //   value: (
            //     <div>
            //       <span className="rioglyph rioglyph-map-location margin-right-10 text-color-primary" />
            //       <span>Abrir rota do mapa</span>
            //     </div>
            //   ),
            // },
            // {
            //   value: (
            //     <div>
            //       <span className="rioglyph rioglyph-map-location margin-right-10 text-color-primary" />
            //       <span>Alterar data e hora</span>
            //     </div>
            //   ),
            // },
            // {
            //   value: (
            //     <div>
            //       <span className="rioglyph rioglyph-calendar margin-right-10 text-color-primary" />
            //       <span>Trocar motorista</span>
            //     </div>
            //   ),
            // },
            // {
            //   value: (
            //     <div>
            //       <span className="rioglyph rioglyph-cost-efficency margin-right-10 text-color-primary" />
            //       <span>Vincular veículo</span>
            //     </div>
            //   ),
            // },
            // {
            //   value: (
            //     <div>
            //       <span className="rioglyph rioglyph-cost-efficency margin-right-10 text-color-primary" />
            //       <span>Autonomia do veículo</span>
            //     </div>
            //   ),
            // },
            // {
            //   value: (
            //     <div>
            //       <span className="rioglyph rioglyph-cost-efficency margin-right-10 text-color-primary" />
            //       <span>Carga</span>
            //     </div>
            //   ),
            // },
            {
                value: (
                    <div onClick={() => props?.handleCancel(id)}>
                        <span className="rioglyph rioglyph-trash margin-right-10 text-color-primary"/>
                        <span>Cancelar viagem</span>
                    </div>
                ),
            },
        ];
        return items;
    };

    const renderChip = (driverId: string) => {
        const follower = "";

        return follower?.length ? (
            <Chip
                text="Conectado"
                icon="rioglyph rioglyph-user-group"
                background="bg-status-driving"
            />
        ) : (
            <Chip
                text="Minha equipe"
                icon="rioglyph rioglyph-role-management"
                background="bg-status-working"
            />
        );
    };

    return (
        <div id="table-areas">
            <TableToolbar>
                <div className="table-toolbar-container">
                    <div className="table-toolbar-group-left">
                        <div className="table-toolbar-column">
                            <div className="btn-toolbar table-btn-toolbar">
                                <Button
                                    bsStyle={Button.PRIMARY}
                                    iconName="rioglyph-plus"
                                    onClick={props.handleCreateArea}
                                >
                                    Criar Área
                                </Button>
                                <TableSearch
                                    value={props.searchValue}
                                    onChange={props.handleSearchValueChange}
                                    placeholder="Buscar em areas"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="table-toolbar-group-right">
                        {<div className="table-toolbar-column table-toolbar-column-spacer">
                            <button
                                type="button"
                                className="btn btn-default btn-icon-only"
                                onClick={props.handleDownload}
                            >
                <span
                    className="rioglyph rioglyph-download"
                    aria-hidden="true"
                ></span>
                            </button>
                        </div>}

                        <div className="table-toolbar-column table-toolbar-column-spacer">
                            <TableViewToggles
                                initialViewType={viewType}
                                onViewTypeChange={handleViewTypeChange}
                                tableViewTooltipContent="Table view"
                                singleCardViewTooltipContent="List view"
                                multiCardsViewTooltipContent="Cards view"
                            />
                        </div>

                        {/* <div className="table-toolbar-column">
              <button type="button" className="btn btn-default">
                <span
                  onClick={props.handleFilter}
                  className="rioglyph rioglyph-filter text-size-20"
                  aria-hidden="true"
                ></span>
                Filtros
              </button>
            </div> */}
                    </div>
                    {showTableSettingsDialog && (
                        <TableSettingsDialog
                            show={showTableSettingsDialog}
                            title="Table settings"
                            onHide={handleToggleTableSettingsDialog}
                            onColumnChange={handleColumnChange}
                            defaultColumnOrder={defaultColumnOrder}
                            defaultHiddenColumns={[]}
                            columnOrder={columnOrder}
                            hiddenColumns={hiddenColumns}
                            columnLabels={columnLabels}
                            disabledColumns={[]}
                            closeButtonText="Close"
                            resetButtonText="Reset to default"
                            searchPlaceholder="Search by column name"
                            notFoundMessage="No column found for this search value"
                            columnsDetails={columnsDetails}
                            autoLabel="Auto"
                            onColumnDetailsChange={handleColumnDetailsChange}
                            onSearchChange={(val: string) => console.log(val)}
                            immediateChange
                        />
                    )}
                </div>
            </TableToolbar>
            {sortBy && !isViewTypeTable && (
                <TableCardsSorting
                    selectOptions={cardSortingSelectOptions}
                    sortName={sortBy}
                    sortOrder={sortDir}
                    onSortChange={handleCardSortChange}
                />
            )}
            <div>
                {isEmpty(rows) && !isViewTypeTable && (
                    <NotFoundState
                        headline="Sem resultados"
                        message="Por favor refaça a busca."
                    />
                )}
                <table className={tableClassNames}>
                    <colgroup>
                        {columns.map((column) =>
                            renderTableCaption(column, columnsDetails[column])
                        )}
                        <col className="table-action"/>
                    </colgroup>
                    <thead>
                    <tr>
                        {columns.map((column) =>
                            renderTableHead(column, columnLabels[column], sortBy, sortDir)
                        )}
                        <th className="table-action"/>
                    </tr>
                    </thead>
                    <tbody>
                    {isEmpty(rows) && isViewTypeTable && (
                        <tr>
                            <td colSpan={columns.length + 1}>
                                <NotFoundState
                                    outerClassName="border-none"
                                    headline="Sem resultados"
                                    message="Por favor refaça a busca."
                                />
                            </td>
                        </tr>
                    )}

                    {rows.map((row: Areas, index: number) => {
                        return (
                            <tr key={index}>
                                <td key={"Nome"} data-field={"Nome"}>
                                    <div className="display-flex flex-direction-row align-items-center">
                                        <div className="padding-x-15">
                                            <span
                                                className="text-bold text-size-16 text-capitalize rioglyph rioglyph rioglyph-layer margin-right-10 text-color-gray">
                                             </span>
                                            <span className="text-bold text-size-14">
                                                {row.name}
                                            </span>

                                        </div>
                                    </div>
                                </td>
                                <td key={"Use"} data-field={"Uso"}>
                                    <div className="display-flex align-items-center">
                                        {row.use === "global" ?
                                            <span
                                                className="rioglyph rioglyph-globe margin-right-10 text-color-gray text-size-16"></span> :
                                            <span
                                                className="rioglyph rioglyph-settings margin-right-10 text-color-gray text-size-16"></span>}
                                        <span className="text-size-14 text-normal text-color-dark">
                                            {row.use}
                                        </span>
                                    </div>
                                </td>
                                <td key={"MaxVelocity"} data-field={"MaxVelocity"}>
                                    <span
                                        className="rioglyph rioglyph rioglyph-speed margin-right-10 text-color-gray text-size-16"></span>
                                    <span className="text-size-14 text-normal text-color-dark">{row.maxVelocity}</span>
                                </td>
                                <td key={"MinimumVelocity"} data-field={"MinimumVelocity"}>
                                    <span
                                        className="rioglyph rioglyph rioglyph-speed margin-right-10 text-color-gray text-size-16"></span>
                                    <span className="text-size-14 text-normal text-color-dark">{row.minVelocity}</span>
                                </td>
                                <td key={"MinimumPermanency"} data-field={"MinimumPermanency"}>
                                    <span
                                        className="rioglyph rioglyph rioglyph-time margin-right-10 text-color-gray text-size-16"></span>
                                    <span
                                        className="text-size-14 text-normal text-color-dark">{row.minimumPermanency}</span>
                                </td>
                                <td key={"MaximumPermanency"} data-field={"MaximumPermanency"}>
                                    <span
                                        className="rioglyph rioglyph rioglyph-time margin-right-10 text-color-gray text-size-16"></span>
                                    <span
                                        className="text-size-14 text-normal text-color-dark">{row.maximumPermanency}</span>
                                </td>
                                <td key={"Type"} data-field={"Type"}>
                                    <ChipStatus status={row?.type}/>
                                </td>
                                <td className="table-action">
                                    <span>
                                        <ButtonDropdown
                                            bsSize="md"
                                            dropdownClassName="width-200"
                                            title={
                                                <span className="rioglyph rioglyph-option-vertical"/>
                                            }
                                            variant="link"
                                            iconOnly
                                            items={renderItems(row.id)}
                                        />
                                    </span>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            <div
                className="display-flex gap-50 flex-wrap justify-content-center"
                style={{marginTop: 40}}
            >
                <ul className="pagination">
                    <li className="disabled">
                        <span className="rioglyph rioglyph-chevron-left"></span>
                    </li>

                    {Array.from({length: props.pages}, (_, i) => (
                        <li
                            key={i}
                            onClick={() => props.handlePage(i + 1)}
                            style={{background: i + 1 === props.page ? "#A7AFBB" : ""}}
                        >
              <span style={{color: i + 1 === props.page ? "white" : ""}}>
                {i + 1}
              </span>
                        </li>
                    ))}

                    <li>
                        <span className="rioglyph rioglyph-chevron-right"></span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default TableAreas;