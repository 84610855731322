import { formatDateUTCBr } from "@utils";
import { INotification } from "src/providers/NotificationProvider";

const NotificationItem = ({ driverName, createdAt, destinyName }: INotification) => {
    return (
        <div className="padding-y-15 padding-x-25">
            <div>
                <span className="text-size-12 text-color-dark text-bold line-height-20">Desvio de rota</span>
            </div>

            <div className="display-flex align-items-center gap-15 padding-y-10">
                <div className="bg-danger width-40 height-40 display-flex align-items-center justify-content-center rounded-circle padding-bottom-5">
                    <span className="rioglyph rioglyph-warning-sign text-size-h4 text-color-white" />
                </div>

                <div>
                    <div>
                        <span className="text-bold text-size-14 line-height-20 text-capitalize">{driverName}</span>
                    </div>

                    <div className="display-flex gap-10 align-items-center">
                        <div className="display-flex gap-4 align-items-center">
                            <span className="rioglyph rioglyph-calendar text-size-12 text-color-gray"></span>
                            <span className="text-size-12 line-height-20 text-normal">{formatDateUTCBr(createdAt)}</span>
                        </div>
                        <div className="width-4 height-4 bg-black rounded" />
                        <div className="display-flex gap-4 align-items-center">
                            <span className="rioglyph rioglyph-finish text-size-14 text-color-gray"></span>
                            <span className="text-size-12 line-height-20 text-normal">{destinyName}</span>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    )
}

export default NotificationItem;