import React, {ChangeEvent, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import "./CustomSlider.css";


type CustomSliderProps = {
    value?: number;
    minValue?: number;
    maxValue?: number;
    step?: number;
    onChange?: (newValue: number) => void;
    disabled?: boolean;
    color?: string;
    className?: string;
};
const CustomSlider: React.FC<CustomSliderProps> = ({
                                                       value = 0,
                                                       minValue = 0.1,
                                                       maxValue = 50,
                                                       step = 0.1,
                                                       onChange,
                                                       disabled = false,
                                                       className = '',
                                                       color = '#30B4C1',
                                                       ...props
                                                   }) => {
    const [internalValue, setInternalValue] = useState(value);

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = parseFloat(event.target.value);
        setInternalValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    const progress = ((internalValue - minValue) / (maxValue - minValue)) * 100;

    return (
        <div className={`custom-slider ${className}`} style={{
            '--progress': `${progress}%`,
            '--slider-filled-color': color,
            '--slider-empty-color': '#E5EBF0',
            '--slider-height': '3px',
        } as React.CSSProperties}{...props}>
            <input
                type="range"
                value={internalValue}
                min={minValue}
                max={maxValue}
                step={step}
                onChange={handleChange}
                disabled={disabled}
                className="slider"
            />

        </div>
    );
};

CustomSlider.propTypes = {
    value: PropTypes.number,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    step: PropTypes.number,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
};

export default CustomSlider;
