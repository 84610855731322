import {
    CreateAccountDefaultAreaPayload,
    CreateAreaPayload,
    GetByAccountAccountDefaultArea,
    GetByIdAccountDefaultArea
} from "../types/areas";
import api from "./index";
import {CreateRouteCostPayload} from "../types/costSimulator";

//Cria uma nova area sem vinculo com a conta.
export const createArea = async (request: CreateAreaPayload): Promise<CreateAreaPayload> => {
    const response = await api.post(`/v1/route-areas/`, request);
    return response.data;
}

//Cria uma area padrão para a conta.
export const createAccountDefaultArea = async (request: CreateAccountDefaultAreaPayload): Promise<CreateAccountDefaultAreaPayload> => {
    const response = await api.post(`/v1/account-default-areas/`, request);
    return response.data;
}

//Busca uma area padrão de acordo com o id da area
export const getByIdAccountDefaultArea = async (id: number): Promise<GetByIdAccountDefaultArea> => {
    const response = await api.get(`/v1/account-default-areas/${id}`);
    return response.data;
}

//Lista todas as areas de acordo com o id da conta
export const getByAccountAccountDefaultArea = async (accountId: string): Promise<GetByAccountAccountDefaultArea[]> => {
    const response = await api.get(`/v1/account-default-areas/account/${accountId}`);
    return response.data;
}

//Lista todas as areas de uma rota pelo id da rota.
export const getRouteAreas = async (routeId: string): Promise<CreateAreaPayload[]> => {
    const response = await api.get(`/v1/route-areas/route/${routeId}`);
    return response.data;
}

//Atualiza uma area sem vinculo com a conta
export const updateRouteArea = async (request: CreateAreaPayload): Promise<CreateAreaPayload> => {
    const response = await api.put(`/v1/route-areas/${request.id}`, request);
    return response.data;
}

//Atualiza uma area de acordo com o vinculo da conta
export const updateRouteAreaOfAccount = async (request: CreateRouteCostPayload): Promise<CreateRouteCostPayload> => {
    const response = await api.put(`/v1/account-default-areas/${request.id}`, request);
    return response.data;
}

//Deleta uma area sem vinculo com a conta
export const deleteRouteArea = async (id: number): Promise<void> => {
    await api.delete(`/v1/route-areas/${id}`);
}

//Deleta uma area de acordo com o vinculo da conta
export const deleteRouteAreaOfAccount = async (id: number): Promise<void> => {
    await api.delete(`/v1/account-default-areas/${id}`);
}