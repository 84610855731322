import { getRandomValueToForceRerender, SandboxMarker } from "src/components/Map";
import { decode } from "src/utils/flexiblePolyline";

export const mountPolyline = (polilyne: Array<string>) => {
    let ArrayOfShape: any[] = [];
    const getPolylineArray = polilyne.map((item) => {
        return {
            shape: decode(item),
        };
    });

    getPolylineArray.forEach((item) => {
        ArrayOfShape.push(item.shape.polyline);
    });

    const concatArrays = ArrayOfShape.flat();

    const getPoints = concatArrays.map((value) => {
        return {
            lat: value[0].toString(),
            lng: value[1].toString(),
        };
    });

    const markerDestination: SandboxMarker = {
        id: getRandomValueToForceRerender().toString(),
        markerProps: {
            iconNames: ["truck"],
            name: "",
            markerColor: "bg-map-marker-asset",
            active: false,
            fixed: false,
        },
        position: {
            lat: getPoints[getPoints.length - 1].lat,
            lng: getPoints[getPoints.length - 1].lng,
        },
    };
    

    return { segments: getPoints, markerDestiny: markerDestination };
};