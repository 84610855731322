import { formatKg, formatMoney } from "@utils";
import CustomInputSelect from "../CustomInputSelect/CustomInputSelect";
import { IStop } from "../RegisterRoute";

interface DynamicContentSimuiladorProps {
    selectedFuelOption: string | number;
    kmInputValue: string;
    priceInputValue: string;
    fuelType: { value: number; label: string }[];
    tollValue: number;
    fuelValue: number;
    totalValue: number;
    showResults: boolean;
    priceInputRef: React.RefObject<HTMLInputElement>;
    priceOperational: string;
    handleFuelOptionChange: (newValue: string | number) => void;
    handleKmInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleKmInputBlur: () => void;
    handleKmInputKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    handlePriceInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handlePriceInputBlur: () => void;
    handlePriceOperational: (value: string) => void;
}

const DynamicContentSimuilador = ({
    selectedFuelOption,
    kmInputValue,
    priceInputValue,
    fuelType,
    tollValue,
    fuelValue,
    totalValue,
    showResults,
    priceInputRef,
    priceOperational,
    handleFuelOptionChange,
    handleKmInputChange,
    handleKmInputBlur,
    handleKmInputKeyDown,
    handlePriceInputChange,
    handlePriceInputBlur,
    handlePriceOperational,
}: DynamicContentSimuiladorProps) => {
    const formatCurrency = (value: number | null | undefined): string => {
        if (value === null || value === undefined || isNaN(value)) {
            return "R$ 0,00";
        }
        return new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
        }).format(value);
    };

    return (
        <div className="col-12">
            <div className="simulador-row">
                <div className="simulador-col-50-left">
                    <label htmlFor="inputWithMask1">Tipo de Alimentação</label>
                    <CustomInputSelect
                        options={fuelType}
                        value={selectedFuelOption === 0 ? "" : selectedFuelOption}
                        onChange={handleFuelOptionChange}
                    />
                </div>
            </div>
            <div className="display-flex gap-15 padding-10">
                <div className="width-33pc">
                    <label htmlFor="inputWithMask1">
                        Consumo veículo (
                        {fuelType.find((fuel) => fuel.value === selectedFuelOption)?.label.toLowerCase() === "elétrico"
                            ? "km/kwh"
                            : "km/litro"}
                        )
                    </label>
                    <input
                        type="text"
                        className="simulador-input"
                        value={kmInputValue}
                        onChange={handleKmInputChange}
                        onBlur={handleKmInputBlur}
                        onKeyDown={handleKmInputKeyDown}
                    />
                </div>

                <div className="width-33pc">
                    <label htmlFor="inputWithMask1">
                        Preço combustível (
                        {fuelType.find((fuel) => fuel.value == selectedFuelOption)?.label.toLowerCase() === "elétrico"
                            ? "R$/kw"
                            : "R$/litro"}
                        )
                    </label>
                    <input
                        type="text"
                        className="simulador-input"
                        value={priceInputValue}
                        onChange={handlePriceInputChange}
                        onBlur={handlePriceInputBlur}
                        placeholder=""
                        ref={priceInputRef}
                    />
                </div>

                <div className="width-60pct">
                    <label htmlFor="inputWithMask1">
                        Custo operacional (R$)
                    </label>
                    <div className="input-group">
                        <input
                            type="text"
                            className="simulador-input"
                            value={'R$ ' + priceOperational}
                            onChange={(e) => handlePriceOperational(formatMoney(e.target.value))}
                        />
                    </div>
                </div>
            </div>
            <div></div>
            {showResults && (
                <div className="col-12 simulador-result-info-container">
                    <div className="simulador-result-item">
                        <div>
                            <label className="simulador-item-info-result-title">Custo combustível</label>
                        </div>
                        <label className="simulador-item-info-result-value">{formatCurrency(fuelValue)}</label>
                    </div>
                    <div className="simulador-result-item">
                        <div>
                            <label className="simulador-item-info-result-title">Custo pedágio</label>
                        </div>
                        <label className="simulador-item-info-result-value">{formatCurrency(tollValue)}</label>
                    </div>
                    <div className="simulador-result-item">
                        <div>
                            <label className="simulador-item-info-result-title">Custo operacional</label>
                        </div>
                        <label className="simulador-item-info-result-value">R$ {priceOperational}</label>
                    </div>
                    <div className="simulador-result-item-total">
                        <div>
                            <label className="simulador-item-info-result-total">TOTAL</label>
                        </div>
                        <label className="simulador-item-info-result-total-value">{formatCurrency(totalValue)}</label>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DynamicContentSimuilador;

