interface Props {
    status?: string;
}

const ChipStatus = ({ status }: Props) => {
    const getStatusDetail = (s?: string) => {
        if (!s) {
            return { color: "darker text on bg-light", text: "Pendente" };
        }

        const statusMap: { [key: string]: { color: string, text: string } } = {
            "PENDING": {
                color: "darker text on bg-light",
                text: "Pendente"
            },
            "SCHEDULED": {
                color: "darker text on bg-light",
                text: "Agendado"
            },
            "ACCEPTED": {
                color: "white text on bg-success",
                text: "Aceitou"
            },
            "REFUSED": {
                color: "white text on bg-danger",
                text: "Recusou"
            },
            "INTEREST": {
                color: "white text on bg-success",
                text: "Área de interesse"
            },
            "AVOID": {
                color: "white text on bg-danger",
                text: "Área a ser evitada"
            },
            "ONROUTE": {
                color: "white text on bg-success",
                text: "Na rota"
            },
            "DELAYED": {
                color: "white text on bg-danger",
                text: "Atrasada"
            },
            "OVERDUE": {
                color: "white text on bg-danger",
                text: "Atrasada"
            },
            "ESTIMATED": {
                color: "white text on bg-success",
                text: "Estimado"
            },
            "OUTROUTE": {
                color: "white text on bg-danger",
                text: "Fora de rota"
            },
            "EARLY": {
                color: "white text on bg-warning",
                text: "Adiantada"
            },
            'STARTING': {
                color: 'white text on bg-info',
                text: "Iniciando..."
            },
            'NOTSTARTED': {
                color: 'darker text on bg-light',
                text: "Não iniciada"
            },
            'PAUSED': {
                color: 'white text on bg-status-driving',
                text: "Pausada"
            },
            'CANCELED': {
                color: "white text on bg-danger",
                text: "Cancelada"
            },
            'FINISHED': {
                color: "white text on bg-danger",
                text: "Finalizada"
            },
            'STARTED': {
                color: "white text on bg-success",
                text: "Iniciada"
            },
        };

        return statusMap[s] || { color: "darker text on bg-light", text: "Pendente" };
    };


    const statusRc = getStatusDetail(status);

    return (
        <div
            className={`width-120 ${statusRc.color} rounded-extra-large padding-4 display-flex flex-direction-row align-items-center justify-content-center`}
        >
            {statusRc.text}
        </div>
    );
};

export default ChipStatus;