import { Client } from "@amityco/ts-sdk";
import api from ".";

export const getDriverVehicle = async (userId?: string, params?: any) => {
  const response = await api.get(`${process.env.REACT_APP_RIO_BASE_URL}/${process.env.REACT_APP_VERSION}/fleets/drivers/rio/${userId}`, {
    params,
  });
  return response.data;
};

export const getInfractions = async (name?: string, document?: string) => {
    const response = await api.get(`${process.env.REACT_APP_RIO_BASE_URL}/${process.env.REACT_APP_VERSION}/fleets/drivers/infractions`, {
      params: {
        name,
        document,
        size: 10000,
        days: 90
      },
    });
    return response.data;
  };

  
  export const getScore = async (userId?: string) => {
    const response = await api.get(
      userId !== '' && userId !== undefined
        ? `v1/routes/rio/scores/${userId}`
        : `v1/routes/rio/scores`, 
      {
        params: {
          months: 3
        },
        headers: { token_amity: Client.getActiveClient().token?.accessToken },
      }
    );
    return response.data;
  };