/* eslint-disable react-hooks/exhaustive-deps */
import PageContainer from "../../components/routes/PageContainer";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "src/hooks/useDebounce";
import {
  excludeRoute,
  fetchRoutesList,
  fetchVehicleList,
  fetchVehiclesWithTags,
  fetchVehicleTags,
} from "src/services/routes";
import { Notification, TableSearch } from "@rio-cloud/rio-uikit";
import { Dialog, RoutesTableTrip } from "@components";
import { useDispatch } from "react-redux";
import {
  setIdRouteSelected,
  setIdVehicleSelected,
  setNameVehicleSelected,
} from "@store";
import { useNavigate, useSearchParams } from "react-router-dom";

export interface IVehicle {
  id: string;
  name: string;
  accountId?: string;
  status?: string;
  type?: string;
  identification?: string;
  identificationType?: string;
  brand: string;
  licensePlate?: string;
  licensePlateCountryCode?: string;
  embedded?: null | string[];
}

const TripsListRouteScreen = () => {
  const [routes, setRoutes] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState("desc");
  const [fieldSort, setFieldSort] = useState("name");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [openModalVehicle, setOpenModalVehicle] = useState(false);
  const [vehicles, setVehicles] = useState<IVehicle[]>([]);
  const [searchVehicle, setSearchVehice] = useState("");
  const [pageVehicle, setPageVehicle] = useState(1);
  const [pagesVehicle, setPagesVehicle] = useState(1);
  const [fleets, setFleets] = useState<{ [x: string]: string; }>();
  const [next, setNext] = useState('');
  const [showItemsVehicle, setShowItemsVehicle] = useState({
    start: 0,
    end: 10,
  })
  const debouncedSearch = useDebounce(searchValue, 400);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const handleUpdateRoute = (id: string) => {
    const currentParams = new URLSearchParams(search);
    currentParams.set("id", id);
    const url = "/routing/routes/update?" + currentParams.toString();
    navigate(url);
    // window.location.href = url;
  };

  const initialData = async (pageToSearch?: number, signal?: AbortSignal, search = debouncedSearch) => {
    try {
      const res = await fetchRoutesList(
        pageToSearch || page,
        10,
        search,
        sort,
        fieldSort, 
        { signal }
      );

      if (res.data.result.routes) {
        setRoutes(res.data.result.routes);
        setPages(res.data.result.pages);
      }
    } catch (error) {
      if ((error as any).name !== "CanceledError") 
        Notification.error("Não foi possível buscar as rotas.");
    } finally {
      if (pageToSearch) {
        setPage(pageToSearch);
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    initialData(undefined, signal);
    return () => {
      controller.abort();
    };
  }, [page, debouncedSearch, sort]);

  const initialVehicleData = async () => {
    try {
      const res = await fetchVehiclesWithTags();
      if (res.data.result) {
        setVehicles(res.data.result.items);
        if (res.data.result.links.next.href) {
          setNext(res.data.result.links.next.href);
        }
      }
    } catch (error) { }
  };

  const handleMoreVehicle = async () => {
    try {
      const res = await fetchVehiclesWithTags(vehicles?.[vehicles.length - 1]?.id);
      if (res.data.result) {

        setVehicles(prev => [...prev, ...res.data.result.items]);
        if (res.data.result.links.next.href) {
          setNext(res.data.result.links.next.href);
        }
      }
    } catch (error) { }
  };

  const getVehicleTag = async () => {
    try {
      const res = await fetchVehicleTags();
      if (res.data.result) {
        const mapped = res.data.result.reduce((acc: { [x: string]: string; }, res: { id: string | number; name: any; }) => {
          acc[res.id] = res.name;
          return acc;
        }, {});
        setFleets(mapped);
      }
    } catch (error) { }
  };

  const initData = async () => {
    await getVehicleTag();
    await initialVehicleData();
  }

  useEffect(() => {
    initData();
  }, []);

  const filteredVehicles = useMemo(() => {
    if (!searchVehicle) return vehicles;
  
    const searchLower = searchVehicle.toLowerCase();

    return vehicles.filter((vehicle) => {
      return (
        (vehicle.name || "").toLowerCase().includes(searchLower)
      );
    });
  }, [vehicles, searchVehicle]);

  useEffect(() => {
    if (next) {
      handleMoreVehicle()
    }
  }, [next])

  const handleDeleteRoute = async (id: string) => {
    try {
      await excludeRoute(id);
      Notification.info("Rota excluída com sucesso!");
      initialData(page > 1 && routes.length === 1 ? page - 1 : page);
    } catch (error) {
      Notification.error("Erro ao excluir rota!");
    }
  };

  const handleSelectedRoute = (id: string) => {
    setShowItemsVehicle({
      start: 0,
      end: 10,
    });
    setPageVehicle(1);
    dispatch(setIdRouteSelected(id));
    setOpenModalVehicle(true);
  };

  const handleSaveVehicle = (id: string, name: string) => {
    setOpenModalVehicle(false);
    dispatch(setIdVehicleSelected(id));
    dispatch(setNameVehicleSelected(name));
    navigate("/routing/trips/driver");
  };

  const handlePageChange = (pageN: number) => {
    setPageVehicle(pageN);
    const itensPorPagina = 10;
    const start = (pageN - 1) * itensPorPagina;
    const end = pageN * itensPorPagina;
  
    setShowItemsVehicle({
      start: start,
      end: end,
    });
  };
  


  return (
    <PageContainer>
      <div>
        <div className="display-flex align-items-center gap-20">
          <div className="border border-color-primary border-width-3 border-style-solid width-50 height-50 display-flex justify-content-center align-items-center">
            <span
              className={`rioglyph rioglyph-route-option text-size-h1 text-color-primary`}
            />
          </div>
          <p className="text-size-h2 text-bold">
            Selecionar rota para a viagem
          </p>
        </div>
      </div>

      <div>
        <TableSearch
          value={searchValue}
          onChange={(text) => setSearchValue(text)}
          placeholder="Buscar em rotas"
        />
      </div>

      <RoutesTableTrip
        handleSelect={handleSelectedRoute}
        pages={pages}
        handleSort={(dir, field) => {
          setSort(dir);
          setFieldSort(field);
        }}
        routes={routes}
        viewType="TABLE"
        page={page}
        handleUpdateRoute={handleUpdateRoute}
        handleSearchValueChange={(text) => setSearchValue(text)}
        handleDeleteRoute={handleDeleteRoute}
        searchValue={searchValue}
        handlePage={(page) => {
          setPage(page);
        }}
      />

      <Dialog.AddVehicle
        initialSlice={showItemsVehicle.start}
        endSlice={showItemsVehicle.end}
        inputValue={searchVehicle}
        onChangeInput={(text) => setSearchVehice(text)}
        vehicles={filteredVehicles}
        fleets={fleets}
        onClose={() => setOpenModalVehicle(false)}
        handleCancel={() => {
          setOpenModalVehicle(false);
        }}
        open={openModalVehicle}
        handleSaveVehicle={handleSaveVehicle}
        page={pageVehicle}
        pages={pagesVehicle}
        handlePage={handlePageChange}
      />
    </PageContainer>
  );
};

export default TripsListRouteScreen;
