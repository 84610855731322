import { RioMap } from "@components";
import Notification from "@rio-cloud/rio-uikit/Notification";
import { IRoute, IVehicleInfo, Route } from "@types";
import { formatMetersMaskInNumber, getLocation } from "@utils";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Coordinates,
  getBoundingBox,
  getRandomValueToForceRerender,
  RoutePoint,
  SandboxMarker,
} from "src/components/Map";
import SidebarRoute from "src/components/SidebarRoute";
import { createAndSendRoute } from "src/services/routes";
import { decode } from "src/utils/flexiblePolyline";
import { IStop } from "../../components/RegisterRoute";
import {
  defineAreas,
  defineAvoidAreas,
  defineAvoidToll,
  defineDestination,
  defineDurationWithStops,
  defineOrigin,
  defineRouteDeviation,
  defineRoutes,
  defineStops,
  defineVehicleInfoDefault,
} from "src/store/routes/RoutesSlice";
import { ApplicationLayout } from "@rio-cloud/rio-uikit";
import { useNavigate } from "react-router-dom";
import { CreateRouteCostPayload } from "../../types/costSimulator";
import { createRouteCost } from "../../services/costSimulator";
import { CreateAreaPayload } from "../../types/areas";
import { createArea } from "../../services/areas";
import { RootState, useAppSelector } from "@store";

const RoutesCreateScreen = () => {
  const dispatch = useDispatch();
  const profile = useAppSelector((state) => state.login.userProfile);
  const [position, setPosition] = useState<Coordinates | undefined>({
    lat: -23.5475,
    lng: -46.63611,
  });
  const [boundingBox, setBoundingBox] = useState<
    { top: number; left: number; right: number; bottom: number } | undefined
  >();
  
  const durationWithStops = useSelector((state: RootState) => state.routes.durationWithStops);
  
  useEffect(() => {
    handleLocation();
  }, []);

  const handleLocation = async () => {
    const { lat, lon } = await getLocation();
    setPosition({ lat, lng: lon });
  };

  const [segments, setSegments] = useState<RoutePoint[]>([]);
  const [markers, setMarkers] = useState<SandboxMarker[]>([]);

  useEffect(() => {
    dispatch(defineOrigin(null));
    dispatch(defineDestination(null));
    dispatch(defineRoutes([]));
    dispatch(defineStops([]));
    dispatch(defineAvoidToll(false));
    dispatch(defineAreas([]));
    dispatch(defineAvoidAreas({}));
    dispatch(defineDurationWithStops(""));
  }, []);

  const formatCategory = (text: string) => {
    const DICT = {
      LUNCH_REST: "Refeição",
      LOAD_UNLOAD: "Coleta de carga",
      END_OF_DAY: "Fim de jornada de trabalho",
      DEALER: "Concessionária",
      WAYPOINT: 'Ponto de passagem',
    };

    return DICT[text as "LUNCH_REST" | "LOAD_UNLOAD" | "END_OF_DAY" | "DEALER"];
  };

  const navigate = useNavigate();
  const [disabledConfirm, setDisabledConfirm] = useState(false);

  const sendRoute = async (
    origin: IRoute,
    destination: IRoute,
    routes: Route[],
    stops: IStop[],
    routeCost: CreateRouteCostPayload,
    value: string,
    routeRangeToleranceLimit: number | null,
    avoidToll: boolean,
    areas: CreateAreaPayload[],
    vehicle: IVehicleInfo
  ) => {
    setDisabledConfirm(true);
    const body = {
      routeName: value,
      originRoute: {
        address: {
          label: origin.label,
        },
        addressStop: {
          label: origin.label,
        },
        lat: origin.lat,
        lng: origin.lng,
      },
      destinyRoute: {
        address: {
          label: destination.label,
        },
        addressStop: {
          label: destination.label,
        },
        lat: destination.lat,
        lng: destination.lng,
      },
      rangeToleranceLimit: routeRangeToleranceLimit ? routeRangeToleranceLimit / 1000 : 0,
      stops: stops.map((stop, index) => ({
        name: `Parada ${index + 1}`,
        category: stop.category,
        rangeLimitMeters: "0",
        position: {
          lat: stop.position?.lat,
          lng: stop.position?.lng,
          addressStop: {
            label: stop?.position?.label || "",
          },
        },
        stayTime: `${stop.stayTime}:00`,
        note: formatCategory(stop.category),
        type: "STOPOVER",
        order: stop.order,
      })),
      roadParameters: {
        trafficConditions: "false",
        avoidToll,
        avoidRoad: false,
        ignoreTrafficRestrictions: false,
      },
      vehicleVocationalInfo: {
        height: vehicle?.height ? formatMetersMaskInNumber(vehicle?.height) : 0,
        width: vehicle?.width ? formatMetersMaskInNumber(vehicle?.width) : 0,
        comTotal: vehicle?.lenght ? formatMetersMaskInNumber(vehicle?.lenght) : 0,
        grossWeight: vehicle?.grossWeight ? formatMetersMaskInNumber(vehicle?.grossWeight, "T") : 0,
        maxAxleLoad: vehicle?.maxAxleLoad && vehicle?.maxAxleLoad !== "0,00" ? vehicle?.maxAxleLoad : 0,
        numberOfAxles: vehicle?.numberOfAxles ? parseInt(vehicle?.numberOfAxles) : 0,
        trailerAxles: vehicle?.trailerAxles ? parseInt(vehicle?.trailerAxles) : 0,
        // vehicleType: vehicle?.type,
        type: vehicle?.type,
        shippedHazardousGoods: vehicle?.shippedHazardousGoods,
        pollutantClass: vehicle?.pollutantClass,
      },
      costs: {
        tollValue: "0",
        operativeCosts: "0",
        fuelAverageCosts: "0",
        averageConsume: "0",
        totalCosts: "0.00",
      },
      responsibleId: profile?.userId,
      status: true,
      linkedVehicles: [],
      responseHere: { routes },
      duration: durationWithStops,
    };

    try {
      const response = await createAndSendRoute(body);
      if (response.status == "success" && routeCost.totalCost > 0) {
        const updatedRouteCost = { ...routeCost, route: response.data.result };
        await createRouteCost(updatedRouteCost);
      }

      if (response.status == "success") {
        for (const area of areas) {
          let areaRequest = { ...area };
          areaRequest.routeId = response.data.result;
          areaRequest.id = undefined;
          await createArea(areaRequest);
        }
      }

      Notification.info(response.data.message);
      dispatch(defineRouteDeviation(0));
      dispatch(defineVehicleInfoDefault());
      dispatch(defineAreas([]));
      setTimeout(() => {
        navigate("/routing/routes");
        setDisabledConfirm(false);
      }, 1000);
    } catch (error) {
      console.log(error);
      type TError = { message: string };
      const message =
        typeof error === "string"
          ? error
          : (error as AxiosError<TError>)?.response?.data?.message || "Erro desconhecido";
      Notification.error(message as string);
      setDisabledConfirm(false);
    }
  };

  const handleRoutes = (routes: Route[], via: any[]) => {
    if (routes.length) {
      let ArrayOfShape: any[] = [];
      const route = routes?.[0];
      const data = route?.sections || [];

      const getPolylineArray = data.map((item) => {
        return {
          shape: decode(item.polyline),
        };
      });

      const markers = data.reduce((acum: SandboxMarker[], curr, index, arr) => {
        if (index === 0) {
          const markerOrigin: SandboxMarker = {
            id: getRandomValueToForceRerender().toString(),
            markerProps: {
              iconNames: ["start"],
              name: "",
              markerColor: "bg-map-marker-route",
              active: false,
              fixed: false,
            },
            position: {
              lat: curr.departure.place.location.lat,
              lng: curr.departure.place.location.lng,
            },
          };
          acum.push(markerOrigin);
        }
        if (index === arr.length - 1) {
          const markerDestination: SandboxMarker = {
            id: getRandomValueToForceRerender().toString(),
            markerProps: {
              iconNames: ["finish"],
              name: "",
              markerColor: "bg-map-marker-route",
              active: false,
              fixed: false,
            },
            position: {
              lat: curr.arrival.place.location.lat,
              lng: curr.arrival.place.location.lng,
            },
          };
          acum.push(markerDestination);
        } else {

          const markerStopover: SandboxMarker = {
            id: getRandomValueToForceRerender().toString(),
            markerProps: {
              iconNames: [curr.transport.mode === 'ferry' ? 'rioglyph rioglyph-ship' : "rioglyph rioglyph-pushpin"],
              name: via?.[index]?.type,
              markerColor: curr.transport.mode === 'ferry' ? 'bg-map-marker-asset' : "bg-map-marker-route",
              active: false,
              fixed: false,
            },
            position: {
              lat: curr.arrival.place.location.lat,
              lng: curr.arrival.place.location.lng,
            },
          };
          acum.push(markerStopover);
        }

        return acum;
      }, []);

      getPolylineArray.forEach((item) => {
        ArrayOfShape.push(item.shape.polyline);
      });
      const concatArrays = ArrayOfShape.flat();
      const getPoints = concatArrays.map((value) => {
        return {
          lat: value[0].toString(),
          lng: value[1].toString(),
        };
      });
      setSegments(getPoints as RoutePoint[]);
      setMarkers(markers);
      if (getPoints?.length > 0) {
        setPosition(undefined);
        setBoundingBox(getBoundingBox(getPoints));
      } else if (markers?.length > 0) {
        setPosition(markers[0].position);
      }
    } else {
      setMarkers([]);
      setSegments([]);
    }
  };

  return (
    <>
      <SidebarRoute disableConfirm={disabledConfirm} handleRoutes={handleRoutes} sendRoute={sendRoute} />
      <ApplicationLayout.Body innerClassName="padding-0">
        <RioMap
          position={position}
          boundingBox={boundingBox}
          apiKey={process.env.REACT_APP_RIO_MAP_KEY as string}
          segments={segments.length ? [{ points: segments, alternative: false }] : []}
          markers={markers}
        />
      </ApplicationLayout.Body>
    </>
  );
};

export default RoutesCreateScreen;

