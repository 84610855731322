import AddTrip from "./AddTrip";
import AddVehicle from "./AddVehicle";
import PerfilSocial from "./PerfilSocial";
import DriverVehicle from "./DriverVehicle";

export const Dialog = {
    AddTrip,
    AddVehicle,
    PerfilSocial,
    DriverVehicle,
}