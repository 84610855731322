import { current } from "@reduxjs/toolkit";
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { Button, DatePicker, Notification } from "@rio-cloud/rio-uikit";
import moment from "moment";

export const formatDateBr = (dateString?: string, showHour = true) => {
  if (!dateString) return "";
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return showHour
    ? `${day}/${month}/${year} ${hours}:${minutes}`
    : `${day}/${month}/${year}`;
};

export const formatDateUTCBr = (dateString?: string, showHour = true) => {
  if (!dateString) return "";
  const utc  = moment.utc(dateString).toDate();
  const date = moment(utc).format('DD/MM/YYYY HH:mm');
  return date;
};

export const validarDate = (dateString: string, showHour = true) => {
  if (!dateString) return "";

  const date = new Date(dateString);
  const today = new Date();

  date.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  if (date < today) {
    Notification.error("A data selecionada tem que ser igual ou maior que a atual.");
    return false;
  }

  return true;
};

export const formatTimeBr = (
  date: Date,
  type?: "DEFAULT" | "HOUR" | "MINUTES" | "SECONDS" | "HOUR_AND_MINUTES"
) => {
  if (!date) return "";

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  switch (type) {
    case "HOUR":
      return hours;
    case "MINUTES":
      return minutes;
    case "SECONDS":
      return seconds;
    case "HOUR_AND_MINUTES":
      return `${hours}:${minutes}`;
    default:
      return `${hours}:${minutes}:${seconds}`;
  }
};

export const formatDateEn = (dateString?: string) => {
  if (!dateString) return "";

  const [day, month, year] = dateString.split('/');

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}

export const formatValueKmMask = (value: number) => {
  if (value === null || value === undefined) return '';

  const valueKM = value / 1000;

  const valueFormatted = valueKM.toFixed(0);

  return valueKM >= 1 ? `${valueFormatted}km` : `${valueKM * 1000}m`;
};

export const formatarMeters = (VALUE: string) => {
  if (!VALUE) return '';
  let formattedValue = VALUE?.replace(/\D/g, '');
  formattedValue = formattedValue?.replace(/(\d)(\d{2})$/, '$1,$2');

  return formattedValue;
};

export const formatMetersMaskInNumber = (VALUE: string, type: 'DEFAULT' | 'T' = 'DEFAULT') => {
    if (!VALUE) return '';

    const TYPE = {
      DEFAULT: 100,
      T: 1000
    }
  
   let formattedValue = VALUE?.replace(/\./g, '');
   formattedValue = formattedValue?.replace(',', '.');
   const v = parseFloat(formattedValue) * TYPE[type];
   
   return parseInt(v.toString());
}

export const formatKg = (value: string) => {
  if (!value) return '';

  value = value.replace(/\D/g, '');
  value = (Number(value) / 100).toFixed(2);
  return value.replace('.', ',');
};

export const formatMoney = (value: string) => {
  if (!value) return '';

  value = value.replace(/\D/g, ''); 
  value = (Number(value) / 100).toFixed(2);

  const [integer, decimal] = value.split('.');
  const integerFormatted = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return `${integerFormatted},${decimal}`;
};

export const formatMoneyInNumber = (value: string) => {
  return parseFloat(value?.replace(/\./g, '').replace(',', '.'));
}

export const formatMaskTime = (value: string) => {
  // Remove tudo que não seja número
  let time = value.replace(/\D/g, '');

  // Adiciona dois pontos entre as horas e os minutos
  if (time.length >= 3) {
      time = `${time.slice(0, 2)}:${time.slice(2, 4)}`;
  } else if (time.length >= 2) {
      time = `${time.slice(0, 2)}:${time.slice(2)}`;
  }

  return time;
};

export const kmToMeters = (km?: string) => {
  if (!km) return 0;
  const calc = parseFloat(km) * 1000;
  return calc.toLocaleString();
}