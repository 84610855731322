import moment from "moment";
import React, { useEffect, useState } from "react";
import { fetchRoutesWayNotifications } from "src/services/routes";

export interface INotification {
  liveRouteId: string,
  latitude: number,
  longitude: number,
  totalDistance: number,
  distanceTraveled: number,
  createdAt: string,
  onRoute: boolean,
  tripId: string,
  status: string,
  startDateTime: string,
  driverId: string,
  assetId: string,
  driverName: string,
  assetName: string,
  accountId: string
  destinyName: string;
};

export type TNotificationsContext = {
  total: number;
  notifications: Array<INotification>;
  today: Array<INotification>;
};

export const NotificationsContext = React.createContext<TNotificationsContext>({
  total: 0,
  notifications: [],
  today: [],
});

export const NotificationsProvider = ({ children }: { children: React.JSX.Element; }) => {
  const [total, setTotal] = useState(0);
  const [today, setToday] = useState<Array<INotification>>([]);
  const [notifications, setNotifications] = useState<Array<INotification>>([]);
  const todayM = moment().format("YYYY-MM-DD");

  const handleNotifications = async () => {
    try {
      const response = await fetchRoutesWayNotifications();
      
      const todayItems = response.data?.result?.filter(
        (item: INotification) => moment(item.createdAt).format("YYYY-MM-DD") === todayM
      );

      const otherItems = response.data?.result?.filter(
        (item: INotification) => moment(item.createdAt).format("YYYY-MM-DD") !== todayM
      );

      setTotal(todayItems?.length + otherItems?.length || 0);
      setToday(todayItems);
      setNotifications(otherItems);

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleNotifications();

    const interval = setInterval(() => {
      handleNotifications();
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, [])

  return (
    <NotificationsContext.Provider
      value={{
        total,
        notifications: notifications,
        today: today,
      }}>
      {children}
    </NotificationsContext.Provider>
  );
}