import { Client } from "@amityco/ts-sdk";
import { Dialog, ExpanderListDriver, Paginator } from "@components";
import {
  Button,
  NotFoundState,
  Notification,
  TableSearch,
} from "@rio-cloud/rio-uikit";
import {
  setAvatarDriverSelected,
  setIdDriverSelected,
  setNameDriverSelected,
  useAppSelector,
} from "@store";
import { Driver } from "@types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageContainer from "src/components/routes/PageContainer";
import { fetchDriverList, fetchDriverListV1 } from "src/services/routes";
import { getFollowers, getFollowings } from "src/services/social";

const TripsDriverScreen = () => {
  const profile = useAppSelector((state) => state.login.userProfile);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [selectedItems, setSelectedItems] = useState("");
  const [page, setPage] = useState(1);
  const [data, setData] = useState<Driver[]>([]);
  // const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [nameS, setName] = useState("");
  const [userId, setUserId] = useState("");
  const [avatar, setAvatar] = useState("");
  const [pages, setPages] = useState(1);

  const [userInfo, setUserInfo] = useState<Driver | undefined>();
  const [openPerfilModal, setPerfilModal] = useState(false);
  const [openVehicleModal, setVehicleModal] = useState(false);

  const [total, setTotal] = useState(0);
  const [allDrivers, setAllDrivers] = useState<Driver[]>([]);

  const handleSearchName = (text: string) => {
    setSearchValue(text);

    if (!text.length || text.length < 1) {
      setData(allDrivers.slice(0, 10));
      setTotal(allDrivers.length);
      setPage(1);
      return;
    }

    const filtered = allDrivers?.filter((driver) =>
      driver?.name?.toLowerCase()?.includes(text?.toLowerCase())
    );

    setTotal(filtered.length);

    if (text?.length && filtered?.length) {
      setData([...filtered]);
    } else if (text.length > 0 && !filtered.length) {
      setData([]);
    }
  };

  const initialData = async () => {
    const response = await fetchDriverListV1();
    const resFollowers = await getFollowers(profile?.userId);

    const rio_users = response.data.result.drivers.map((driver: Driver) => {
      return {
        ...driver,
        avatar: resFollowers.data?.result?.users?.find(
          (res: Amity.User) => res.userId === driver.userId
        )?.metadata?.imageProfile?.fileUrl,
        type: driver.profileType === "MANAGER" ? "MANAGER" : "MY_TEAM",
        origin: "RIO",
      };
    });

    const amityUsers = resFollowers.data?.result?.users
      ?.filter(
        (driver: Amity.User) =>
          (driver?.metadata?.profileType && driver?.metadata?.profileType !== "MANAGER") ||
          (driver?.metadata?.profileType &&
            driver?.metadata?.profileType === "MANAGER" &&
            driver?.metadata?.cnhCategory)
      )
      .filter((driver: Amity.User) => !rio_users?.some((user: Driver) => user.userId === driver.userId))
      .map((user: Amity.User) => {
        const metadata = user?.metadata;
        return {
          id: user.userId,
          name: metadata?.displayName || metadata?.givenName + " " + metadata?.familyName,
          userId: user?.userId.toString(),
          avatar: metadata?.imageProfile?.fileUrl,
          type: "CONNECTED",
          origin: "AMITY",
        };
      });

    const totalArr = amityUsers.length + rio_users.length;

    const followersSorted = amityUsers.sort((a: { name: string; }, b: { name: string; }) =>
      a.name.localeCompare(b.name)
    );

    const rioDriversSorted = rio_users.sort((a: { name: string; }, b: { name: string; }) =>
      a.name.localeCompare(b.name)
    );

    const grouped = [...followersSorted, ...rioDriversSorted];

    setAllDrivers(grouped);
    setData(grouped.slice(0, 10));
    setTotal(totalArr);
    setPages(Math.ceil(totalArr / 10));
  };

  const modals = {
    CALENDAR: (driver: Driver) => {
      console.log(`open calendar modal ${driver.id}`);
    },
    PROFILE: (driver: Driver) => handlePerfilSocial(driver),
    VEHICLE: (driver: Driver) => {
      console.log(`vehicle modal open ${driver.id}`);
    }, //handleDriverVehicle(id),
  };

  const handlePerfilSocial = (driver: Driver) => {
    setUserInfo(driver);
    setPerfilModal(true);
  };

  const handleClosePerfilModal = () => {
    setPerfilModal(false);
  };

  const handleDriverVehicle = (driver: Driver) => {
    setUserInfo(driver);
    setVehicleModal(true);
  };

  const handleCloseVehicleModal = () => {
    setVehicleModal(false);
  };

  // Não remover.
  // const handleChecked = (checked: boolean, id: string) => {
  //   setSelectedItems((prev) => {
  //     if (checked) {
  //       return [...prev, id];
  //     } else {
  //       return prev.filter((item) => item !== id);
  //     }
  //   });
  // };

  const handleChecked = (
    checked: boolean,
    id: string,
    name: string,
    userIdN?: string,
    avatar?: string
  ) => {
    setSelectedItems(id);
    setName(name);
    setUserId(userIdN as string);
    setAvatar(avatar || "");
  };

  const navigate = useNavigate();

  const handleSubmit = () => {
    dispatch(setIdDriverSelected(userId));
    dispatch(setNameDriverSelected(nameS));
    dispatch(setAvatarDriverSelected(avatar));
    navigate("/routing/trips/schedule");
  };

  const handleCancel = () => {
    navigate("/routing/trips/routes");
  };

  const handlePage = (pageNumber: number) => {
    const config = {
      start: pageNumber * 10 - 10,
      end: pageNumber * 10
    };

    setPage(pageNumber);
    setData(allDrivers?.slice(config.start, config.end));
  }

  useEffect(() => {
    initialData();
  }, [Client.getActiveClient().token?.accessToken]);

  return (
    <PageContainer>
      <div className="display-flex align-items-center gap-20">
        <div className="border border-color-primary border-width-3 border-style-solid width-50 height-50 display-flex justify-content-center align-items-center">
          <span
            className={`rioglyph rioglyph-user text-size-h1 text-color-primary`}
          />
        </div>
        <p className="text-size-h2 text-bold">Motorista para a viagem</p>
      </div>
      <div>
        <TableSearch
          value={searchValue}
          onChange={handleSearchName}
          placeholder="Buscar motorista"
        />
      </div>
      <div className="padding-50 bg-white rounded shadow-default">
        <div className="margin-bottom-25">
          <span className="text-bold text-size-16">
            {total} motoristas encontrados:
          </span>
        </div>
        {data && data.length > 0 ? (
          <ExpanderListDriver
            onChecked={handleChecked}
            selected={selectedItems} //temp remover
            onClickButton={(type, _, driver) => {
              modals[type](driver!);
            }}
            data={data}
          />
        ) : (
          <NotFoundState
            headline="Nenhum motorista disponível"
            message="Volte e tente novamente."
            fullWidth
            outerClassName="border-none"
          />
        )}

        <Paginator
          handlePage={handlePage}
          pages={pages}
          page={page}
        />

        <div className="margin-top-20 display-flex gap-10 justify-content-end flex-grow">
          <Button
            bsSize={Button.MD}
            style={{ width: 84 }}
            onClick={handleCancel}
          >
            Voltar
          </Button>
          <Button
            disabled={!selectedItems}
            bsStyle={Button.PRIMARY}
            bsSize={Button.MD}
            style={{ width: 157 }}
            onClick={handleSubmit}
          >
            Buscar motorista
          </Button>
        </div>

        <Dialog.PerfilSocial
          driver={userInfo}
          open={openPerfilModal}
          onClose={handleClosePerfilModal}
        />

        <Dialog.DriverVehicle
          id={userInfo?.id}
          open={openVehicleModal}
          onClose={handleCloseVehicleModal}
        />
      </div>
    </PageContainer>
  );
};

export default TripsDriverScreen;

