import { ActionBarItem, Button } from "@rio-cloud/rio-uikit";
import NotificationItem from "../NotificationItem";
import { Link, NavLink } from "react-router-dom";
import { INotification } from "src/providers/NotificationProvider";

interface Props {
  total: number;
  href: string;
  notifications: Array<INotification>;
}

const NotificationBar = ({ total, href, notifications }: Props) => {
  return (
    <ActionBarItem popoverWidth={500}>
      <ActionBarItem.Icon>
        <span className="icon rioglyph rioglyph-notification" />
        <span className="badge badge-primary badge-indicator badge-indicator-pinging">{total}</span>
      </ActionBarItem.Icon>
      <ActionBarItem.Popover useOffscreen className="padding-0" >
        <div className="padding-x-25 padding-top-15">
            <span className="text-bold text-size-16 line-height-24 ">Notificacões</span>
        </div>
        <hr className="margin-top-15 margin-bottom-0 border-color-light border-width-2"></hr>
        {notifications && notifications.length > 0 ? (
          <div className="bg-white border rounded divider-y-2 divider-color-light divider-style-solid">
            {notifications.slice(0, 2)?.map((notification, index) => {
              return <NotificationItem {...notification} key={index} />;
            })}

            <div className="display-flex width-100pct align-items-center justify-content-center padding-20">
              <NavLink to={href} className={"display-flex text-decoration-none"}>
                <span className="icon rioglyph rioglyph-notification text-size-20" />
                <span className="margin-left-4 text-size-14 text-color-primary text-hover-black">
                  Ver todas notificações
                </span>
              </NavLink>
            </div>
          </div>
        ) : (
          <div className="display-flex justify-content-center max-width-100pct">
            <div className="margin-0 width-100pct max-width-600 panel-default panel padding-15 border-none">
              <div className="display-flex flex-column align-items-center text-center padding-25">
                <div className="text-size-h2 margin-bottom-20">
                  <span className="rioglyph text-color-light rioglyph rioglyph-notification text-size-300pct"></span>
                </div>
                <div className="text-size-h3 text-size-h2-sm line-height-125rel text-color-dark text-medium width-500 max-width-100pct">
                  Não há novas notificações
                </div>
                <div className="text-color-dark margin-top-15 width-500 max-width-100pct">
                  Se algo novo surgir, avisaremos você aqui.
                </div>
              </div>
            </div>
          </div>
        )}
      </ActionBarItem.Popover>
    </ActionBarItem>
  );
};

export default NotificationBar;

