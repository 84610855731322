import React, { useRef, createContext, type RefObject, useState, useEffect, useContext } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";

import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import ApplicationHeader from "@rio-cloud/rio-uikit/ApplicationHeader";

import { useLocation } from "react-router-dom";

import { parseRoute } from "./routeHelper";
import { useRouteState } from "./routeHooks";
import { DefaultUserMenu } from "@rio-cloud/rio-user-menu-component";
import IframeResizer from "iframe-resizer-react";
import { config } from "../../config";
import { ApplicationLayoutBodyNavigation } from "@rio-cloud/rio-uikit";
import SubNavigation from '@rio-cloud/rio-uikit/SubNavigation';
import { NotificationsContext } from "src/providers/NotificationProvider";
import NotificatioBar from "../NotificationBar";

const RouteUpdater = () => {
  const { search } = useLocation();
  useRouteState(() => {
    parseRoute(search);
  });

  return null;
};

const MapItems = [{
  key: "map",
  route: <NavLink to="/routing/map/trips" className='text-medium'>VIAGENS EM ANDAMENTO</NavLink>,
}]

const NavItems = [
  {
    key: "map",
    route: <NavLink to="/routing/map/trips">Mapa</NavLink>,
  },
  {
    key: "trips",
    route: <NavLink to="/">Viagens</NavLink>,
  },
  {
    key: "routes",
    route: <NavLink to="/routing/routes">Rotas</NavLink>,
  },
  //  {
  //    key: "areas",
  //    route: <NavLink to="/routing/zone">Áreas</NavLink>,
  //  },
  // {
  //   key: "perfomance",
  //   route: <NavLink to="/routing/perfomance">Desempenho</NavLink>,
  // },
];

const AppHeader = () => {
  const ContextNotification = useContext(NotificationsContext);

  const notifications = (
    <NotificatioBar 
      href="/routing/notifications" 
      total={ContextNotification.total} 
      notifications={ContextNotification.today.length > 0 ? ContextNotification.today : ContextNotification.notifications} 
    />
  )

  const userMenuItem = (
    <DefaultUserMenu
      environment={
        process.env.NODE_ENV === "development" ? "development" : "production"
      }
    />
  );

  return (
    <ApplicationHeader
      appNavigator={
        <IframeResizer
          className="iFrameResizer"
          src={config.backend.MENU_SERVICE}
        />
      }
      homeRoute={<Link to={config.homeRoute || ""} />}
      appMenuItems={MapItems}
      label="RIO ROUTING"
      navItems={NavItems}
      actionBarItems={[
        notifications,
        userMenuItem,
      ]}
    />
  );
};

const AppMenuSubItems = () => {
  const items = {
    map: MapItems,
  }

  const location = useLocation();

  return (
    <ApplicationLayoutBodyNavigation className={'has-offset'}>
      <SubNavigation navItems={location?.pathname === '/routing/map/trips' ? items.map : []} />
    </ApplicationLayoutBodyNavigation>
  )
}

type AppContextType = { sidebarRef: RefObject<HTMLDivElement> | null };
const AppContext = createContext<AppContextType>({ sidebarRef: null });

const AppLayout = () => {
  const sidebarRef = useRef<HTMLDivElement>(null);

  return (
    <AppContext.Provider value={{ sidebarRef }}>
      <ApplicationLayout data-testid="app-layout">
        <ApplicationLayout.Header>
          <AppHeader />
        </ApplicationLayout.Header>
        <ApplicationLayout.Sidebar className="right" ref={sidebarRef} />
        <ApplicationLayout.Body innerClassName="padding-0" className="padding-0 margin-0" navigation={<AppMenuSubItems />}>
          <Outlet />
          <RouteUpdater />
        </ApplicationLayout.Body>
      </ApplicationLayout>
    </AppContext.Provider>
  );
};

export default AppLayout;
