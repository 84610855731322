import {RegisterRouter} from "@components";
import {ApplicationLayout, Sidebar} from "@rio-cloud/rio-uikit";
import {useAppSelector} from "@store";
import {IRoute, IVehicleInfo, Route} from "@types";
import {IStop} from "../RegisterRoute";
import {CreateRouteCostPayload} from "../../types/costSimulator";
import {CreateAreaPayload} from "../../types/areas";
import { useNavigate } from "react-router-dom";

type TProps = {
  sendRoute: (
    origin: IRoute,
    destination: IRoute,
    routes: Route[],
    stops: IStop[],
    value: string,
    routeCost: CreateRouteCostPayload,
    accountId: string,
    routeRangeToleranceLimit: number | null,
    avoidToll: boolean,
    areas: CreateAreaPayload[],
    vehicle: IVehicleInfo,
  ) => void;
  handleRoutes: (routes: Route[], via: any[]) => void;
};

const UpdateSidebarRoute = ({ sendRoute, handleRoutes }: TProps) => {
  const { origin, destination, routes, stops, routeName, accountId, routeRangeToleranceLimit } =
    useAppSelector((state) => state.routes);

  const handleSave = (
    origin: IRoute,
    destination: IRoute,
    routes: Route[],
    stops: IStop[],
    routeName: string,
    routeCost: CreateRouteCostPayload,
    accountId: string,
    routeRangeToleranceLimit: number | null,
    avoidToll: boolean,
    areas: CreateAreaPayload[],
    vehicle: IVehicleInfo,
  ) => {
      sendRoute(
        origin!,
        destination!,
        routes,
        stops,
        routeName,
        routeCost,
        accountId,
        routeRangeToleranceLimit,
        avoidToll,
        areas,
        vehicle,
      );
    };
    
  const navigate = useNavigate();

  return (
    <ApplicationLayout.Sidebar className="right">
      <Sidebar
        title="Rota"
        closed={false}
        onClose={() => { navigate("/routing/routes") }}
        position={Sidebar.RIGHT}
        disableClose={false}
        width={500}
      >
        <div className="padding-left-20 padding-top-20 padding-right-20 padding-bottom-20">
          <RegisterRouter
            onChangeRoutes={handleRoutes}
            onSave={handleSave}
            saveLabel="Editar rota"
            // dataUpdateRoute={dataUpdateRoute}
            // setDataUpdateRoute={setDataUpdateRoute}
          />
          <>
            {/* <div className="btn-toolbar">
              <Button
                onClick={() =>
                  origin && destination
                    ? sendRoute(origin, destination, routes)
                    : null
                }
                block
              >
                Salvar rota e criar viagem
              </Button>
            </div> */}
          </>
        </div>
      </Sidebar>
    </ApplicationLayout.Sidebar>
  );
};

export default UpdateSidebarRoute;
