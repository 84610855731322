import { useContext, useEffect, useState } from 'react';

import {
    UserRepository
} from '@amityco/ts-sdk';
import { getListCourses } from 'src/services/social';
import { ISocialProfileDetails } from '@types';


export function useSocialProfileUser() {

  const [userProfile, setUserProfile] = useState<Amity.User|undefined>();
  const [ data, setData ] = useState<ISocialProfileDetails|undefined>();

  const refetch = async (userId: string|undefined) => {
    try {
      setUserProfile(undefined);
      setData(undefined);

      handleUserDetail([userId!]);

      const response = await getListCourses(userId!);
      const data = await fillDriversInfo(response?.data?.result as ISocialProfileDetails);
      setData(data);
    } catch(e) {
      console.log(e)
    }
  };


  const fillDriversInfo = async (data: ISocialProfileDetails): Promise<ISocialProfileDetails> => {
    const evaluatorsIdList = data?.evaluations?.map(it => it.evaluatorDriverId);
    if (evaluatorsIdList && evaluatorsIdList.length) {
      try {
        const response = await UserRepository.getUserByIds(evaluatorsIdList);
        data?.evaluations?.forEach(evaluation => {
          const driver = response?.data?.find(driver => driver.userId == evaluation.evaluatorDriverId);
          evaluation.evaluatorName = driver?.metadata?.displayName 
            || [driver?.metadata?.givenName, driver?.metadata?.familyName].filter(s => s).join(' ');
          evaluation.evaluatorImage = driver?.metadata?.imageProfile?.fileUrl;
        });
      } catch(e) {
        console.log('error while fetching evaluators image and name')
      }
    }
    return data;
  }

  const handleUserDetail = async (ids: Array<string>) => {
    try {
      const response = await UserRepository.getUserByIds(ids);
      setUserProfile(response?.data ? response.data[0] : undefined)
    } catch(e) {
      console.log(e);
    }
  };

  return {
    userProfile,
    data,
    refetch,
    handleUserDetail,
  };

};
