import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import "./CustomInputSelect.css";

type Option = {
    value: string | number;
    label: string;
};

type CustomInputSelectProps = {
    value?: string | number;
    options: Option[];
    onChange?: (newValue: string | number) => void;
    disabled?: boolean;
    placeholder?: string;
    className?: string;
};

const CustomInputSelect: React.FC<CustomInputSelectProps> = ({
                                                                 value = '',
                                                                 options,
                                                                 onChange,
                                                                 disabled = false,
                                                                 placeholder = 'Selecione uma opção',
                                                                 className = '',
                                                             }) => {


    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = event.target.value;

        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <div className={`custom-input-select ${className}`}>
            <select
                value={value}
                onChange={handleChange}
                disabled={disabled}
                className="select-input"
            >
                <option value="" disabled>
                    {placeholder}
                </option>
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <div className="select-icon"><span className="expander-icon rioglyph rioglyph-chevron-down"></span></div>
        </div>
    );
};


export default CustomInputSelect;
