import { Client } from "@amityco/ts-sdk";
import api from ".";

export const getFollowers = async (userId?: string) => {
  const response = await api.get(`v1/social/followers/${userId}`, {
    headers: { token_amity: Client.getActiveClient().token?.accessToken },
  });
  return response.data;
};

export const getFollowings = async (userId?: string) => {
  const response = await api.get(`v1/social/followings/${userId}`, {
    headers: { token_amity: Client.getActiveClient().token?.accessToken },
  });
  return response.data;
};

export const getListCourses = async (id: string) => {
  const response = await api.get(`v2/profile/details/${id}`, {
    headers: { token_amity: Client.getActiveClient().token?.accessToken },
  });
  return response.data;
};