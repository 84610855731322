import { ISendTripRequest, IDeletedResquest} from "@types";
import api from ".";

export const fetchTripsList = async (page: number, size: number, search?: string, sortB?: string, fieldSort?: string, filterByType?: string,
  options?: { signal?: AbortSignal }) => {
    const params = new URLSearchParams();
    params.append("size", size.toString());
    params.append("page", page.toString());
  
    const names = {
      name: 'driverName',
      origin: 'originName',
      destination: 'destinationName',
      stops: 'quantityStops',
      distance: 'distance',
      duration: 'duration',
      vehicle: 'assetName'
    }
    
    if (search) {
      params.append("search", search);
    }
  
    if (sortB) {
      params.append('order', sortB.toUpperCase());
    }
  
    if (fieldSort && fieldSort in names) {
      params.append('orderBy', names[fieldSort as keyof typeof names]);
    }

    switch (filterByType != undefined && filterByType.toUpperCase()) {
      case "ALL":
        break;
      case "ACCEPTED":
        params.append("status", "SCHEDULED");
        params.append("statusAccept", "ACCEPTED");
        break;
      case "PROPOSED":
        params.append("status", "PROPOSED");
        params.append("statusAccept", "PROPOSED");
        break;
      case "PENDING":
        params.append("status", "SCHEDULED");
        params.append("statusAccept", "PENDING");
        break;
      case "REFUSED":
        params.append("status", "FINISHED");
        params.append("statusAccept", "REFUSED");
        break;
      case "CANCELED":
        params.append("status", "FINISHED");
        params.append("statusAccept", "CANCELED");
        break;
      default:
        break;
  }

    const response = await api.get(`v2/routes/trips?${params.toString()}`, options);
    return response.data;
  };

  export const fetchTripsListOnGoing = async (page: number, size: number, search?: string, sortB?: string, fieldSort?: string, statusFilter?: string,
    options?: { signal?: AbortSignal }) => {
    const params = new URLSearchParams();
    params.append("size", size.toString());
    params.append("page", page.toString());
  
    const names = {
      name: "drivername",
      dateStarted: "datestarted",
      status: "",
      statusTrip: "",
      progress: "progress",
      duration: "eta",
      destination: "destination",
      vehicle: "vehicle",
    };
    
    if (search) {
      params.append("search", search);
    }
  
    if (sortB) {
      params.append('order', sortB.toUpperCase());
    }
  
    if (fieldSort && fieldSort in names) {
      params.append('orderBy', names[fieldSort as keyof typeof names]);
    }

    if (statusFilter && statusFilter.trim() !== "") {
      if (statusFilter.toUpperCase() === "STARTED") {
        params.append("statusFilter", "ONROUTE,ESTIMATED");
      } else {
        params.append("statusFilter", statusFilter);
      }
    }

    const response = await api.get(`v2/routes/started-status?${params.toString()}`, options);
    return response.data;
  };


export const sendTrip = async (body: ISendTripRequest, routeId: string) => {
  const response = await api.post(`v2/routes/rio/send/${routeId}`, body);
  return response.data;
};

export const fetchCanceledTrip = async (body: IDeletedResquest, id: string) => {
  const response = await api.put(`v2/routes/trips/${id}`, body);
  return response.data;
};

export const sendStatusSchulingTrip = async (body: {tripId: string, status: string}) => {
  const response = await api.post(`v2/routes/trips/trip-date-decision`, body);
  return response.data;
};

