import * as St from "./styles";

interface Props {
  borderColor: string;
  label: string;
  value: string | number;
  onClick?: () => void;
  isActive?: boolean;
}

const CardCount = ({ borderColor, label, value, onClick, isActive }: Props) => {
  return (
    <div
      className={`bg-white shadow-muted-down width-100pct height-100 display-flex align-items-center border border-width-2 border-style-solid border-color-transparent ${
        onClick ? "hover-bg-highlight-lightest cursor-pointer" : ""
      } ${isActive ? "bg-highlight-lightest border-color-primary" : ""} `}
      onClick={onClick}
    >
      <div
        className="width-100pct display-flex align-items-center jusfity-content-space-between padding-left-20 padding-right-20"
        style={{ borderLeft: `8px solid ${borderColor}` }}
      >
        <div className="width-100pct">
          <span className="text-color-dark text-size-20 text-normal">{label}</span>
        </div>
        <div>
          <span className="text-color-primary text-medium" style={{ fontSize: 40 }}>
            {value}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CardCount;

