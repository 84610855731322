import { useContext, useEffect, useRef, useState } from "react";
import { useDebounce } from "src/hooks/useDebounce";
import { ApplicationLayout, Notification, Sidebar } from "@rio-cloud/rio-uikit";
import { SidebarDetail, TableOnGoingTrip } from "@components";
import { fetchTripsListOnGoing } from "src/services/trips";
import { getFollowers } from "src/services/social";
import { Client } from "@amityco/ts-sdk";
import { useAppSelector } from "@store";
import RioMap, {
  Coordinates,
  getBoundingBox,
  getRandomValueToForceRerender,
  RoutePoint,
  SandboxMarker
} from "src/components/Map";
import CardCount from "src/components/CardCount";
import { fetchDetail, fetchUrltripShare } from "src/services/routes";
import { Result, Route } from "src/types/routes";
import { IOnGoingTrip } from "@types";
import { decode } from "src/utils/flexiblePolyline";
import styled from 'styled-components';
import SmoothScrollbars from '@rio-cloud/rio-uikit/components/smoothScrollbars/SmoothScrollbars';
import { WebSocketContext } from "src/providers/WebsocketProvider";
import { mountPolyline } from "@utils";

const positionDefault = {
  lat: -23.5475,
  lng: -46.63611,
}

const Container = styled.div`
  background-color: #e5e9f2;
  display: flex;
  flex-direction: column;
  color: #333;
  padding: 20px;
  box-sizing: border-box;
  gap: 24px;
`;

const OnGoingTripsScreen = () => {
  const pageRef: React.LegacyRef<any> = useRef(null);
  const profile = useAppSelector((state) => state.login.userProfile);
  const [routes, setRoutes] = useState<Array<IOnGoingTrip>>([]);
  const [followers, setFollowers] = useState([]);
  const [usersAmity, setUsersAmity] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState("desc");
  const [fieldSort, setFieldSort] = useState("dateStarted");
  const [selected, setSelected] = useState<IOnGoingTrip | null>();
  const [details, setDetails] = useState<Result | null>();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const ctx = useContext(WebSocketContext);

  const [dashboard, setDashboard] = useState({
    ongoingRoute: 0,
    outOfRoute: 0,
    overdueRoute: 0,
    earlyRoute: 0,
    notStarted: 0,
  });
  const [filterByType, setFilterByType] = useState<string>();

  const [position, setPosition] = useState<Coordinates | undefined>(positionDefault);
  const [boundingBox, setBoundingBox] = useState<
    { top: number; left: number; right: number; bottom: number } | undefined
  >();
  const [segments, setSegments] = useState<RoutePoint[]>([]);
  const [markers, setMarkers] = useState<SandboxMarker[]>([]);
  const [view, setView] = useState<'DEFAULT' | 'DETAILS'>('DEFAULT');
  const [closed, setClosed] = useState(true);
  const [stops, setStops] = useState<Array<{ note: string, label: string }>>([]);
  const [currencySegments, setCurrencySegments] = useState<RoutePoint[]>([]);
  const [updatedLenght, setUpdatedLengh] = useState(0);
  const [statusTripNow, setStatusTripNow] = useState('');
  const [statusRouteNow, setStatusRouteNow] = useState('');
  const [progressNow, setProgressNow] = useState<string>();
  const [markerNow, setMarkerNow] = useState<SandboxMarker>()
  const debouncedSearch = useDebounce(searchValue, 400);

  const initialData = async (signal?: AbortSignal, search = debouncedSearch) => {
    try {
      const res = await fetchTripsListOnGoing(page - 1, 10, search, sort, fieldSort, filterByType, { signal });
      if (res.data.result) {
        setRoutes(res.data?.result?.routes);
        setPages(res.data.result.pages === 0 ? 1 : res.data.result.pages);
        setDashboard({
          ongoingRoute: res.data.result.startedRoute,
          outOfRoute: res.data.result.outOfRoute,
          overdueRoute: res.data.result.overdueRote,
          earlyRoute: res.data.result.earlyRoute,
          notStarted: res.data.result.notStartedRoute,
        })
      }

    } catch (error) {
      if ((error as any).name !== "CanceledError") 
        Notification.error("Não foi possível buscar as rotas.");
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    initialData(signal);

    const interval = setInterval(() => {
      initialData(signal);
      return () => {
        controller.abort();
      };
    }, 60000);

    return () => {
      controller.abort();
      clearInterval(interval);
    };

  }, [page, debouncedSearch, sort, fieldSort, filterByType]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    initialData(signal);
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, debouncedSearch, sort, filterByType]);

  const fetchFollowerAndFollowing = async () => {
    try {
      const resFollowers = await getFollowers(profile?.userId);

      setFollowers(resFollowers.data.result.follows);
      setUsersAmity(resFollowers.data.result.users);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFollowerAndFollowing();
  }, [Client.getActiveClient().token?.accessToken]);

  const fetchRouteDetails = async (type?: string) => {
    try {
      const response = await fetchDetail(selected?.routeId as string);
      let stps: Array<{ note: string, label: string }> = [];
      const stops = response.data.result.stops.map((stop) => {
        stps.push({
          note: stop.stop.note,
          label: stop.stop.position.address.label
        })
        return {
          id: stop.id,
          category: stop.stop.category,
          order: stop.stopQueueOrder,
          stayTime: (stop.stayTime as string).split("T")[1].substring(0, 5),
          position: {
            lat: stop.stop.position.lat,
            lng: stop.stop.position.long,
            label: stop.stop.position.address.label,
          },
        };
      });

      setDetails(response.data.result);
      setStops(stps);
      handleRoutes(response.data.result.responseHere.routes, stops);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selected?.routeId) {
      fetchRouteDetails();
    }
  }, [selected])

  const handleRoutes = (routes: Route[], via: any[]) => {
    if (routes.length) {
      let ArrayOfShape: any[] = [];
      const route = routes?.[0];
      const data = route?.sections || [];

      const getPolylineArray = data.map((item) => {
        return {
          shape: decode(item.polyline),
        };
      });

      const markers = data.reduce((acum: SandboxMarker[], curr, index, arr) => {
        if (index === 0) {
          const markerOrigin: SandboxMarker = {
            id: getRandomValueToForceRerender().toString(),
            markerProps: {
              iconNames: ["start"],
              name: "",
              markerColor: "bg-map-marker-route",
              active: false,
              fixed: false,
            },
            position: {
              lat: curr.departure.place.location.lat,
              lng: curr.departure.place.location.lng,
            },
          };
          acum.push(markerOrigin);
        }
        if (index === arr.length - 1) {
          const markerDestination: SandboxMarker = {
            id: getRandomValueToForceRerender().toString(),
            markerProps: {
              iconNames: ["finish"],
              name: "",
              markerColor: "bg-map-marker-route",
              active: false,
              fixed: false,
            },
            position: {
              lat: curr.arrival.place.location.lat,
              lng: curr.arrival.place.location.lng,
            },
          };
          acum.push(markerDestination);
        } else {
          const markerStopover: SandboxMarker = {
            id: getRandomValueToForceRerender().toString(),
            markerProps: {
              iconNames: ["rioglyph rioglyph-pushpin"],
              name: via?.[index]?.type,
              markerColor: "bg-map-marker-route",
              active: false,
              fixed: false,
            },
            position: {
              lat: curr.arrival.place.location.lat,
              lng: curr.arrival.place.location.lng,
            },
          };
          acum.push(markerStopover);
        }

        return acum;
      }, []);

      getPolylineArray.forEach((item) => {
        ArrayOfShape.push(item.shape.polyline);
      });

      const concatArrays = ArrayOfShape.flat();

      const getPoints = concatArrays.map((value) => {
        return {
          lat: value[0].toString(),
          lng: value[1].toString(),
        };
      });

      setSegments(getPoints as RoutePoint[]);
      setMarkers(markers);

      if (getPoints?.length > 0) {
        setPosition(undefined);
        const values = getBoundingBox(getPoints);
        const paddedValues = {
          top: values.top + 0.4,
          bottom: values.bottom - 0.1,
          left: values.left - 0.4,
          right: values.right + 0.4,
        };

        setBoundingBox(paddedValues);
      } else if (markers?.length > 0) {
        setPosition(markers[0].position);
      }
    } else {
      setMarkers([]);
      setSegments([]);
    }
  };

  const handleClear = () => {
    setSelected(null);
    setDetails(null);
    setMarkers([]);
    setSegments([]);
    setCurrencySegments([]);
    setPosition(positionDefault);
  }

  const handleSelect = (select: IOnGoingTrip) => {
    if (select.routeId !== selected?.routeId) {
      setSelected(select);

      if (select.polyline?.length) {
        const mounted = mountPolyline(select.polyline);
        setCurrencySegments(mounted.segments);
        setMarkerNow(mounted.markerDestiny);
      };

      ctx?.sendMessage(select.tripId);
      ctx?.handleSaveTrip(select.tripId);
    } else {
      handleClear();
    }
  }

  const handleSelectFilterByType = (type: string) => {
    setPage(1);
    handleClear();
    setFilterByType(prev => prev === type ? '' : type);
  }

  const handleDetails = (select: IOnGoingTrip) => {
    if (select.polyline?.length) {
      const mounted = mountPolyline(select.polyline);
      setCurrencySegments(mounted.segments);
      setMarkerNow(mounted.markerDestiny);
    };

    setSelected(select);
    ctx?.sendMessage(select.tripId);
    ctx?.handleSaveTrip(select.tripId);
    setView('DETAILS');
    setClosed(false);
  }

  const handleShare = async (id: string) => {
    try {
      const response = await fetchUrltripShare(id);
      window.open(`https://latam-routing.rio.cloud/route/view?link=${response.data.result}`, '_blank');
      console.log(response)
    } catch (error) {

    }
  }

  const CurrencyTripSelected = async () => {
    const response = await fetchTripsListOnGoing(page - 1, 10, searchValue, sort, fieldSort, filterByType);
    if (response.data.result.routes) {
      const find = response.data.result.routes.find((res: { tripId: string }) => res.tripId === selected?.tripId);
      if (find.tripId) {
        setStatusRouteNow(find?.routeStatusNow);
        setStatusTripNow(find?.tripStatusNow);
        setProgressNow(find?.actualProgress);

        if (find?.polyline.length > 1) {
          const mounted = mountPolyline(find.polyline as string[]);
          setCurrencySegments(mounted.segments);
          setMarkerNow(mounted.markerDestiny);
        }
      }
    }
  }

  useEffect(() => {
    console.log('SOCKET:', ctx?.lastMessage);
    if (ctx?.lastMessage?.data && ctx?.lastMessage?.data.includes('TRIP_LOCATION_UPDATE')) {
      setUpdatedLengh(updatedLenght + 1);
    }
  }, [ctx?.lastMessage])

  useEffect(() => {
    if (updatedLenght > 0 && selected) {
      CurrencyTripSelected();
    }
  }, [updatedLenght])

  return (
    <>
      {
        view === 'DEFAULT' ? (
          <SmoothScrollbars slideIn largeTrack trackOffset ref={pageRef}>
            <ApplicationLayout.Body innerClassName="padding-0" scrollResetTrigger={{}}>
              <div style={{ height: 400, marginBottom: 30 }}>
                <RioMap
                  position={position}
                  boundingBox={boundingBox}
                  apiKey={process.env.REACT_APP_RIO_MAP_KEY as string}
                  segments={
                    segments.length ? [{ points: segments, alternative: false }] : []
                  }
                  currencySegments={
                    currencySegments.length ? [{ points: currencySegments, alternative: false }] : []
                  }
                  markers={markerNow?.position?.lat ? [...markers, markerNow] : markers}
                />
              </div>
              <Container>
                <div style={{ display: "flex", gap: 20, marginBottom: 30 }}>
                  <CardCount
                    isActive={filterByType === 'STARTED'}
                    onClick={() => handleSelectFilterByType('STARTED')}
                    borderColor="#30B4C0"
                    label="VIAGENS EM ANDAMENTO"
                    value={dashboard.ongoingRoute}
                  />

                  <CardCount
                    isActive={filterByType === 'OUTROUTE'}
                    onClick={() => handleSelectFilterByType('OUTROUTE')}
                    borderColor="#D90000"
                    label="FORA DE ROTA"
                    value={dashboard.outOfRoute}
                  />

                  <CardCount
                    isActive={filterByType === 'OVERDUE'}
                    onClick={() => handleSelectFilterByType('OVERDUE')}
                    borderColor="#D90000"
                    label="ATRASADAS"
                    value={dashboard.overdueRoute}
                  />

                  <CardCount
                    isActive={filterByType === 'EARLY'}
                    onClick={() => handleSelectFilterByType('EARLY')}
                    borderColor="#FF8E3C"
                    label="ADIANTADAS"
                    value={dashboard.earlyRoute}
                  />

                  <CardCount
                    isActive={filterByType === 'NOTSTARTED'}
                    onClick={() => handleSelectFilterByType('NOTSTARTED')}
                    borderColor="#697A8B"
                    label="NÃO INICIADAS"
                    value={dashboard.notStarted}
                  />
                </div>

                <TableOnGoingTrip
                  handleDetails={handleDetails}
                  selected={selected?.routeId}
                  handleSelect={handleSelect}
                  usersAmity={usersAmity}
                  followers={followers}
                  pages={pages}
                  handleSort={(dir, field) => {
                    setSort(dir);
                    setFieldSort(field);
                  }}
                  routes={routes}
                  viewType="TABLE"
                  page={page}
                  handleSearchValueChange={(text) => setSearchValue(text)}
                  searchValue={searchValue}
                  handlePage={(page) => {
                    setPage(page);
                  }}
                />
              </Container>
            </ApplicationLayout.Body>
          </SmoothScrollbars>
        ) : (
          <>
            <ApplicationLayout.Sidebar className="right">
              <Sidebar
                title="Detalhes da viagem"
                closed={closed}
                onClose={() => {
                  setClosed(true);
                  setView('DEFAULT');
                }}
                position={Sidebar.RIGHT}
                width={500}
              >
                <div className="padding-left-20 padding-top-20 padding-right-20 padding-bottom-20">
                  <SidebarDetail
                    detail={selected}
                    origin={details?.originRoute}
                    destiny={details?.destinyRoute}
                    statusTripNow={statusTripNow}
                    statusRouteNow={statusRouteNow}
                    progressNow={progressNow}
                    stops={stops}
                    usersAmity={usersAmity}
                    followers={followers}
                    handleShare={handleShare}
                  />
                </div>
              </Sidebar>
            </ApplicationLayout.Sidebar>


            <div style={{ width: '100%', height: '100%' }}>
              <ApplicationLayout.Body innerClassName="padding-0">
                <RioMap
                  position={position}
                  boundingBox={boundingBox}
                  apiKey={process.env.REACT_APP_RIO_MAP_KEY as string}
                  segments={
                    segments.length ? [{ points: segments, alternative: false }] : []
                  }
                  currencySegments={
                    currencySegments.length ? [{ points: currencySegments, alternative: false }] : []
                  }
                  markers={markerNow?.position?.lat ? [...markers, markerNow] : markers}
                />
              </ApplicationLayout.Body>
            </div>
          </>
        )
      }
    </>
  );
};

export default OnGoingTripsScreen;
