import { useState } from "react";
import omit from "lodash/fp/omit";
import isEmpty from "lodash/fp/isEmpty";
import classNames from "classnames";

import TableToolbar from "@rio-cloud/rio-uikit/TableToolbar";
import TableViewToggles, {
  type TableViewTogglesViewType,
} from "@rio-cloud/rio-uikit/TableViewToggles";
import TableSearch from "@rio-cloud/rio-uikit/TableSearch";
import TableCardsSorting from "@rio-cloud/rio-uikit/TableCardsSorting";
import TableSettingsDialog from "@rio-cloud/rio-uikit/TableSettingsDialog";
import SortDirection, {
  type SortDirectionType,
} from "@rio-cloud/rio-uikit/SortDirection";
import SortArrows from "@rio-cloud/rio-uikit/SortArrows";
import ButtonDropdown from "@rio-cloud/rio-uikit/ButtonDropdown";
import NotFoundState from "@rio-cloud/rio-uikit/NotFoundState";
import Button from "@rio-cloud/rio-uikit/Button";
import { SocialUser, Trip } from "@types";
import Chip from "../Chip";
import { formatDateBr, kmToMeters } from "@utils";
import ChipStatus from "../ChipStatus";
import Paginator from "../Paginator";

const defaultColumnOrder = [
  "name",
  "scheduling",
  "status",
  "tripStatus",
  "origin",
  "destination",
  "stops",
  "distance",
  "duration",
  "vehicle",
];

type ColumnLabel = {
  [key: string]: string;
};
const columnLabels: ColumnLabel = {
  name: "Motorista",
  status: "Aceite",
  tripStatus: "Status",
  scheduling: "Agendamentos",
  origin: "Origem",
  destination: "Destino",
  stops: "Paradas",
  distance: "Distância",
  duration: "Duração",
  vehicle: "Veículo",
};

type ColumnDetails = {
  [key: string]: number;
};

type ColumnDetailsMap = {
  [key: string]: ColumnDetails;
};

const demoColumnsDetails: ColumnDetailsMap = {
  name: {
    width: 260,
    defaultWidth: 260,
    maxWidth: 260,
  },
  // origin: {
  //   width: 200,
  //   defaultWidth: 200,
  //   maxWidth: 200,
  // },
  // destination: {
  //   width: 200,
  //   defaultWidth: 200,
  //   maxWidth: 200,
  // },
  // vehicle: {
  //   width: 260,
  //   defaultWidth: 260,
  //   maxWidth: 260,
  // },
};

const getSortDir = (
  sortDir: SortDirectionType,
  sortBy: string,
  previousSortBy: string
) => {
  if (sortBy === previousSortBy) {
    return sortDir === SortDirection.ASCENDING
      ? SortDirection.DESCENDING
      : SortDirection.ASCENDING;
  }
  return SortDirection.ASCENDING;
};

export type Props = {
  viewType: TableViewTogglesViewType;
  pages: number;
  routes: Trip[];
  searchValue: string;
  page: number;
  followers?: Array<SocialUser>;
  usersAmity?: Amity.User[];
  handleSearchValueChange: (text: string) => void;
  handleDownload: () => void;
  handleCreateRoute: () => void;
  handleUpdateRoute: (id: string) => void;
  handlePage: (page: number) => void;
  handleSort: (dir: string, newSort: string) => void;
  handleFilter?: () => void;
  handleCancel: (id?: string) => void;
  handleScheduleAccept: (id: string) => void;
  handleScheduleRefused: (id: string) => void;
};

const TableTrips = (props: Props) => {
  const { viewType: externalViewType } = props;
  const [sortBy, setSortBy] = useState("name");
  const [sortDir, setSortDir] = useState<SortDirectionType>(
    SortDirection.ASCENDING
  );
  const [showTableSettingsDialog, setShowTableSettingsDialog] = useState(false);
  const [columnOrder, setColumnOrder] = useState<string[]>(defaultColumnOrder);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [columnsDetails, setColumnsDetails] = useState(demoColumnsDetails);
  const [viewType, setViewType] = useState(
    externalViewType || TableViewToggles.VIEW_TYPE_TABLE
  );

  const handleToggleTableSettingsDialog = () =>
    setShowTableSettingsDialog(!showTableSettingsDialog);
  const handleViewTypeChange = (newViewType: TableViewTogglesViewType) =>
    setViewType(newViewType);

  const handleColumnChange = (
    newColumnOrder: string[],
    newHiddenColumns: string[],
    newColumnsDetails = columnsDetails
  ) => {
    setColumnOrder(newColumnOrder);
    setHiddenColumns(newHiddenColumns);
    setColumnsDetails(newColumnsDetails);
  };

  // For immediate effect
  const handleColumnDetailsChange = (
    column: string,
    newColumnDetails: ColumnDetails
  ) => {
    const updatedColumnsDetails = { ...columnsDetails };
    updatedColumnsDetails[column] = newColumnDetails;
    setColumnsDetails(updatedColumnsDetails);
  };

  const handleSortChange = (event: React.MouseEvent<HTMLElement>) => {
    const newSortBy = event.currentTarget.getAttribute("data-sortby");
    if (newSortBy) {
      handleCardSortChange(newSortBy, getSortDir(sortDir, newSortBy, sortBy));
      props.handleSort(sortDir, newSortBy);
    }
  };

  const handleCardSortChange = (
    newSortBy: string,
    newSortDir: SortDirectionType
  ) => {
    setSortBy(newSortBy);
    setSortDir(newSortDir);
  };

  // May be extracted as a dedicated component but for demo purpose it's shown here
  const renderTableHead = (
    column: string,
    label: string,
    sortByColumn: string,
    sortDirection: SortDirectionType
  ) => {
    const tableHeadClassNames = classNames("user-select-none", "sort-column");

    return (
      <th
        key={column}
        className={tableHeadClassNames}
        onClick={handleSortChange}
        data-field={column}
        data-sortby={column}
        title={label}
      >
        <span>
          {sortByColumn === column ? (
            <SortArrows direction={sortDirection} />
          ) : (
            <SortArrows />
          )}
          <span>{label}</span>
        </span>
      </th>
    );
  };

  const renderTableCaption = (column: string, columnDetails: ColumnDetails) => {
    const style = columnDetails?.width
      ? {
          minWidth: columnDetails.width,
          width: columnDetails.width,
        }
      : {};

    return <col key={column} style={style} />;
  };

  // filter for hidden columns
  const columns = columnOrder.filter((name) => !hiddenColumns.includes(name));

  // filter data to omit hidden columns
  const withoutHidden = omit(hiddenColumns);
  const filteredRows = props.routes.map((vehicle) => ({
    ...withoutHidden(vehicle),
  })) as Trip[];

  // in case a search value is given, filter the data accordingly
  const searchResult = filteredRows;

  // Sort rows according to the sortBy and sortDir settings
  const rows = searchResult;

  const tableClassNames = classNames(
    "table",
    "table-layout-fixed",
    "table-column-overflow-hidden",
    "table-bordered",
    "table-sticky",
    "table-head-filled",
    viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD &&
      "table-cards table-single-card",
    viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS &&
      "table-cards table-multi-cards"
  );

  const isViewTypeTable = viewType === TableViewToggles.VIEW_TYPE_TABLE;

  const cardSortingSelectOptions = columns.map((column) => {
    return {
      id: column,
      label: columnLabels[column],
      selected: column === sortBy,
      disabled: false,
    };
  });

  const renderItems = (id?: string) => {
    const items = [
      // {
      //   value: (
      //     <div>
      //       <span className="rioglyph rioglyph-map-location margin-right-10 text-color-primary" />
      //       <span>Abrir rota do mapa</span>
      //     </div>
      //   ),
      // },
      // {
      //   value: (
      //     <div>
      //       <span className="rioglyph rioglyph-map-location margin-right-10 text-color-primary" />
      //       <span>Alterar data e hora</span>
      //     </div>
      //   ),
      // },
      // {
      //   value: (
      //     <div>
      //       <span className="rioglyph rioglyph-calendar margin-right-10 text-color-primary" />
      //       <span>Trocar motorista</span>
      //     </div>
      //   ),
      // },
      // {
      //   value: (
      //     <div>
      //       <span className="rioglyph rioglyph-cost-efficency margin-right-10 text-color-primary" />
      //       <span>Vincular veículo</span>
      //     </div>
      //   ),
      // },
      // {
      //   value: (
      //     <div>
      //       <span className="rioglyph rioglyph-cost-efficency margin-right-10 text-color-primary" />
      //       <span>Autonomia do veículo</span>
      //     </div>
      //   ),
      // },
      // {
      //   value: (
      //     <div>
      //       <span className="rioglyph rioglyph-cost-efficency margin-right-10 text-color-primary" />
      //       <span>Carga</span>
      //     </div>
      //   ),
      // },
      {
        value: (
          <div onClick={() => props?.handleCancel(id)}>
            <span className="rioglyph rioglyph-trash margin-right-10 text-color-primary" />
            <span>Cancelar viagem</span>
          </div>
        ),
      },
    ];
    return items;
  };

  const renderChip = (driverId: string) => {
    const follower = props.followers?.filter((res) => res.from === driverId);

    return follower?.length ? (
      <Chip
        text="Conectado"
        icon="rioglyph rioglyph-user-group"
        background="bg-status-driving"
      />
    ) : (
      <Chip
        text="Minha equipe"
        icon="rioglyph rioglyph-role-management"
        background="bg-status-working"
      />
    );
  };

  return (
    <div id="table-trips">
      <TableToolbar>
        <div className="table-toolbar-container">
          <div className="table-toolbar-group-left">
            <div className="table-toolbar-column">
              <div className="btn-toolbar table-btn-toolbar">
                <Button
                  bsStyle={Button.PRIMARY}
                  iconName="rioglyph-plus"
                  onClick={props.handleCreateRoute}
                >
                  Agendar viagem
                </Button>
                <TableSearch
                  value={props.searchValue}
                  onChange={props.handleSearchValueChange}
                  placeholder="Buscar em viagens"
                />
              </div>
            </div>
          </div>
          <div className="table-toolbar-group-right">
            {/* <div className="table-toolbar-column table-toolbar-column-spacer">
              <button
                type="button"
                className="btn btn-default btn-icon-only"
                onClick={props.handleDownload}
              >
                <span
                  className="rioglyph rioglyph-download"
                  aria-hidden="true"
                ></span>
              </button>
            </div> */}

            <div className="table-toolbar-column table-toolbar-column-spacer">
              <TableViewToggles
                initialViewType={viewType}
                onViewTypeChange={handleViewTypeChange}
                tableViewTooltipContent="Table view"
                singleCardViewTooltipContent="List view"
                multiCardsViewTooltipContent="Cards view"
              />
            </div>

            {/* <div className="table-toolbar-column">
              <button type="button" className="btn btn-default">
                <span
                  onClick={props.handleFilter}
                  className="rioglyph rioglyph-filter text-size-20"
                  aria-hidden="true"
                ></span>
                Filtros
              </button>
            </div> */}
          </div>
          {showTableSettingsDialog && (
            <TableSettingsDialog
              show={showTableSettingsDialog}
              title="Table settings"
              onHide={handleToggleTableSettingsDialog}
              onColumnChange={handleColumnChange}
              defaultColumnOrder={defaultColumnOrder}
              defaultHiddenColumns={[]}
              columnOrder={columnOrder}
              hiddenColumns={hiddenColumns}
              columnLabels={columnLabels}
              disabledColumns={[]}
              closeButtonText="Close"
              resetButtonText="Reset to default"
              searchPlaceholder="Search by column name"
              notFoundMessage="No column found for this search value"
              columnsDetails={columnsDetails}
              autoLabel="Auto"
              onColumnDetailsChange={handleColumnDetailsChange}
              onSearchChange={(val: string) => console.log(val)}
              immediateChange
            />
          )}
        </div>
      </TableToolbar>
      {sortBy && !isViewTypeTable && (
        <TableCardsSorting
          selectOptions={cardSortingSelectOptions}
          sortName={sortBy}
          sortOrder={sortDir}
          onSortChange={handleCardSortChange}
        />
      )}
      <div>
        {isEmpty(rows) && !isViewTypeTable && (
          <NotFoundState
            headline="Sem resultados"
            message="Por favor refaça a busca."
          />
        )}
        <table className={tableClassNames}>
          <colgroup>
            {columns.map((column) =>
              renderTableCaption(column, columnsDetails[column])
            )}
            <col className="table-action" />
          </colgroup>
          <thead>
            <tr>
              {columns.map((column) =>
                renderTableHead(column, columnLabels[column], sortBy, sortDir)
              )}
              <th className="table-action" />
            </tr>
          </thead>
          <tbody>
            {isEmpty(rows) && isViewTypeTable && (
              <tr>
                <td colSpan={columns.length + 1}>
                  <NotFoundState
                    outerClassName="border-none"
                    headline="Sem resultados"
                    message="Por favor refaça a busca."
                  />
                </td>
              </tr>
            )}

            {rows.map((row: Trip, index: number) => {
              const proposed = row?.route?.trips?.find((trip) => trip.id === row.id)?.proposedDate;
              return (
                <tr key={index}>
                  <td key={"Motorista"} data-field={"Motorista"}>
                    <div className="display-flex flex-direction-row align-items-center">
                      <div
                        className="width-30 aspect-ratio-1 bg-highlight padding-5 display-flex align-items-center justify-content-center rounded-circle bg-size-100pct bg-repeat-no bg-light"
                        style={{
                          backgroundImage: `url(${
                            props.usersAmity?.find(
                              (res) => row.driver === res.userId
                            )?.metadata?.imageProfile?.fileUrl ||
                            "https://cdn.rio.cloud/svg/common/ico_rio_colored.svg"
                          })`,
                        }}
                      />
                      <div className="padding-x-15">
                        <span className="text-bold text-size-16 text-capitalize">
                          {row.driverName}
                        </span>
                        <div className="margin-top-5">
                          {renderChip(row.driver)}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td key={"Agendamentos"} data-field={"Agendamentos"}>
                    {proposed ? (
                      <>
                        <div className="display-flex align-items-center margin-bottom-4">
                          <span className="text-thin text-size-14 text-color-rating-2">
                            Sugeriu um novo dia e hora:
                          </span>
                        </div>

                        <div className="display-flex align-items-center">
                          <span className="rioglyph rioglyph-calendar text-size-14 text-color-dark"></span>
                          <span
                            className="margin-left-10 text-size-12 text-normal text-decoration-line-through text-color-dark">
                            {formatDateBr(row.schedule.date)}
                          </span>
                        </div>
                        <div className="display-flex align-items-center margin-top-2">
                          <span className="rioglyph rioglyph-calendar text-size-16"></span>
                          <span className="margin-left-10 text-size-14 text-normal">
                            {formatDateBr(proposed)}
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className="display-flex align-items-center">
                        <span className="rioglyph rioglyph-calendar text-size-16"></span>
                        <span className="margin-left-10 text-size-14 text-normal">
                          {formatDateBr(row.schedule.date)}
                        </span>
                      </div>
                    )}
                  </td>
                  <td key={"Aceite"} data-field={"Aceite"}>
                    {proposed ? (
                      <div className="gap-4 display-flex">
                        <Button
                          className="btn-md"
                          bsStyle={Button.PRIMARY}
                          onClick={() => props.handleScheduleAccept(row.id)}
                        >
                          Aceitar
                        </Button>
                        <Button
                          className="btn-md"
                          onClick={() => props.handleScheduleRefused(row.id)}
                        >
                          Recusar
                        </Button>
                      </div>
                    ) : (
                      <ChipStatus status={row?.statusAcceptance}/>
                    )}
                  </td>
                  <td key={"Status Viagem"} data-field={"Status Viagem"}>
                    <ChipStatus status={row.status}/>
                  </td>
                  <td key={"Origem"} data-field={"Origem"}>
                    <span>{row.origin}</span>
                  </td>
                  <td key={"Destino"} data-field={"Destino"}>
                    <span>{row.destination}</span>
                  </td>
                  <td key={"Paradas"} data-field={"Paradas"}>
                    <span className="rioglyph rioglyph-pushpin margin-right-10 text-color-gray text-size-16"></span>
                    <span>{row.stops}</span>
                  </td>
                  <td key={"Distãncia"} data-field={"Distãncia"}>
                    <span className="rioglyph rioglyph-road margin-right-10 text-color-gray text-size-16"></span>
                    <span>{row.distance?.toLowerCase()?.replace(/\./g, ",")}</span>
                  </td>
                  <td key={"Duração"} data-field={"Duração"}>
                    <span className="rioglyph rioglyph-time margin-right-10 text-color-gray text-size-16"></span>
                    <span>{row.duration}</span>
                  </td>
                  <td key={"Veículo"} data-field={"Veículo"}>
                    <span className="rioglyph rioglyph-truck margin-right-10 text-color-gray text-size-16"></span>
                    <span>{row.vehicleName}</span>
                  </td>
                  <td className="table-action">
                    <span>
                      <ButtonDropdown
                        bsSize="md"
                        dropdownClassName="width-200"
                        title={
                          <span className="rioglyph rioglyph-option-vertical"/>
                        }
                        variant="link"
                        iconOnly
                        items={renderItems(row.id)}
                      />
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Paginator
        handlePage={number => props.handlePage(number)}
        page={props.page}
        pages={props.pages}
      />
    </div>
  );
};

export default TableTrips;
