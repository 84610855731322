import { useState } from "react";
import omit from "lodash/fp/omit";
import isEmpty from "lodash/fp/isEmpty";
import classNames from "classnames";

import TableToolbar from "@rio-cloud/rio-uikit/TableToolbar";
import TableViewToggles, {
  type TableViewTogglesViewType,
} from "@rio-cloud/rio-uikit/TableViewToggles";
import TableSearch from "@rio-cloud/rio-uikit/TableSearch";
import TableCardsSorting from "@rio-cloud/rio-uikit/TableCardsSorting";
import TableSettingsDialog from "@rio-cloud/rio-uikit/TableSettingsDialog";
import SortDirection, {
  type SortDirectionType,
} from "@rio-cloud/rio-uikit/SortDirection";
import SortArrows from "@rio-cloud/rio-uikit/SortArrows";
import ButtonDropdown from "@rio-cloud/rio-uikit/ButtonDropdown";
import NotFoundState from "@rio-cloud/rio-uikit/NotFoundState";
import { naturalSortByProperty } from "@rio-cloud/rio-uikit/SortUtils";
import Button from "@rio-cloud/rio-uikit/Button";
import { excludeRoute } from "src/services/routes";
import { Notification } from "@rio-cloud/rio-uikit";
import { convertSecondsToHours, convertTimeStringToSeconds, IStop } from "../RegisterRoute";
import { kmToMeters } from "@utils";
import Paginator from "../Paginator";

const defaultColumnOrder = [
  "name",
  "origin",
  "destination",
  "stops",
  "distance",
  "duration",
  "register",
  // "vwcoin",
  // "performance",
];

type ColumnLabel = {
  [key: string]: string;
};
const columnLabels: ColumnLabel = {
  name: "Nome",
  origin: "Origem",
  destination: "Destino",
  stops: "Paradas",
  distance: "Distância",
  duration: "Duração",
  register: "Cadastro",
  // vwcoin: "VWCoin",
  // performance: "Otimização",
};

type ColumnDetails = {
  [key: string]: number;
};

type ColumnDetailsMap = {
  [key: string]: ColumnDetails;
};

const demoColumnsDetails: ColumnDetailsMap = {
  name: {
    width: 200,
    defaultWidth: 200,
    maxWidth: 350,
  },
  origin: {
    width: 250,
    defaultWidth: 250,
    maxWidth: 350,
  },
  destination: {
    width: 250,
    defaultWidth: 250,
    maxWidth: 350,
  },
  stops: {
    width: 170,
    defaultWidth: 170,
    maxWidth: 200,
  },
  performance: {
    width: 278,
    defaultWidth: 278,
    maxWidth: 300,
  },
};

type Route = {
  id: string;
  name: string;
  origin: string;
  destination: string;
  stops: string;
  distance: string;
  duration: string;
  register: string;
  vwcoin: string;
  performance: string;
};

const getSortDir = (
  sortDir: SortDirectionType,
  sortBy: string,
  previousSortBy: string
) => {
  if (sortBy === previousSortBy) {
    return sortDir === SortDirection.ASCENDING
      ? SortDirection.DESCENDING
      : SortDirection.ASCENDING;
  }
  return SortDirection.ASCENDING;
};

export type Props = {
  viewType: TableViewTogglesViewType;
  pages: number;
  routes: Route[];
  searchValue: string;
  page: number;
  handleSearchValueChange: (text: string) => void;
  handleDownload: () => void;
  handleCreateRoute: () => void;
  handleUpdateRoute: (id: string) => void;
  handleDeleteRoute: (id: string) => void;
  handlePage: (page: number) => void;
  handleSort: (dir: string, newSort: string) => void;
  showHeader?: boolean;
};

const RoutesTable = (props: Props) => {
  const { viewType: externalViewType } = props;
  const [sortBy, setSortBy] = useState("name");
  const [sortDir, setSortDir] = useState<SortDirectionType>(
    SortDirection.ASCENDING
  );
  const [showTableSettingsDialog, setShowTableSettingsDialog] = useState(false);
  const [columnOrder, setColumnOrder] = useState<string[]>(defaultColumnOrder);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [columnsDetails, setColumnsDetails] = useState(demoColumnsDetails);
  const [viewType, setViewType] = useState(
    externalViewType || TableViewToggles.VIEW_TYPE_TABLE
  );

  const handleToggleTableSettingsDialog = () =>
    setShowTableSettingsDialog(!showTableSettingsDialog);
  const handleViewTypeChange = (newViewType: TableViewTogglesViewType) =>
    setViewType(newViewType);

  const handleColumnChange = (
    newColumnOrder: string[],
    newHiddenColumns: string[],
    newColumnsDetails = columnsDetails
  ) => {
    setColumnOrder(newColumnOrder);
    setHiddenColumns(newHiddenColumns);
    setColumnsDetails(newColumnsDetails);
  };

  // For immediate effect
  const handleColumnDetailsChange = (
    column: string,
    newColumnDetails: ColumnDetails
  ) => {
    const updatedColumnsDetails = { ...columnsDetails };
    updatedColumnsDetails[column] = newColumnDetails;
    setColumnsDetails(updatedColumnsDetails);
  };

  const handleSortChange = (event: React.MouseEvent<HTMLElement>) => {
    const newSortBy = event.currentTarget.getAttribute("data-sortby");
    if (newSortBy) {
      handleCardSortChange(newSortBy, getSortDir(sortDir, newSortBy, sortBy));
      props.handleSort(sortDir, newSortBy);
    }
  };

  const handleCardSortChange = (
    newSortBy: string,
    newSortDir: SortDirectionType
  ) => {
    setSortBy(newSortBy);
    setSortDir(newSortDir);
  };

  // May be extracted as a dedicated component but for demo purpose it's shown here
  const renderTableHead = (
    column: string,
    label: string,
    sortByColumn: string,
    sortDirection: SortDirectionType
  ) => {
    const tableHeadClassNames = classNames("user-select-none", "sort-column");

    return (
      <th
        key={column}
        className={tableHeadClassNames}
        onClick={handleSortChange}
        data-field={column}
        data-sortby={column}
        title={label}
      >
        <span>
          {sortByColumn === column ? (
            <SortArrows direction={sortDirection} />
          ) : (
            <SortArrows />
          )}
          <span>{label}</span>
        </span>
      </th>
    );
  };

  const renderTableCaption = (column: string, columnDetails: ColumnDetails) => {
    const style = columnDetails?.width
      ? {
        minWidth: columnDetails.width,
        width: columnDetails.width,
      }
      : {};

    return <col key={column} style={style} />;
  };

  // filter for hidden columns
  const columns = columnOrder.filter((name) => !hiddenColumns.includes(name));

  // filter data to omit hidden columns
  const withoutHidden = omit(hiddenColumns);
  const filteredRows = props.routes.map((vehicle) => ({
    ...withoutHidden(vehicle),
  })) as Route[];

  // in case a search value is given, filter the data accordingly
  const searchResult = filteredRows;

  // Sort rows according to the sortBy and sortDir settings
  const rows = searchResult;

  const tableClassNames = classNames(
    "table",
    "table-layout-fixed",
    "table-column-overflow-hidden",
    "table-bordered",
    "table-sticky",
    "table-head-filled",
    viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD &&
    "table-cards table-single-card",
    viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS &&
    "table-cards table-multi-cards"
  );

  const isViewTypeTable = viewType === TableViewToggles.VIEW_TYPE_TABLE;

  const cardSortingSelectOptions = columns.map((column) => {
    return {
      id: column,
      label: columnLabels[column],
      selected: column === sortBy,
      disabled: false,
    };
  });

  const icons = {
    id: "",
    name: "rioglyph rioglyph-route-option margin-right-10 text-color-gray text-size-20",
    origin:
      "rioglyph rioglyph-start margin-right-10 text-color-gray text-size-16",
    destination:
      "rioglyph rioglyph-finish margin-right-10 text-color-gray text-size-16",
    stops:
      "rioglyph rioglyph-pushpin margin-right-10 text-color-gray text-size-16",
    distance:
      "rioglyph rioglyph-road margin-right-10 text-color-gray text-size-16",
    duration:
      "rioglyph rioglyph-time margin-right-10 text-color-gray text-size-16",
    register: "",
    vwcoin:
      "rioglyph rioglyph-circle-stack margin-right-10 text-color-gray text-size-16",
    performance: "",
  };

  return (
    <div id="TableCommonDemo">
      {props.showHeader ? (
        <>
          <TableToolbar>
            <div className="table-toolbar-container">
              <div className="table-toolbar-group-left">
                <div className="table-toolbar-column">
                  <div className="btn-toolbar table-btn-toolbar">
                    <Button
                      bsStyle={Button.PRIMARY}
                      iconName="rioglyph-plus"
                      onClick={props.handleCreateRoute}
                    >
                      Criar rota
                    </Button>
                    {/*<Button>Cadastrar serviços</Button>*/}
                    <TableSearch
                      value={props.searchValue}
                      onChange={props.handleSearchValueChange}
                      placeholder="Buscar em rotas"
                    />
                  </div>
                </div>
              </div>
              <div className="table-toolbar-group-right">
                <div className="table-toolbar-column table-toolbar-column-spacer">
                  <button
                    type="button"
                    className="btn btn-default btn-icon-only"
                    onClick={props.handleDownload}
                  >
                    <span
                      className="rioglyph rioglyph-download"
                      aria-hidden="true"
                    ></span>
                  </button>
                </div>
                <div className="table-toolbar-column">
                  <TableViewToggles
                    initialViewType={viewType}
                    onViewTypeChange={handleViewTypeChange}
                    tableViewTooltipContent="Table view"
                    singleCardViewTooltipContent="List view"
                    multiCardsViewTooltipContent="Cards view"
                  />
                </div>
              </div>
              {showTableSettingsDialog && (
                <TableSettingsDialog
                  show={showTableSettingsDialog}
                  title="Table settings"
                  onHide={handleToggleTableSettingsDialog}
                  onColumnChange={handleColumnChange}
                  defaultColumnOrder={defaultColumnOrder}
                  defaultHiddenColumns={[]}
                  columnOrder={columnOrder}
                  hiddenColumns={hiddenColumns}
                  columnLabels={columnLabels}
                  disabledColumns={[]}
                  closeButtonText="Close"
                  resetButtonText="Reset to default"
                  searchPlaceholder="Search by column name"
                  notFoundMessage="No column found for this search value"
                  columnsDetails={columnsDetails}
                  autoLabel="Auto"
                  onColumnDetailsChange={handleColumnDetailsChange}
                  onSearchChange={(val: string) => console.log(val)}
                  immediateChange
                />
              )}
            </div>
          </TableToolbar>
          {sortBy && !isViewTypeTable && (
            <TableCardsSorting
              selectOptions={cardSortingSelectOptions}
              sortName={sortBy}
              sortOrder={sortDir}
              onSortChange={handleCardSortChange}
            />
          )}
        </>
      ) : null}

      <div>
        {isEmpty(rows) && !isViewTypeTable && (
          <NotFoundState
            headline="Sem resultados"
            message="Por favor refaça a busca."
          />
        )}
        <table className={tableClassNames}>
          <colgroup>
            {columns.map((column) =>
              renderTableCaption(column, columnsDetails[column])
            )}
            <col className="table-action" />
          </colgroup>
          <thead>
            <tr>
              {columns.map((column) =>
                renderTableHead(column, columnLabels[column], sortBy, sortDir)
              )}
              <th className="table-action" />
            </tr>
          </thead>
          <tbody>
            {isEmpty(rows) && isViewTypeTable && (
              <tr>
                <td colSpan={columns.length + 1}>
                  <NotFoundState
                    outerClassName="border-none"
                    headline="Sem resultados"
                    message="Por favor refaça a busca."
                  />
                </td>
              </tr>
            )}
            {rows.map((row: Route, index: number) => (
              //preciso adicionar um console que mostre a duração de cada rota aqui dentro para debugar
              <tr key={index}>
                {columns.map((col) => (
                  <td key={col} data-field={columnLabels[col]}>
                    {col === "performance" ? (
                      <>
                        {row[col] ? (
                          <div
                            style={{
                              background: "#239B7D",
                              borderRadius: 20,
                              overflow: "hidden",
                              color: "white",
                              paddingLeft: 16,
                              paddingRight: 16,
                              paddingTop: 4,
                              paddingBottom: 4,
                              alignItems: "center",
                              width: "auto !important",
                              display: "inline-block",
                            }}
                          >
                            <span>{row[col]}</span>
                          </div>
                        ) : null}
                      </>
                    ) : (
                      (
                        <>
                          {row[col as keyof Route] ? (
                            <span className={icons[col as keyof Route]}></span>
                          ) : null}
                          {col === 'distance' ? <span>{row[col as keyof Route].toLowerCase()?.replace(/\./g, ",")}</span> : <span>{row[col as keyof Route]}</span>}
                        </>
                      )
                    )}
                  </td>
                ))}
                <td className="table-action">
                  <span>
                    <ButtonDropdown
                      bsSize="md"
                      dropdownClassName="width-200"
                      title={
                        <span className="rioglyph rioglyph-option-vertical" />
                      }
                      variant="link"
                      iconOnly
                      items={[
                        {
                          value: (
                            <div
                              onClick={() => props.handleUpdateRoute(row.id)}
                            >
                              <span className="rioglyph rioglyph-map-location margin-right-10 text-color-primary" />
                              <span>Editar Rota</span>
                            </div>
                          ),
                        },
                        // {
                        //   value: (
                        //     <div>
                        //       <span className="rioglyph rioglyph-map-location margin-right-10 text-color-primary" />
                        //       <span>Abrir rota no mapa</span>
                        //     </div>
                        //   ),
                        // },
                        // {
                        //   value: (
                        //     <div>
                        //       <span className="rioglyph rioglyph-calendar margin-right-10 text-color-primary" />
                        //       <span>Agendar viagem</span>
                        //     </div>
                        //   ),
                        // },
                        // {
                        //   value: (
                        //     <div>
                        //       <span className="rioglyph rioglyph-cost-efficency margin-right-10 text-color-primary" />
                        //       <span>Rota inteligente</span>
                        //     </div>
                        //   ),
                        // },

                        {
                          value: (
                            <div
                              onClick={() => props.handleDeleteRoute(row.id)}
                            >
                              <span className="rioglyph rioglyph-trash margin-right-10 text-color-primary" />
                              <span>Excluir</span>
                            </div>
                          ),
                        },
                      ]}
                    />
                  </span>
                </td>
              </tr>
            ))}
            {/* Placeholder workaround for equal with cards of the last row */}
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
          </tbody>
        </table>
      </div>

    <Paginator 
      handlePage={(page) => props.handlePage(page)} 
      page={props.page} 
      pages={props.pages} 
    />
    </div>
  );
};

export default RoutesTable;
