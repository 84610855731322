import {useState} from "react";
import omit from "lodash/fp/omit";
import isEmpty from "lodash/fp/isEmpty";
import classNames from "classnames";

import TableToolbar from "@rio-cloud/rio-uikit/TableToolbar";
import TableViewToggles, {type TableViewTogglesViewType} from "@rio-cloud/rio-uikit/TableViewToggles";
import TableSearch from "@rio-cloud/rio-uikit/TableSearch";
import TableCardsSorting from "@rio-cloud/rio-uikit/TableCardsSorting";
import TableSettingsDialog from "@rio-cloud/rio-uikit/TableSettingsDialog";
import SortDirection, {type SortDirectionType} from "@rio-cloud/rio-uikit/SortDirection";
import SortArrows from "@rio-cloud/rio-uikit/SortArrows";
import NotFoundState from "@rio-cloud/rio-uikit/NotFoundState";
import {IOnGoingTrip, SocialUser} from "@types";
import Chip from "../Chip";
import ChipStatus from "../ChipStatus";
import {Button} from "@rio-cloud/rio-uikit";
import {GetMiniDashboard} from "../../types/miniDashboard";
import Paginator from "../Paginator";
import { formatDateBr } from "@utils";

const defaultColumnOrder = ["name",'dateStarted', "status", "statusTrip", "progress", "duration", "destination", "vehicle", 'action'];

type ColumnLabel = {
  [key: string]: string;
};
const columnLabels: ColumnLabel = {
  name: "Motorista",
  dateStarted: "Agendamento",
  status: "Status da rota",
  statusTrip: "Status de chegada",
  duration: "ETA",
  progress: "Progresso",
  destination: "Destino",
  vehicle: "Veículo",
  action: '',
};

type ColumnDetails = {
  [key: string]: number;
};

type ColumnDetailsMap = {
  [key: string]: ColumnDetails;
};

const demoColumnsDetails: ColumnDetailsMap = {
  // duration: {
  //   width: 120,
  // },
  // progress: {
  //   width: 300,
  // },
  // action: {
  //   width: 170
  // },
};

const getSortDir = (sortDir: SortDirectionType, sortBy: string, previousSortBy: string) => {
  if (sortBy === previousSortBy) {
    return sortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
  }
  return SortDirection.ASCENDING;
};

export type Props = {
  viewType: TableViewTogglesViewType;
  pages: number;
  routes: IOnGoingTrip[];
  searchValue: string;
  page: number;
  followers?: Array<SocialUser>;
  usersAmity?: Amity.User[];
  handleSearchValueChange: (text: string) => void;
  handlePage: (page: number) => void;
  handleSort: (dir: string, newSort: string) => void;
  handleSelect: (s: IOnGoingTrip) => void;
  selected?: string;
  handleDetails: (s: IOnGoingTrip) => void;
};

const TableTrips = (props: Props) => {
  const { viewType: externalViewType } = props;
  const [sortBy, setSortBy] = useState("name");
  const [sortDir, setSortDir] = useState<SortDirectionType>(SortDirection.ASCENDING);
  const [showTableSettingsDialog, setShowTableSettingsDialog] = useState(false);
  const [columnOrder, setColumnOrder] = useState<string[]>(defaultColumnOrder);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [columnsDetails, setColumnsDetails] = useState(demoColumnsDetails);
  const [viewType, setViewType] = useState(externalViewType || TableViewToggles.VIEW_TYPE_TABLE);

  const handleToggleTableSettingsDialog = () => setShowTableSettingsDialog(!showTableSettingsDialog);
  const handleViewTypeChange = (newViewType: TableViewTogglesViewType) => setViewType(newViewType);


  const handleColumnChange = (
    newColumnOrder: string[],
    newHiddenColumns: string[],
    newColumnsDetails = columnsDetails
  ) => {
    setColumnOrder(newColumnOrder);
    setHiddenColumns(newHiddenColumns);
    setColumnsDetails(newColumnsDetails);
  };

  // For immediate effect
  const handleColumnDetailsChange = (column: string, newColumnDetails: ColumnDetails) => {
    const updatedColumnsDetails = { ...columnsDetails };
    updatedColumnsDetails[column] = newColumnDetails;
    setColumnsDetails(updatedColumnsDetails);
  };

  const handleSortChange = (event: React.MouseEvent<HTMLElement>) => {
    const newSortBy = event.currentTarget.getAttribute("data-sortby");
    if (newSortBy) {
      handleCardSortChange(newSortBy, getSortDir(sortDir, newSortBy, sortBy));
      props.handleSort(sortDir, newSortBy);
    }
  };

  const handleCardSortChange = (newSortBy: string, newSortDir: SortDirectionType) => {
    setSortBy(newSortBy);
    setSortDir(newSortDir);
  };

  // May be extracted as a dedicated component but for demo purpose it's shown here
  const renderTableHead = (column: string, label: string, sortByColumn: string, sortDirection: SortDirectionType) => {
    const tableHeadClassNames = classNames("user-select-none", "sort-column");

    return (
      <th
        key={column}
        className={tableHeadClassNames}
        onClick={handleSortChange}
        data-field={column}
        data-sortby={column}
        title={label}
      >
        <span>
          {sortByColumn === column ? <SortArrows direction={sortDirection} /> : column !== 'action' ? <SortArrows /> : null}
          <span>{label}</span>
        </span>
      </th>
    );
  };

  const renderTableCaption = (column: string, columnDetails: ColumnDetails) => {
    const style = columnDetails?.width
      ? {
        minWidth: columnDetails.width,
        width: columnDetails.width,
      }
      : {};

    return <col key={column} style={style} />;
  };

  // filter for hidden columns
  const columns = columnOrder.filter((name) => !hiddenColumns.includes(name));

  // filter data to omit hidden columns
  const withoutHidden = omit(hiddenColumns);
  const filteredRows = props.routes.map((vehicle) => ({
    ...withoutHidden(vehicle),
  })) as IOnGoingTrip[];

  // in case a search value is given, filter the data accordingly
  const searchResult = filteredRows;

  // Sort rows according to the sortBy and sortDir settings
  //const rows = searchResult;
    const rows = props.routes;

  const tableClassNames = classNames(
    "table",
    "table-layout-fixed",
    "table-column-overflow-hidden",
    props?.routes?.length > 0 && "table-hover",
    "cursor-pointer",
    "table-bordered",
    "table-sticky",
    "table-head-filled",
    viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD && "table-cards table-single-card",
    viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS && "table-cards table-multi-cards"
  );

  const isViewTypeTable = viewType === TableViewToggles.VIEW_TYPE_TABLE;

  const cardSortingSelectOptions = columns.map((column) => {
    return {
      id: column,
      label: columnLabels[column],
      selected: column === sortBy,
      disabled: false,
    };
  });

  const renderChip = (driverId: string) => {
    const follower = props.followers?.filter((res) => res.from === driverId);

    return follower?.length ? (
      <Chip text="Conectado" icon="rioglyph rioglyph-user-group" background="bg-status-driving" />
    ) : (
      <Chip text="Minha equipe" icon="rioglyph rioglyph-role-management" background="bg-status-working" />
    );
  };

  return (
    <div id="table-trips">
      <TableToolbar>
        <div className="table-toolbar-container">
          <div className="table-toolbar-group-left">
            <div className="table-toolbar-column">
              <div className="btn-toolbar table-btn-toolbar">
                <TableSearch
                  value={props.searchValue}
                  onChange={props.handleSearchValueChange}
                  placeholder="Buscar viagem em andamento"
                />
              </div>
            </div>
          </div>
          <div className="table-toolbar-group-right">
            <div className="table-toolbar-column">
              <TableViewToggles
                initialViewType={viewType}
                onViewTypeChange={handleViewTypeChange}
                tableViewTooltipContent="Table view"
                singleCardViewTooltipContent="List view"
                multiCardsViewTooltipContent="Cards view"
              />
            </div>
          </div>
          {showTableSettingsDialog && (
            <TableSettingsDialog
              show={showTableSettingsDialog}
              title="Table settings"
              onHide={handleToggleTableSettingsDialog}
              onColumnChange={handleColumnChange}
              defaultColumnOrder={defaultColumnOrder}
              defaultHiddenColumns={[]}
              columnOrder={columnOrder}
              hiddenColumns={hiddenColumns}
              columnLabels={columnLabels}
              disabledColumns={[]}
              closeButtonText="Close"
              resetButtonText="Reset to default"
              searchPlaceholder="Search by column name"
              notFoundMessage="No column found for this search value"
              columnsDetails={columnsDetails}
              autoLabel="Auto"
              onColumnDetailsChange={handleColumnDetailsChange}
              onSearchChange={(val: string) => console.log(val)}
              immediateChange
            />
          )}
        </div>
      </TableToolbar>

      {sortBy && !isViewTypeTable && (
        <TableCardsSorting
          selectOptions={cardSortingSelectOptions}
          sortName={sortBy}
          sortOrder={sortDir}
          onSortChange={handleCardSortChange}
        />
      )}

      <div>
        {isEmpty(rows) && !isViewTypeTable && (
          <NotFoundState headline="Sem resultados" message="Por favor refaça a busca." />
        )}

        <table className={tableClassNames}>
          <colgroup>
            {columns.map((column) => renderTableCaption(column, columnsDetails[column]))}
          </colgroup>

          <thead>
            <tr>
              {columns.map((column) => renderTableHead(column, columnLabels[column], sortBy, sortDir))}
            </tr>
          </thead>

          <tbody>
            {isEmpty(rows) && isViewTypeTable && (
              <tr>
                <td colSpan={columns.length}>
                  <NotFoundState
                    outerClassName="border-none"
                    headline="Sem resultados"
                    message="Por favor refaça a busca."
                  />
                </td>
              </tr>
            )}

            {rows?.map((row: IOnGoingTrip, index: number) => {
              return (
                <tr
                  key={index}
                  className={row.routeId === props.selected ? "active" : ""}
                >
                  <td key={"Motorista"} data-field={"Motorista"} onClick={() => props?.handleSelect(row)}>
                    <div className="display-flex flex-direction-row align-items-center">
                      <div
                        className="width-30 aspect-ratio-1 bg-highlight padding-5 display-flex align-items-center justify-content-center rounded-circle bg-size-100pct bg-repeat-no bg-light"
                        style={{
                          backgroundImage: `url(${props.usersAmity?.find((res) => row.driverId === res.userId)
                            ?.metadata?.imageProfile?.fileUrl ||
                            "https://cdn.rio.cloud/svg/common/ico_rio_colored.svg"
                            })`,
                        }}
                      />
                      <div className="padding-x-15">
                        <span className="text-bold text-size-14 text-capitalize">
                          {row.driverName}
                        </span>
                        <div className="margin-top-5">{renderChip(row.driverId)}</div>
                      </div>
                    </div>
                  </td>

                  <td key={"dateStarted"} data-field={"dateStarted"} onClick={() => props?.handleSelect(row)}>
                    <div className="width-150">
                      {formatDateBr(row.dateStarted)}
                    </div>
                  </td>

                  <td key={"Status"} data-field={"Status"} onClick={() => props?.handleSelect(row)}>
                    <div style={{maxWidth: 150}}>
                      <ChipStatus status={row.tripStatusNow === "NOTSTARTED" && !row.polyline?.length ? "NOTSTARTED" : row.routeStatusNow} />
                    </div>
                  </td>

                  <td key={"StatusTrip"} data-field={"StatusTrip"} onClick={() => props?.handleSelect(row)}>
                    <div style={{maxWidth: 150}}>
                      <ChipStatus
                        status={
                          row.tripStatusNow === "NOTSTARTED" && row?.polyline && row?.polyline?.length > 0
                            ? "PAUSED"
                            : row.tripStatusNow === "NOTSTARTED" && !row?.polyline?.length
                            ? "NOTSTARTED"
                            : row.tripStatusNow
                        }
                      />
                    </div>

                  </td>

                  <td key={"Progress"} data-field={"Progress"} onClick={() => props?.handleSelect(row)}>
                    <div className="display-flex align-items-center gap-10">
                      <div className="progress width-250">
                        <div
                          className="progress-bar progress-bar-primary"
                          style={{ width: row?.actualProgress }}
                        />
                      </div>
                      <span className="text-size-14 text-normal text-color-darkest">{row.actualProgress}</span>
                    </div>
                  </td>

                  <td key={"Duração"} data-field={"Duração"} onClick={() => props?.handleSelect(row)}>
                    <span className="rioglyph rioglyph-time-alt margin-right-10 text-color-gray text-size-16"></span>
                    <span className="text-size-14 text-normal text-color-darkest">{row.eta}</span>
                  </td>

                  <td key={"Destino"} data-field={"Destino"} onClick={() => props?.handleSelect(row)}>
                    <span className="rioglyph rioglyph-finish margin-right-10 text-color-gray text-size-16" />
                    <span className="text-size-14 text-normal text-color-darkest">
                      {row.destinyName}
                    </span>
                  </td>

                  <td key={"Veículo"} data-field={"Veículo"} onClick={() => props?.handleSelect(row)}>
                    <span className="rioglyph rioglyph-truck margin-right-10 text-color-gray text-size-16"></span>
                    <span className="text-size-14 text-norma text-color-darkest">
                      {row.assetName}
                    </span>
                  </td>

                  <td key={"Action"} data-field={"Action"}>
                    <div className="display-flex gap-20">
                      <Button className="width-150 height-36" iconName="rioglyph rioglyph-detail-view-info" onClick={() => props.handleDetails(row)}>
                        Ver Detalhes
                      </Button>
                      {/* <Button className="width-150 height-36">
                        Mensagens
                      </Button> */}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Paginator
        pages={props.pages}
        page={props.page}
        handlePage={(id) => props.handlePage(id)}
      />
    </div>
  );
};

export default TableTrips;

