import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITripsState } from "@types";

const initialState: ITripsState = {
  idRouteSelected: "",
  idVehicleSelected: "",
  idDriverSelected: "",
  nameVehicleSelected: "",
  nameDriverSelected: "",
  avatarDriverSelected: '',
};

export const TripsSlice = createSlice({
  name: "Trips",
  initialState,
  reducers: {
    setIdRouteSelected: (state, action: PayloadAction<string | null>) => {
      state.idRouteSelected = action.payload;
    },
    setIdVehicleSelected: (state, action: PayloadAction<string | null>) => {
      state.idVehicleSelected = action.payload;
    },
    setIdDriverSelected: (state, action: PayloadAction<string | null>) => {
      state.idDriverSelected = action.payload;
    },
    setNameDriverSelected: (state, action: PayloadAction<string | null>) => {
      state.nameDriverSelected = action.payload;
    },
    setNameVehicleSelected: (state, action: PayloadAction<string | null>) => {
      state.nameVehicleSelected = action.payload;
    },
     setAvatarDriverSelected: (state, action: PayloadAction<string | null>) => {
      state.avatarDriverSelected = action.payload;
    },
    clearTrip: (state) => {
      state = initialState;
    },
  },
});

export const {
  setIdRouteSelected,
  setIdDriverSelected,
  setIdVehicleSelected,
  clearTrip,
  setNameDriverSelected,
  setNameVehicleSelected,
  setAvatarDriverSelected,
} = TripsSlice.actions;

export default TripsSlice.reducer;
