import { ChangeEvent, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import ExpanderPanel from "@rio-cloud/rio-uikit/ExpanderPanel";
import Button from "@rio-cloud/rio-uikit/Button";
import "./routes.css";
import Checkbox from "@rio-cloud/rio-uikit/Checkbox";
import Autosuggest from "../Autosuggest";
import ConfirmationDialog from "@rio-cloud/rio-uikit/ConfirmationDialog";
import {fetchAutosuggest, fetchAutosuggestByCoords, mountRoute} from "src/services/routes";
import { AutoSuggestSuggestion } from "@rio-cloud/rio-uikit/components/autosuggest/AutoSuggest";
import moment from "moment";
import { AvoidAreas, IRoute, IVehicleInfo, Route } from "@types";
import Dialog from "@rio-cloud/rio-uikit/Dialog";
import { useDispatch, useSelector } from "react-redux";
import Notification from "@rio-cloud/rio-uikit/Notification";
import {
    defineAreas,
    defineAvoidAreas,
    defineAvoidToll,
    defineDestination, defineDurationWithStops,
    defineOrigin,
    defineRouteCost,
    defineRouteDeviation,
    defineRoutes,
    defineStops,
    defineVehicleInfo,
} from "src/store/routes/RoutesSlice";
import { RootState, useAppSelector } from "@store";
import CustomSlider from "../CustomSlider/CustomSlider";
import CustomInputSelect from "../CustomInputSelect/CustomInputSelect";
import {
    getByAccountAndCostTypeAccountDefaultCost,
    getCalculateFuelCost,
    getCalculateToll,
    getCostTypeByAccount,
    getCurrencyTypes,
    getTruckTypes,
} from "../../services/costSimulator";
import { CreateRouteCostPayload, getCostTypeByAccountResponse } from "../../types/costSimulator";
import { useSearchParams } from "react-router-dom";
import { Dynamics } from "../Dynamics";
import { formatMaskTime, formatMetersMaskInNumber, formatMoneyInNumber, kmToMeters } from "@utils";
import { CreateAreaPayload } from "../../types/areas";
import { ChipStatus } from "../index";
import { deleteRouteArea, getRouteAreas } from "../../services/areas";
import {Coordinates} from "../Map";

interface DynamicContentDesvioProps {
    isChecked: boolean;
    rangeValue: number;
    onCheckedChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onDeviationChange: (value: number) => void;
}

const DynamicContentDesvio = ({
    isChecked,
    onCheckedChange,
    rangeValue,
    onDeviationChange,
}: DynamicContentDesvioProps) => {
    const formatValueWithUnit = (value: number): string => `${value}m`;

    const parseValueFromUnit = (value: string): number => {
        const numericValue = parseFloat(value.replace(" m", ""));
        return isNaN(numericValue) ? 0 : numericValue;
    };

    const [localInputValue, setLocalInputValue] = useState<string>(formatValueWithUnit(rangeValue));

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = e.target.value?.replace(/[^0-9.]/g, '');

        let numericValue = parseValueFromUnit(rawValue);

        if (numericValue <= 2000) {
            setLocalInputValue(rawValue);
        }

        if (numericValue >= 0.1 && numericValue <= 2000) {
            onDeviationChange(Number(numericValue.toFixed(1)));
        }
    };

    const handleInputBlur = () => {
        let numericValue = parseValueFromUnit(localInputValue);

        if (numericValue > 2000) {
            numericValue = 2000;
        }

        setLocalInputValue(formatValueWithUnit(numericValue));
    };

    const handleSliderChange = (value: number) => {
        onDeviationChange(Number(value.toFixed(2)));
        setLocalInputValue(formatValueWithUnit(value));
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Delete", ","];

        if (allowedKeys.includes(e.key)) return;

        const numericValue = parseValueFromUnit(localInputValue + e.key);

        if (isNaN(numericValue) || numericValue > 2000) {
            e.preventDefault();
        }
    };

    useEffect(() => {
        if (!isChecked) {
            setLocalInputValue(formatValueWithUnit(0));
            onDeviationChange(0);
        } else {
            if (localInputValue === "1m") {
                setLocalInputValue('1m');
            }
        }
    }, [isChecked]);

    return (
        <div className="display-flex gap-20" style={{ flexDirection: "column" }}>
            <div className="gap-20 display-flex align-items-center checkbox-container">
                <Checkbox checked={isChecked} onChange={onCheckedChange}>
                    Definir tolerância de desvio da rota
                </Checkbox>
            </div>
            {isChecked && (
                <div className="itens-container display-flex gap-20">
                    <div className="slider-container">
                        <CustomSlider
                            value={rangeValue}
                            minValue={0}
                            maxValue={2000}
                            step={1}
                            onChange={handleSliderChange}
                        />
                    </div>

                    <div className="input-container">
                        <input
                            type="text"
                            className="form-control"
                            value={localInputValue}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

interface DynamicContentAreaProps {
    areas: CreateAreaPayload[];
}

const DynamicContentAreas = ({ areas }: DynamicContentAreaProps) => {
    const dispatch = useDispatch();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [indexToDelete, setIndexToDelete] = useState<number | null>(null);
    const [searchParams] = useSearchParams();

    const handleDeleteClick = (index: number) => {
        setIndexToDelete(index);
        setConfirmDelete(true);
    };

    const handleConfirmDelete = async () => {
        const updatedAreas = areas.filter((_, i) => i !== indexToDelete);
        dispatch(defineAreas(updatedAreas));

        if (indexToDelete === null) {
            Notification.error("Índice inválido para exclusão.");
            return;
        }

        const validIndex: number = indexToDelete;
        try {

            const areaId = Number(areas[validIndex].id);

            if (areaId) {

                await deleteRouteArea(areaId);
                const updatedAreas = areas.filter((_, i) => i !== indexToDelete);
                dispatch(defineAreas(updatedAreas));
                Notification.info("Área deletada com sucesso!");
            }
        } catch (error) {
            console.error("Erro ao deletar a área:", error);
            Notification.error("Erro ao deletar a área da rota.");
        } finally {
            setConfirmDelete(false);
        }
    };

    return (
        <div className="bg-blank border">
            {areas.length === 0 ? (
                <div className="col-12 area-container-main">
                    <p className="no-areas-message">Sem áreas cadastradas.</p>
                </div>
            ) : (
                areas.map((area, index) => (
                    <div key={index} className="areas-container">
                        <div className="area-item-block">
                            <span className="rioglyph rioglyph-layer area-item-icon"></span>
                            <p className="area-item-name">{area.name || "Área sem nome"}</p>
                        </div>
                        <div className="area-item-block-2">
                            <ChipStatus status={area.interestType} />
                            <button
                                className="rioglyph rioglyph-trash area-del-button"
                                onClick={() => handleDeleteClick(index)}
                            ></button>
                        </div>
                    </div>
                ))
            )}
            <ConfirmationDialog
                show={confirmDelete}
                title="Confirmação de exclusão"
                content="Tem certeza que deseja excluir esta área?"
                onClickConfirm={handleConfirmDelete}
                onClickCancel={() => setConfirmDelete(false)}
                cancelButtonText="Cancelar"
                confirmButtonText={<span>Excluir</span>}
                useOverflow
            />
        </div>
    );
};

type TProps = {
    onChangeRoutes: (routes: Route[], via: any[]) => void;
    onSave?: (
        origin: IRoute,
        destination: IRoute,
        routes: Route[],
        stops: IStop[],
        routeName: string,
        routeCost: CreateRouteCostPayload,
        accountId: string,
        routeRangeToleranceLimit: number | null,
        avoidToll: boolean,
        areas: CreateAreaPayload[],
        vehicle: IVehicleInfo
    ) => void;
    saveLabel?: string;
};

export interface IStop {
    id?: string;
    position: any;
    stayTime: string;
    category: string;
    order: number;
}

const INITIAL_STOP = {
    position: null,
    stayTime: "00:00",
    category: "LUNCH_REST",
    order: 1,
};

export const convertTimeStringToSeconds = (timeStr: string): number => {

    if (!timeStr || timeStr.trim() === "" || timeStr === "0" || timeStr === "0:00" || timeStr === "0h0min") {
        return 0;
    }

    if (timeStr.includes(":")) {

        const parts = timeStr.split(":");
        if (parts.length !== 2) {
            throw new Error("Formato inválido. Use 'HH:MM' ou 'HhMMmin' (ex.: 01:00 ou 1h00min)");
        }
        const [hoursStr, minutesStr] = parts;
        const hours = Number(hoursStr);
        const minutes = Number(minutesStr);
        if (isNaN(hours) || isNaN(minutes)) {
            throw new Error("Formato inválido. Use 'HH:MM' ou 'HhMMmin'");
        }
        return hours * 3600 + minutes * 60;
    } else if (timeStr.includes("h")) {

        const match = timeStr.match(/(\d+)h(\d+)min/);
        if (!match) {
            throw new Error("Formato inválido. Use 'HH:MM' ou 'HhMMmin'");
        }
        const hours = Number(match[1]);
        const minutes = Number(match[2]);
        if (isNaN(hours) || isNaN(minutes)) {
            throw new Error("Formato inválido. Use 'HH:MM' ou 'HhMMmin'");
        }
        return hours * 3600 + minutes * 60;
    } else {
        throw new Error("Formato inválido. Use 'HH:MM' ou 'HhMMmin'");
    }
};

export const convertSecondsToHours = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    return `${hours}h${minutes}min`;
};

const RegisterRoute = ({ onChangeRoutes, onSave, saveLabel = "Salvar rota",}: TProps) => {
    const {
        origin: originStore,
        destination: destinationStore,
        stops,
        routes: routesStore,
        routeCost: routeCostStore,
        routeName,
        accountId,
        routeRangeToleranceLimit,
        avoidToll: routeAvoidToll,
        areas: areasStore,
        areasToAvoid: areasToAvoidStore,
        vehicle,
    } = useAppSelector((state) => state.routes);
    const dispatch = useDispatch();
    const waypoint = useAppSelector((state) => state.routes.waypoint);
    useEffect(() => {
        setAvoidToll(routeAvoidToll);
    }, [routeAvoidToll]);

  useEffect(() => {
    if (waypoint && waypoint.lat && waypoint.lng) {

      const addWaypoint = async () => {
        try {

          const locationName = await fetchAutosuggestByCoords(waypoint.lat, waypoint.lng);

          const newStop: IStop = {
            id: waypoint.id,
            position: {
              lat: waypoint.lat,
              lng: waypoint.lng,
              label: locationName
            },
            stayTime: "00:00",
            category: "WAYPOINT",
            order: via.length + 1
          };

          setVia((prev) => [...prev, newStop]);
        } catch (error) {
          console.error("Erro ao adicionar waypoint:", error);
        }
      };

      addWaypoint();
    }
  }, [waypoint]);

    //TEMP FOI RETIRADO AS PRESSAS REFERENTE A LÓGICA DO SIMULADOR SE BASEAR NO TIPO DO VEÍCULO
    const [type, setType] = useState("TRUCK");
    const [origin, setOrigin] = useState<any>(null);
    const [destination, setDestination] = useState<any>(null);
    const [routes, setRoutes] = useState<Route[]>([]);
    const [via, setVia] = useState<IStop[]>([]);
    const [distance, setDistance] = useState("");
    const [duration, setDuration] = useState("");
    const [stop, setStop] = useState({ ...INITIAL_STOP });
    const [invalidPosition, setInvalidPosition] = useState(false);

    const [hasRouteRangeLimit, setHasRouteRangeLimit] = useState(Boolean(routeRangeToleranceLimit));
    const [avoidToll, setAvoidToll] = useState(Boolean(routeAvoidToll));
    const [routeRangeLimit, setRouteRangeLimit] = useState(routeRangeToleranceLimit ?? 0);

    const [selectedFuelOption, setSelectedFuelOption] = useState<string | number>(0);
    const [selectedVehicleOption, setSelectedVehicleOption] = useState<string | number>(1);
    const [kmInputValue, setKmInputValue] = useState<string>("");
    const [priceInputValue, setPriceInputValue] = useState<string>("");

    const [fuelType, setFuelType] = useState<{ value: number; label: string }[]>([]);
    const [vehicleType, setVehicleType] = useState<{ value: number; label: string }[]>([]);
    const [tollValue, setTollValue] = useState<number>(0);
    const [fuelValue, setFuelValue] = useState<number>(0);
    const [totalValue, setTotalValue] = useState<number>(0);
    const [showResults, setShowResults] = useState<boolean>(false);
    const [currencyTypeId, setCurrencyTypeId] = useState<number>(0);

    const [operational, setOperational] = useState<getCostTypeByAccountResponse | undefined>();
    const [priceOperational, setPriceOperational] = useState<string>("0,00");

    const [vehicleLenght, setVehicleLenght] = useState<string>(vehicle?.lenght || "");
    const [vehicleHeight, setVehicleHeight] = useState<string>(vehicle?.height || "");
    const [vehicleWidth, setVehicleWidht] = useState<string>(vehicle?.width || "");
    const [vehicleNumberOfAxles, setVehicleNumberOfAxles] = useState<string>(vehicle?.numberOfAxles || "");
    const [vehicleTrailerAxles, setVehicleTrailerAxles] = useState<string>(vehicle.trailerAxles || "");
    const [vehicleMaxAxleLoad, setVehicleMaxAxleLoad] = useState<string>(vehicle.maxAxleLoad || "");
    const [vehicleGrossWeight, setGrossWeight] = useState<string>(vehicle.grossWeight || "");
    const [shippedVHazardousGoods, setVShippedHazardousGoods] = useState<string | number>("");
    const [vehiclePollutantClass, setVehiclePollutantClass] = useState<string | number>("");

    const [searchParams] = useSearchParams();
    const routeId = searchParams.get("id");

    const durationWithStops = useSelector(
        (state: RootState) => state.routes.durationWithStops);

    const [shouldSave, setShouldSave] = useState(false);

    useEffect(() => {
        const updatedVia = via.map(stop => ({
            ...stop,
            stayTimeInSeconds: convertTimeStringToSeconds(stop.stayTime)
        }));

        const routeDurationSeconds = convertTimeStringToSeconds(duration);

        const totalStopsSeconds = updatedVia.reduce((acc, stop) =>
            acc + (stop.stayTimeInSeconds || 0), 0);

        const totalDurationSeconds = routeDurationSeconds + totalStopsSeconds;

        const finalDuration = convertSecondsToHours(totalDurationSeconds);
        dispatch(defineDurationWithStops(finalDuration));
    }, [via, duration]);

    useEffect(() => {
        if (vehicle) {
            const {
                lenght,
                height,
                width,
                trailerAxles,
                maxAxleLoad,
                grossWeight,
                numberOfAxles,
                shippedHazardousGoods,
                pollutantClass,
            } = vehicle;
            setVehicleLenght(lenght);
            setVehicleHeight(height);
            setVehicleWidht(width);
            setVehicleTrailerAxles(trailerAxles);
            setVehicleMaxAxleLoad(maxAxleLoad);
            setGrossWeight(grossWeight);
            setVehicleNumberOfAxles(numberOfAxles);
            if (shippedHazardousGoods) {
                setVShippedHazardousGoods(shippedHazardousGoods);
            }
            setVehiclePollutantClass(pollutantClass);
        }
    }, [vehicle]);

    // CALCULO PESO POR EIXO
    // console.log({vehicleGrossWeight , vehicleTrailerAxles , vehicleNumberOfAxles})
    useEffect(() => {
        const vw = vehicleGrossWeight ? parseFloat(vehicleGrossWeight) : 0;
        const vt = vehicleTrailerAxles ? parseInt(vehicleTrailerAxles) : 0;
        const vn = vehicleNumberOfAxles ? parseInt(vehicleNumberOfAxles) : 0;
        const sum = vt + vn;
        const cal = vw / sum;

        if (
            vehicleGrossWeight &&
            vehicleTrailerAxles &&
            vehicleNumberOfAxles &&
            vehicleGrossWeight !== "0,00" &&
            vehicleGrossWeight !== "0" &&
            vehicleTrailerAxles !== "0"
        ) {
            setVehicleMaxAxleLoad(cal.toString());
        } else if (
            vehicleGrossWeight &&
            vehicleTrailerAxles &&
            vehicleNumberOfAxles &&
            vt === 0 &&
            vn === 0 &&
            vw > 0
        ) {
            setVehicleMaxAxleLoad("0,00");
        } else {
            setVehicleMaxAxleLoad("0,00");
        }
    }, [vehicleGrossWeight, vehicleTrailerAxles, vehicleNumberOfAxles]);

    function handleHasRouteRangeLimitChange(event: ChangeEvent<HTMLInputElement>) {
        setHasRouteRangeLimit(event.target.checked);
    }

    function handleRouteRangeLimitChange(value: number) {
        setRouteRangeLimit(value);
    }

    useEffect(() => {
        const fetchCurrencyTypes = async () => {
            try {
                const response = await getCurrencyTypes();
                const realCurrency = response.find((currency) => currency.name.toLowerCase().includes("real"));
                if (realCurrency) {
                    setCurrencyTypeId(realCurrency.id);
                }
            } catch (error) {
                console.error("Error fetching currency types:", error);
            }
        };

        fetchCurrencyTypes();
    }, []);

    useEffect(() => {
        if (routeRangeToleranceLimit && routeRangeToleranceLimit > 0) {
            setHasRouteRangeLimit(true);
            setRouteRangeLimit(routeRangeToleranceLimit);
        } else {
            setHasRouteRangeLimit(false);
            setRouteRangeLimit(0);
        }
    }, [routeRangeToleranceLimit]);

    useEffect(() => {
        setOrigin(originStore || null);
        setDestination(destinationStore || null);
        setVia(stops || []);
    }, [originStore, destinationStore, stops]);

    useEffect(() => {
        if (routeCostStore) {
            const costTypeKey = routeCostStore?.costTypes?.[0];
            setSelectedFuelOption(costTypeKey || 0);
            setSelectedVehicleOption(routeCostStore.truckType || 1);
            const tollCost = routeCostStore.tollCost || 0;
            const totalCost = routeCostStore.totalCost || 0;
            setTollValue(tollCost);
            setFuelValue(totalCost - tollCost);

            setTotalValue(totalCost);

            const priceOp = routeCostStore?.valuePerUnit?.[operational?.id as keyof number]?.[0];
            if (priceOp) {
                setPriceOperational(Number(priceOp).toFixed(2).replace(".", ","));
            }

            const valuePerUnit = routeCostStore?.valuePerUnit?.[costTypeKey];

            const kmValue = valuePerUnit ? String(valuePerUnit[0]) : "";
            const priceValue = valuePerUnit ? String(valuePerUnit[1]) : "";

            setKmInputValue(kmValue);
            setPriceInputValue(priceValue);

            if (kmValue) {
                const selectedFuel = fuelType.find((fuel) => fuel.value == selectedFuelOption);
                const updatedKmValue = `${parseFloat(kmValue).toFixed(2)} ${selectedFuel?.label.toLowerCase() === "elétrico" ? "km/kwh" : "km/litro"
                    }`;
                setKmInputValue(updatedKmValue);
            }

            if (priceValue) {
                const numericValue = priceValue.replace(",", ".");
                if (!isNaN(Number(numericValue))) {
                    const formattedPriceValue = `R$ ${Number(numericValue).toFixed(2).replace(".", ",")}`;
                    setPriceInputValue(formattedPriceValue);
                }
            }

            if (totalCost > 0) {
                setShowResults(true);
            }
        }
    }, [routeCostStore]);

    useEffect(() => {
        if (origin && destination) {
            handleRoute();
        } else {
            setRoutes([]);
            setDistance(convertMetersToKm(0));
            setDuration(convertSecondsToHours(0));
        }
    }, [
        origin,
        destination,
        via,
        avoidToll,
        type,
        vehicleWidth,
        vehicleHeight,
        vehicleLenght,
        vehicleGrossWeight,
        vehicleMaxAxleLoad,
        vehicleNumberOfAxles,
        vehicleTrailerAxles,
        shippedVHazardousGoods,
        areasToAvoidStore
    ]);

    const saveChanges = () => {
      if (!origin || !destination) return;

      const saveOrigin = {
        lat: origin.lat,
        lng: origin.lng,
        label: origin.label,
      };

      const saveDestination = {
        lat: destination.lat,
        lng: destination.lng,
        label: destination.label,
      };

      const operationID = parseInt(operational?.id as string) || 921;

      const saveRouteCost: CreateRouteCostPayload = {
        route: "",
        costTypes: [parseInt(selectedFuelOption as string), operationID],
        valuePerUnit: {
          [selectedFuelOption.toString()]: [
            parseFloat(kmInputValue.replace(/[^0-9.,]/g, "")),
            parseFloat(priceInputValue.replace(/[^0-9.,]/g, "").replace(",", ".")),
          ],
          [operationID]: [formatMoneyInNumber(priceOperational) || 0],
        },
        totalCost: totalValue,
        tollCost: tollValue,
        currencyType: currencyTypeId,
        truckType: parseInt(selectedVehicleOption as string),
      };

      const vehicleInfo: IVehicleInfo = {
        grossWeight: vehicleGrossWeight,
        height: vehicleHeight,
        width: vehicleWidth,
        lenght: vehicleLenght,
        type: type,
        maxAxleLoad: vehicleMaxAxleLoad,
        numberOfAxles: vehicleNumberOfAxles,
        trailerAxles: vehicleTrailerAxles,
        shippedHazardousGoods: type === "TRUCK" ? shippedVHazardousGoods?.toString() : "NONE",
        pollutantClass: vehiclePollutantClass?.toString(),
      };

      dispatch(defineOrigin(saveOrigin));
      dispatch(defineDestination(saveDestination));
      dispatch(defineStops(via));
      dispatch(defineRouteDeviation(routeRangeLimit ?? 0));
      dispatch(defineRouteCost(saveRouteCost));
      dispatch(defineAvoidToll(avoidToll));
      dispatch(defineVehicleInfo(vehicleInfo));

      setShouldSave(true);
    };

    useEffect(() => {
      if (shouldSave) {
        if (onSave && originStore && destinationStore) {
          onSave(
            originStore,
            destinationStore,
            routesStore,
            stops,
            routeName,
            routeCostStore,
            accountId,
            routeRangeToleranceLimit ?? 0,
            routeAvoidToll,
            areasStore,
            vehicle
          );
        }
        setShouldSave(false);
      }
    }, [shouldSave, originStore, destinationStore, routeCostStore]);

    useEffect(() => {
        onChangeRoutes(routes, via);
        dispatch(defineRoutes(routes));
    }, [routes]);

    const handleRoute = async () => {
        if (origin && destination) {
            const body = {
                origin: { lat: origin.lat, lng: origin.lng },
                destination: { lat: destination.lat, lng: destination.lng },
                via: via.map((t) => ({ lat: t.position.lat, lng: t.position.lng })),
                vehicle: {
                    height: formatMetersMaskInNumber(vehicleHeight) || 0,
                    width: formatMetersMaskInNumber(vehicleWidth) || 0,
                    length: formatMetersMaskInNumber(vehicleLenght) || 0,
                    numberOfAxles: parseInt(vehicleNumberOfAxles) || 0,
                    trailerAxles: parseInt(vehicleTrailerAxles) || 0,
                    grossWeight: formatMetersMaskInNumber(vehicleGrossWeight, "T") || 0,
                    maxWeightAxle: parseFloat(vehicleMaxAxleLoad?.slice(0, 4)) * 1000 || 0,
                    vehicleType: type,
                    shippedHazardousGoods: type === "TRUCK" ? shippedVHazardousGoods !== '' ? shippedVHazardousGoods : 'NONE' : "NONE",
                },
                departureTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
                // routeDeviation: routeRangeLimit ?? null,
                avoidToll,
                transport: {
                    mode: type,
                },
                avoidAreas: areasToAvoidStore.formatted
            };
            const response = await mountRoute(body);
            setRoutes(response.routes);
            const { distance, duration } = (response.routes[0]?.sections || []).reduce(
                (acum, curr) => {
                    acum.distance += curr.summary.length;
                    acum.duration += curr.summary.duration;
                    return acum;
                },
                { distance: 0, duration: 0 }
            );
            setDistance(convertMetersToKm(distance));
            setDuration(convertSecondsToHours(duration));
        }
    };

    useEffect(() => {
        async function getAreas() {
            try {
                if (routeId) {
                    const response = await getRouteAreas(routeId);
                    dispatch(defineAreas(response));
                }
            } catch (error) {
                console.error("Erro ao buscar as áreas da rota:", error);
            }
        }

        if (routeId) {
            getAreas();
        }
    }, [dispatch, routeId]);

    /*const convertSecondsToHours = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);

        return `${hours}h${minutes}min`;
    };*/

    const convertMetersToKm = (valueInMeters: number) => {
        if (valueInMeters < 1000) {
            return `${valueInMeters} M`;
        } else {
            const valueInKm = (valueInMeters / 1000).toFixed(2);
            return `${valueInKm} Km`;
        }
    };

    const [openModal, setOpenModal] = useState(false);

    const DynamicContent = () => {
        return (
            <fieldset className="col-sm-4">
                <p>
                    <Checkbox label="Rota com menor consumo energético" />
                </p>
                <p>
                    <Checkbox label="Rota mais rápida" />
                </p>
                <p>
                    <Checkbox label="Rota mais econômica" />
                </p>
            </fieldset>
        );
    };

    const DynamicContentPreferencias = () => {
        return (
            <fieldset className="col-sm-4">
                <p>
                    <Checkbox
                        checked={avoidToll}
                        onChange={() => setAvoidToll((previous) => !previous)}
                        label="Evitar pedágio"
                    />
                </p>
            </fieldset>
        );
    };

    const fetchDataAutosuggestAddress = async (text: string) => {
        const response = await fetchAutosuggest(text);
        const data: AutoSuggestSuggestion[] = [];
        response.forEach((i) => data.push({ label: i.title, description: i.address.label, ...i } as any));
        return data;
    };

    const onClear = (index: number) => {
        setVia((previous) => {
            const via = previous.filter((_, i) => index !== i);
            return via.map((item, i) => ({ ...item, order: i + 1 }));
        });
    };

    const formatTimeToText = (time: string): string => {
        const [hours, minutes] = time.split(":").map(Number);
        const hourText = hours > 0 ? `${hours} hora${hours > 1 ? "s" : ""}` : "";
        const minuteText = minutes > 0 ? `${minutes} minuto${minutes > 1 ? "s" : ""}` : "";

        return [hourText, minuteText].filter(Boolean).join(" e ");
    };

    const formatCategory = (text: string) => {
        const DICT = {
            LUNCH_REST: "Refeição",
            LOAD_UNLOAD: "Coleta de carga",
            END_OF_DAY: "Fim de jornada de trabalho",
            DEALER: "Concessionária",
            WAYPOINT: 'Ponto de passagem',
        };

        return DICT[text as "LUNCH_REST" | "LOAD_UNLOAD" | "END_OF_DAY" | "DEALER" | 'WAYPOINT'];
    };

    const reorderArray = (index: number, direction: 1 | -1) => {
        if (index < 0 || index >= via.length) return;

        const newIndex = index + direction;
        if (newIndex < 0 || newIndex >= via.length) return;

        const newArray = [...via];
        [newArray[index], newArray[newIndex]] = [newArray[newIndex], newArray[index]];

        setVia(newArray);
    };

    const handleChangeLength = (value: string) => {
        setVehicleLenght(value);
    };

    const handleChangeHeight = (value: string) => {
        setVehicleHeight(value);
    };

    const handleChangeWidth = (value: string) => {
        setVehicleWidht(value);
    };

    const priceInputRef = useRef<HTMLInputElement>(null);
    const profile = useAppSelector((state) => state.login.userProfile);
    const accountIdG = profile?.account;
    const routeDate = useAppSelector((state) => state.routes);

    const [defaultKmValue, setDefaultKmValue] = useState<string>("");
    const [defaultPriceValue, setDefaultPriceValue] = useState<string>("");
    const hasFetchedForFuel = useRef(false);
    const previousFuelLabel = useRef<string | null>(null);
    const [initialFetchDone, setInitialFetchDone] = useState(false);

    const selectedFuelLabel = fuelType.find((fuel) => fuel.value == selectedFuelOption)?.label.toLowerCase() || "";

    useEffect(() => {
        fetchFuelTypes();
        fetchVehicleTypes();
    }, []);

    useEffect(() => {
        if (selectedFuelOption && !initialFetchDone) {
            fetchAccountDefaultCosts();
            setInitialFetchDone(true);
        }
    }, [selectedFuelOption]);

    useEffect(() => {
        if (routeId && selectedFuelOption !== Number(routeDate.routeCost?.costTypes?.at(0))) {
            fetchAccountDefaultCosts();
        }
    }, [selectedFuelOption]);

    useEffect(() => {
        if (!routeId && Boolean(selectedFuelOption.toString())) {
            if (previousFuelLabel.current && previousFuelLabel.current !== selectedFuelLabel) {
                hasFetchedForFuel.current = false;
            }

            if (!hasFetchedForFuel.current) {
                fetchAccountDefaultCosts();
                hasFetchedForFuel.current = true;
            }

            previousFuelLabel.current = selectedFuelLabel;
        }
    }, [selectedFuelOption]);

    const fetchAccountDefaultCosts = async () => {
        try {
            const selectedFuel = fuelType.find((fuel) => fuel.value == selectedFuelOption);

            if (selectedFuel?.value) {
                const response = await getByAccountAndCostTypeAccountDefaultCost(accountIdG, selectedFuel?.value);

                if (response?.consumptionPerUnit !== undefined) {
                    const updatedKmValue = `${response.consumptionPerUnit.toFixed(2)} ${selectedFuelLabel === "elétrico" ? "km/kwh" : "km/litro"
                        }`;

                    setDefaultKmValue(updatedKmValue);
                    setKmInputValue(updatedKmValue);
                }

                if (response?.valuePerUnit !== undefined) {
                    const numericValue = parseFloat(response.valuePerUnit.toString().replace(",", "."));
                    if (!isNaN(numericValue)) {
                        const formattedPriceValue = `R$ ${numericValue.toFixed(2).replace(".", ",")}`;

                        setDefaultPriceValue(formattedPriceValue);
                        setPriceInputValue(formattedPriceValue);
                    }
                }

                await calculateCost();
            }
        } catch (error) {
            console.error("Error fetching account default cost:", error);
        }
    };

    useEffect(() => {
        if (kmInputValue) {
            const numericValue = kmInputValue.replace(/\s?km\/litro|\s?km\/kwh/g, "").trim();
            const updatedValue = `${numericValue} ${selectedFuelLabel === "elétrico" ? "km/kwh" : "km/litro"}`;

            setKmInputValue(updatedValue);
        }
    }, [selectedFuelOption]);

    const fetchFuelTypes = async () => {
        try {
            const response = await getCostTypeByAccount(accountIdG);
            const operationalFind = response.find((res) => res.name === "Custo Operacional");

            const formattedFuelTypes = response
                .filter((res) => res.name !== "Custo Operacional")
                .map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }))
                .sort((a, b) => a.label.localeCompare(b.label));

            setFuelType(formattedFuelTypes);
            setOperational(operationalFind);

            const currentFuelOption = formattedFuelTypes.find((fuel) => fuel.value === selectedFuelOption);

            if (currentFuelOption) {
                setSelectedFuelOption(selectedFuelOption);
            } else {
                setSelectedFuelOption(formattedFuelTypes[0]?.value || 0);
            }
        } catch (error) {
            console.error("Error fetching cost types by account id:", error);
        }
    };

    const fetchVehicleTypes = async () => {
        try {
            const response = await getTruckTypes();
            const formattedVehicleTypes = response.map((item: any) => ({
                value: item.id,
                label: item.name,
                ...item,
            }));
            setVehicleType(formattedVehicleTypes);
        } catch (error) {
            console.error("Error fetching vehicle types:", error);
        }
    };

    useEffect(() => {
        const fetchTools = async () => {
            if (origin && destination) {
                await fetchCalculateToll();
            }
        };
        fetchTools();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        type,
        selectedVehicleOption,
        origin,
        destination,
        stops,
        avoidToll,
        vehicleWidth,
        vehicleHeight,
        vehicleLenght,
        vehicleGrossWeight,
        vehicleMaxAxleLoad,
        vehicleNumberOfAxles,
        vehicleTrailerAxles,
        shippedVHazardousGoods,
    ]);

    useEffect(() => {
        const fetchFuel = async () => {
            if (kmInputValue && priceInputValue) {
                try {
                    await fetchCalculateFuelCost();
                } catch (error) {
                    console.error("Error fetching fuel cost:", error);
                }
            }
        };
        const debouncedFetchFuel = debounce(fetchFuel, 500);

        debouncedFetchFuel();

        return () => {
            debouncedFetchFuel.cancel();
        };
    }, [selectedFuelOption, kmInputValue, priceInputValue, distance]);

    const calculateCost = async () => {
        if (kmInputValue && priceInputValue) {
            try {
                await Promise.all([fetchCalculateToll(), fetchCalculateFuelCost()]);
                setShowResults(true);
            } catch (error) {
                console.error("Error calculating costs:", error);
            }
        }
    };

    const fetchCalculateToll = async (): Promise<void> => {
        try {
            const lats = [origin?.lat, ...stops.map((stop) => stop.position.lat), destination?.lat];
            const lngs = [origin?.lng, ...stops.map((stop) => stop.position.lng), destination?.lng];

            const response = await getCalculateToll(
                lats,
                lngs,
                type,
                avoidToll,
                formatMetersMaskInNumber(vehicleWidth) || 0,
                formatMetersMaskInNumber(vehicleHeight) || 0,
                formatMetersMaskInNumber(vehicleLenght) || 0,
                vehicleNumberOfAxles,
                vehicleTrailerAxles,
                formatMetersMaskInNumber(vehicleGrossWeight, "T") || 0,
                formatMetersMaskInNumber(vehicleMaxAxleLoad, "T") || 0,
                type === "TRUCK" ? shippedVHazardousGoods : "NONE"
            );

            setTollValue(response.data.result.tollCost);
        } catch (error) {
            console.error("Error fetching toll cost:", error);
            setTollValue(0);
        }
    };

    const fetchCalculateFuelCost = async (): Promise<void> => {
        try {
            const numericFuelOption =
                typeof selectedFuelOption === "string" ? parseInt(selectedFuelOption) : selectedFuelOption;
            const priceMatch = priceInputValue.match(/\d+(\.\d+)?/g);
            const parsedPriceInputValue = priceMatch ? parseFloat(priceMatch.join(".")) : 0;

            if (isNaN(parsedPriceInputValue)) {
                throw new Error("Invalid price input value");
            }

            const distanceValue = Math.round(convertKmToMeters(parseFloat(distance)));
            const response = await getCalculateFuelCost(
                numericFuelOption,
                parseFloat(kmInputValue),
                parsedPriceInputValue,
                distanceValue
            );

            const fuelCost = response.data.result.value;
            setFuelValue(!isFinite(fuelCost) ? 0 : fuelCost);
        } catch (error) {
            console.error("Error fetching fuel cost:", error);
            setFuelValue(0);
        }
    };

    useEffect(() => {
        const tv = tollValue || 0;
        const fv = fuelValue || 0;
        const po = formatMoneyInNumber(priceOperational) || 0;
        const total = tv + fv + po;
        setTotalValue(total);
        if (tollValue !== null && fuelValue !== null) {
            setShowResults(true);
        }
    }, [tollValue, fuelValue, priceOperational]);

    const convertKmToMeters = (distanceInKm: number): number => {
        return distanceInKm * 1000;
    };

    const handleVehicleOptionChange = (newValue: string | number) => {
        setSelectedVehicleOption(newValue);
    };

    const handleShippedHazardousGoodsType = (newValue: string | number) => {
        setVShippedHazardousGoods(newValue);
    };

    const handleFuelOptionChange = (newValue: string | number) => {
        setSelectedFuelOption(newValue);
    };

    const handleKmInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let rawValue = e.target.value.replace(/[^0-9.,]/g, "");
        rawValue = rawValue.replace(",", ".");
        setKmInputValue(rawValue);
    };

    const handleKmInputBlur = () => {
        let numericValue = parseFloat(kmInputValue);
        if (isNaN(numericValue) || kmInputValue.trim() === "") {
            numericValue = 0.0;
        }

        const selectedFuel = fuelType.find((fuel) => fuel.value == selectedFuelOption);
        const formattedValue = `${numericValue.toFixed(2)} ${selectedFuel?.label.toLowerCase() === "elétrico" ? "km/kwh" : "km/litro"
            }`;

        setKmInputValue(formattedValue);
    };

    const handleKmInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Tab") {
            e.preventDefault();
            priceInputRef.current?.focus();
        }
    };

    const handlePriceInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let rawValue = e.target.value.replace(/[^0-9,]/g, "");
        setPriceInputValue(rawValue);
    };

    const handlePriceInputBlur = () => {
        if (!priceInputValue || priceInputValue.trim() === "") {
            setPriceInputValue("R$ 0,00");
            return;
        }

        const numericValue = priceInputValue.replace(",", ".");
        if (!isNaN(Number(numericValue))) {
            const formattedValue = `R$ ${Number(numericValue).toFixed(2).replace(".", ",")}`;
            setPriceInputValue(formattedValue);
        }
    };

    const getDisplayDuration = () => {
        if (durationWithStops !== "0h0min" && duration !== "0h0min") {
            return durationWithStops;
        } else if (duration !== "0h0min") {
            return duration;
        }
        return "0h0min";
    };

    const handleAddWaypointToVia = (position: Coordinates) => {
      const newStop = {

        position: position,
        stayTime: "00:00", // Defina o valor inicial
        category: "WAYPOINT",
        order: via.length + 1
     };

      setVia((prev) => [...prev, newStop]);
    };

    return (
        <>
            <div className="gap-10" style={{ display: "flex", flexDirection: "column" }}>
                <div className="callout callout-primary border-none display-flex align-items-center shadow-smooth">
                    <div className="flex-1">
                        <div className="text-medium text-size-20 line-height-125rel display-flex align-items-center">
                            <span className="rioglyph rioglyph-road text-size-16 margin-right-10"></span>
                            <span className="text-size-16 text-medium line-height-15">
                                Distância: {distance?.toLowerCase()?.replace(/\./g, ",") || "0km"}
                            </span>
                        </div>

                        <div>
                            {/* <span className="rioglyph text-color-gray rioglyph-filling-e-station text-size-16 margin-right-10"></span>
              <span className="text-color-gray text-size-12 line-height-15">
                Estimativa média de consumo de combustível: 0l
              </span> */}
                        </div>

                        <div>
                            {/* <span className="rioglyph text-color-gray rioglyph-globe text-size-16 margin-right-10"></span>
              <span className="text-color-gray text-size-12 line-height-15">
                Estimativa média de emissão de carbono: 0kg CO2
              </span> */}
                        </div>
                    </div>
                    <div className="display-flex justify-content-center" style={{ flex: 1 }}>
                        <h1 className="text-color-primary">
                            {getDisplayDuration()}
                        </h1>
                    </div>
                </div>

                <div className="gap-10" style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                        <label className="text-color-dark">Origem</label>

                        <div>
                            <div className="display-flex align-items-center">
                                <Autosuggest
                                    fetchData={fetchDataAutosuggestAddress}
                                    placeholder="Informe a origem"
                                    icon="rioglyph-start"
                                    defaultValue={origin?.label || ""}
                                    onClear={() => setOrigin(null)}
                                    onSuggestionSelected={(e, i) => {
                                        setOrigin({
                                            ...(i.suggestion as any).position,
                                            ...i.suggestion,
                                        });
                                    }}
                                />
                                <div className="btn-toolbar">
                                    <Button
                                        bsStyle={Button.PRIMARY}
                                        iconOnly
                                        iconName="rioglyph-plus-light"
                                        onClick={() => setOpenModal(true)}
                                        className="margin-left-10"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {via.map((item, index) => (
                        <div>
                            <div className="display-flex align-items-center">
                                <div className="width-40"></div>
                                <label className="text-color-dark">
                                    {formatCategory(item.category)} {item.category !== 'WAYPOINT' ? `- ${formatTimeToText(item.stayTime)}` : null}
                                </label>
                            </div>
                            <div className="display-flex align-items-center">
                                <div className="width-40 padding-left-10">
                                    {via.length > 1 ? (
                                        <>
                                            <div
                                                style={{ color: "#30B4C0", cursor: "pointer" }}
                                                className="rioglyph rioglyph-chevron-up"
                                                onClick={() => reorderArray(index, -1)}
                                            ></div>
                                            <div
                                                style={{ color: "#30B4C0", cursor: "pointer" }}
                                                className="rioglyph rioglyph-chevron-down"
                                                onClick={() => reorderArray(index, 1)}
                                            ></div>
                                        </>
                                    ) : null}
                                </div>
                                <Autosuggest
                                    fetchData={fetchDataAutosuggestAddress}
                                    placeholder="Informe a parada"
                                    icon="rioglyph-pushpin"
                                    defaultValue={item?.position?.label || ""}
                                    onClear={() => {
                                        onClear(index);
                                    }}
                                    onSuggestionSelected={(e, i) => {
                                        // setOrigin({
                                        //   ...(i.suggestion as any).position,
                                        //   ...i.suggestion,
                                        // });
                                    }}
                                />
                                <div className="btn-toolbar">
                                    <Button
                                        bsStyle={Button.PRIMARY}
                                        iconOnly
                                        iconName="rioglyph-plus-light"
                                        onClick={() => setOpenModal(true)}
                                        className="margin-left-10"
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                    <div>
                        <label className="text-color-dark">Destino</label>
                        <Autosuggest
                            fetchData={fetchDataAutosuggestAddress}
                            placeholder="Informe o destino"
                            icon="rioglyph-finish"
                            defaultValue={destination?.label || ""}
                            onClear={() => setDestination(null)}
                            onSuggestionSelected={(e, i) => {
                                setDestination({
                                    ...(i.suggestion as any).position,
                                    ...i.suggestion,
                                });
                            }}
                        />
                    </div>
                    <div className="separator">
                        {/*<ExpanderPanel
                            title="Otimizar"
                            bsStyle="blank"
                            titleClassName="text-bold margin-y-10"
                            bodyClassName="padding-0"
                        >
                            <DynamicContent/>
                        </ExpanderPanel>*/}

                        <ExpanderPanel
                            title="Preferências"
                            bsStyle="blank"
                            titleClassName="text-bold margin-y-10"
                            bodyClassName="padding-0"
                        >
                            <DynamicContentPreferencias />
                        </ExpanderPanel>

                        <ExpanderPanel
                            title="Características do veículo"
                            bsStyle="blank"
                            titleClassName="text-bold margin-y-10"
                            bodyClassName="padding-0"
                        >
                            <Dynamics.Dimensions
                                shippedHazardousGoods={shippedVHazardousGoods}
                                type={type}
                                handleVehicleOptionType={(str) => {
                                    setType(str.toString());
                                }}
                                selectedTypeOption={type}
                                selectedVehicleOption={selectedVehicleOption}
                                vehicleType={vehicleType}
                                handleChangeLenght={handleChangeLength}
                                handleChangeWidth={handleChangeWidth}
                                handleChangeHeight={handleChangeHeight}
                                handleVehicleOptionChange={handleVehicleOptionChange}
                                handleShippedHazardousGoodsType={handleShippedHazardousGoodsType}
                                numberOfAxles={vehicleNumberOfAxles}
                                handleVehicleNumberOfAxles={(v) => setVehicleNumberOfAxles(v)}
                                trailerAxles={vehicleTrailerAxles}
                                handleVehicleTrailerAxles={(v) => setVehicleTrailerAxles(v)}
                                height={vehicleHeight}
                                length={vehicleLenght}
                                width={vehicleWidth}
                                handleGrossWeight={(v) => setGrossWeight(v)}
                                grossWeight={vehicleGrossWeight}
                                handleMaxAxleLoad={(v) => setVehicleMaxAxleLoad(v)}
                                maxAxleLoad={vehicleMaxAxleLoad}
                                handleVehiclePollutantClass={(v) => setVehiclePollutantClass(v)}
                                vehiclePollutantClass={vehiclePollutantClass}
                            />
                        </ExpanderPanel>

                        <ExpanderPanel
                            title="Desvio da rota"
                            bsStyle="blank"
                            titleClassName="text-bold margin-y-10"
                            bodyClassName="padding-0"
                        >
                            <DynamicContentDesvio
                                isChecked={hasRouteRangeLimit}
                                onCheckedChange={handleHasRouteRangeLimitChange}
                                onDeviationChange={handleRouteRangeLimitChange}
                                rangeValue={routeRangeLimit}
                            />
                        </ExpanderPanel>

                        <ExpanderPanel
                            title="Áreas"
                            bsStyle="blank"
                            titleClassName="text-bold margin-y-10"
                            bodyClassName="padding-0"
                        >
                            <DynamicContentAreas areas={areasStore} />
                        </ExpanderPanel>

                        <ExpanderPanel
                            title="Simulador de custos"
                            bsStyle="blank"
                            titleClassName="text-bold margin-y-10"
                            bodyClassName="padding-0"
                        >
                            <Dynamics.DynamicContentSimuilador
                                priceOperational={priceOperational}
                                handlePriceOperational={(v) => setPriceOperational(v)}
                                handleFuelOptionChange={handleFuelOptionChange}
                                handleKmInputBlur={handleKmInputBlur}
                                handleKmInputChange={handleKmInputChange}
                                handleKmInputKeyDown={handleKmInputKeyDown}
                                handlePriceInputBlur={handlePriceInputBlur}
                                handlePriceInputChange={handlePriceInputChange}
                                priceInputRef={priceInputRef}
                                selectedFuelOption={selectedFuelOption}
                                kmInputValue={kmInputValue}
                                priceInputValue={priceInputValue}
                                fuelType={fuelType}
                                tollValue={tollValue}
                                fuelValue={fuelValue}
                                totalValue={totalValue}
                                showResults={showResults}
                            />
                        </ExpanderPanel>

                        <div className="btn-toolbar" style={{ paddingTop: "16px" }}>
                            <Button onClick={saveChanges} block bsStyle={Button.PRIMARY}>
                                {saveLabel}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog
                show={openModal}
                title={"Adicionar parada"}
                onClose={() => setOpenModal(false)}
                showCloseButton
                bsSize="md"
                body={
                    <div className="display-flex">
                        <div
                            style={{
                                width: 56,
                                height: 56,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginRight: 24,
                                marginLeft: 24,
                            }}
                            className="border border-color-primary border-width-3
                       text-center"
                        >
                            <span className="rioglyph rioglyph-pushpin text-color-primary" style={{ fontSize: 32 }} />
                        </div>
                        <div className="display-grid gap-10" style={{ flex: 1 }}>
                            <div>
                                <label className="text-color-dark">Local da parada</label>
                                <Autosuggest
                                    fetchData={fetchDataAutosuggestAddress}
                                    placeholder="Informe a parada"
                                    onClear={() => {
                                        setStop((previous) => ({
                                            ...previous,
                                            position: null,
                                        }));
                                    }}
                                    onSuggestionSelected={(e, i) => {
                                        setInvalidPosition(false);
                                        setStop((previous) => ({
                                            ...previous,
                                            position: {
                                                ...(i.suggestion as any).position,
                                                ...i.suggestion,
                                            },
                                        }));
                                    }}
                                />
                                {invalidPosition ? (
                                    <div
                                        style={{
                                            color: "red",
                                            fontSize: "11px",
                                            marginTop: "8px",
                                        }}
                                    >
                                        Campo obrigatório
                                    </div>
                                ) : null}
                            </div>
                            <div className="form-group padding-left-0">
                                <label htmlFor="select">Tipo de parada</label>
                                <select
                                    className="form-control input-lg"
                                    id="select"
                                    onChange={(event) => {
                                        setStop((previous) => ({
                                            ...previous,
                                            category: event.target.value,
                                            ...(event.target.value === 'WAYPOINT' && { stayTime: '00:00' })
                                        }));
                                    }}
                                >
                                    <option value="LUNCH_REST">Refeição</option>
                                    <option value="LOAD_UNLOAD">Coleta de carga</option>
                                    <option value="END_OF_DAY">Fim de jornada de trabalho</option>
                                    <option value="DEALER">Concessionária</option>
                                    <option value="WAYPOINT">Ponto de passagem</option>
                                </select>
                            </div>
                            {stop.category !== 'WAYPOINT' ?
                                <div>
                                    <label htmlFor="time">Tempo de permanência</label>
                                    <input
                                        id="time"
                                        type="text"
                                        className="form-control input-lg"
                                        min="00:00"
                                        // max="23:59"
                                        value={stop.stayTime}
                                        onChange={(event) => {
                                            const formattedTime: string = formatMaskTime(event.target.value);
                                            setStop((previous) => ({
                                                ...previous,
                                                stayTime: formattedTime,
                                            }));
                                        }}
                                    />
                                </div> : null}
                        </div>
                    </div>
                }
                footer={
                    <div className="display-flex gap-10 justify-content-end">
                        <Button
                            bsStyle="primary"
                            className="btn btn-primary btn-outline btn-lg width-100"
                            onClick={() => {
                                setStop({ ...INITIAL_STOP });
                                setOpenModal(false);
                                setInvalidPosition(false);
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            disabled={stop.category !== 'WAYPOINT' ? stop.stayTime === "00:00" : !stop.position}
                            bsStyle="primary"
                            className="btn-lg width-100"
                            onClick={() => {
                                if (!stop.position) {
                                    setInvalidPosition(true);
                                    return;
                                }
                                setStop({ ...INITIAL_STOP });
                                setInvalidPosition(false);
                                setVia((previous) => [...previous, { ...stop, order: previous.length + 1 }]);
                                setOpenModal(false);
                            }}
                        >
                            Salvar
                        </Button>
                    </div>
                }
            />
        </>
    );
};
export default RegisterRoute;

